export const TRACKING_SCRIPT_SUPPORT_LINK_ITEMS = [
    {
        url: '/support/script-setup',
        label: 'Installing the Tracking Script',
    },
];

export const TEST_CREATION_LINK_ITEMS = [
    {
        url: '/support/test-creation/guide',
        label: 'Test Creation Guide',
    },
];

export const TEST_EXAMPLE_LINK_ITEMS = [
    {
        url: '/support/test-examples/images',
        label: 'How to Test Images to Increase Sales',
    },
    {
        url: '/support/test-examples/forms',
        label: 'How to Test Forms to Increase Conversions',
    },
];

export const AFFILIATE_SUPPORT_LINK_ITEMS = [
    {
        url: '/affiliates/faq',
        label: 'Affiliate FAQ',
    },
];
