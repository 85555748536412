import { useEffect } from 'react';

const DEFAULT_PREVENT_PAGE_REFRESH_MESSAGE =
    'Are you sure you want to leave? Any progress will be lost.';

// note: many browsers restrict a customised message, so it'll likely not work, which doesn't overly matter...
const usePreventPageRefresh = (message: string = DEFAULT_PREVENT_PAGE_REFRESH_MESSAGE): void => {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [message]);
};

export default usePreventPageRefresh;
