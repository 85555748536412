import React from 'react';
import { InputField } from '../../../../default/Form/Form';
import CampaignTypeDropdown from './CampaignTypeDropdown/CampaignTypeDropdown';
import StoreInDbDropdown from './StoreInDbDropdown/StoreInDbDropdown';

const CreateCampaignStageOne = () => (
    <>
        <InputField
            type='text'
            title='campaignName'
            placeholder='Campaign Name'
            alias='Campaign Name'
        />
        <InputField type='text' title='popupName' placeholder='Popup Name' alias='Popup Name' />
        <CampaignTypeDropdown />
        <StoreInDbDropdown />
    </>
);

export default CreateCampaignStageOne;
