import { useCallback, useState } from 'react';
import { CompetitorsDocument } from '../../../graphql/generated/schema';
import useLazyQuery from '../../other/useLazyQuery/useLazyQuery';
import { UseLazyCompetitors } from './types';
import { SelectItem } from '../useGetSelectOptions/types';
import { Uuid } from '../../../utils/global-types';

const useLazyCompetitors: UseLazyCompetitors = () => {
    const fetchCompetitors = useLazyQuery(CompetitorsDocument);
    const [competitors, setCompetitors] = useState<SelectItem | null>(null);
    const [loadingCompetitors, setLoadingCompetitors] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const getCompetitors = useCallback(async () => {
        try {
            setLoadingCompetitors(true);
            const res = await fetchCompetitors();
            if (!res || res?.error)
                throw new Error(res?.error?.message || 'Could not fetch competitors');
            const refinedCompetitors = res?.data?.competitors.map(
                ({ id, name }: { id: Uuid; name: string }) => ({
                    value: id,
                    name,
                })
            );
            setCompetitors(refinedCompetitors);
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoadingCompetitors(false);
        }
    }, [fetchCompetitors]);

    return {
        competitors,
        loadingCompetitorList: loadingCompetitors,
        competitorListError: error,
        getCompetitors,
    };
};

export default useLazyCompetitors;
