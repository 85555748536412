import jwt_decode, { JwtPayload } from 'jwt-decode';
import {
    DEFAULT_CURRENCY_SYMBOL,
    Plans,
    Plan,
    PlanKey,
    MAX_TEST_SESSIONS,
    TARGET_DEVICES,
    PLAN_PRICE_IDS,
    SubscriptionStatus,
    SubscriptionStatusKey,
} from '../global-variables';

export const formatDate = (date: string | number): string => {
    const actualDate = new Date(date);
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(actualDate);
    const month = new Intl.DateTimeFormat('en', {
        month: '2-digit',
    }).format(actualDate);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(actualDate);

    return `${day}/${month}/${year}`;
};

export const formatTime = (date: string | number): string => {
    const actualDate = new Date(date);
    const hour = new Intl.DateTimeFormat('en', {
        hour: 'numeric',
        hour12: false,
    }).format(actualDate);
    const minutes = (() => {
        const unformattedMinutes = new Intl.DateTimeFormat('en', {
            minute: 'numeric',
        }).format(actualDate);
        return Number(unformattedMinutes) < 10 ? '0' + unformattedMinutes : unformattedMinutes;
    })();

    return `${hour}:${minutes}`;
};

export const capitalise = (str: string): string => {
    // note: preferably use CSS where possible - use the capitalise mixin
    if (!str.length) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// note: this function may not debounce properly as it still calls the fn the same number of times
// export const debounce = (fn: () => {}, timeout: number = 300) => {
//     let timer: ReturnType<typeof setTimeout>;
//     return (...args: any) => {
//         clearTimeout(timer);
//         timer = setTimeout(() => {
//             fn.apply(this, args);
//         }, timeout);
//     };
// };

// note: this function may not debounce properly as it still calls the fn the same number of times
export const asyncDebounce = (fn: () => {}, wait: number, callFirst: any) => {
    var timeout: any;
    return function () {
        return new Promise(async (resolve) => {
            if (!wait) {
                // @ts-ignore
                const result = await fn.apply(this, arguments);
                resolve(result);
            }

            // @ts-ignore
            var context = this;
            // @ts-ignore
            var args = arguments;
            var callNow = callFirst && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(async function () {
                timeout = null;
                if (!callNow) {
                    // @ts-ignore
                    const result = await fn.apply(context, args);
                    resolve(result);
                }
            }, wait);

            if (callNow) {
                // @ts-ignore
                const result = await fn.apply(this, arguments);
                resolve(result);
            }
        });
    };
};

export const getCookie = (name: string): string | null =>
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null;

// note: when cookies deleted, they may not show in the browser tab until you switch tabs and back again
// if the deleted cookies' paths aren't /, they may not work
export const deleteCookies = (names: string[]): void => {
    names.forEach((name: string) => {
        if (getCookie(name)) {
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
        }
    });
};

export const checkTokenExpiry = (token: string | null): boolean => {
    if (!token) return false;

    const decodedToken = jwt_decode<JwtPayload>(token);
    const expiryDate = decodedToken.exp as number; // expects exp key to exist

    if (Date.now() >= expiryDate * 1000) {
        return true;
    }

    return false;
};

export const convertToCurrency = (number: number | undefined | null): string | undefined => {
    if (!number && number !== 0) return;
    return `${DEFAULT_CURRENCY_SYMBOL}${number.toFixed(2)}`;
};

export const debounce = (func: (...args: any) => any, wait: number) => {
    let timeout: NodeJS.Timeout;

    return function executedFunction(...args: any) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const mappedPlan = (plan: number): Plans => Plan[plan] as Plans;

export const mappedSubscriptionStatus = (status: number): SubscriptionStatusKey =>
    SubscriptionStatus[status] as SubscriptionStatusKey;

export const millisecondsToSeconds = (milliseconds: number): number => {
    const seconds = milliseconds / 1000; // Convert milliseconds to seconds
    return Math.round(seconds * 10) / 10; // Round to 1 decimal place
};

export const oneDP = (num: number): number =>
    Number.isInteger(num) ? num : Number(num.toFixed(1));

// export const extractNestedParts = (str: string): [string, number, string] | undefined => {
//     const regex = /^(\w+)\[(\d+)\]\.(\w+)$/; // perhaps could be /\[(\d+)\]/
//     const match = str.match(regex);

//     if (match) {
//         const [, prefix, indexStr, postfix] = match;
//         const index = parseInt(indexStr, 10);
//         return [prefix, index, postfix];
//     }
// };
// replaced with this:
type ExtractNestedPartArgs = {
    objectName: string;
    propertyName: string;
    arrayIndex: number;
    subPropertyName: string;
};
/*
    example inputs:
    - campaignPages.include[0].value
    - campaignPages.include[2].type
*/
export const extractNestedParts = (input: string): ExtractNestedPartArgs | null => {
    // Match the new input format: objectName.propertyName[arrayIndex].subPropertyName
    const regex = /^(\w+)\.(\w+)\[(\d+)\]\.(\w+)$/;
    const match = input.match(regex);

    if (!match) {
        return null; // Return null if the string doesn't match the expected format
    }

    const [, objectName, propertyName, indexStr, subPropertyName] = match;

    return {
        objectName,
        propertyName,
        arrayIndex: parseInt(indexStr, 10),
        subPropertyName,
    };
};

export const getPlanValue = (key: PlanKey | undefined): Plan | undefined => {
    const planKey = key?.toUpperCase() as PlanKey;
    return Plan.hasOwnProperty(planKey) ? Plan[planKey] : undefined;
};

export const getMaxTestSessions = (plan?: Plan): number | undefined => {
    if (plan && MAX_TEST_SESSIONS.hasOwnProperty(plan)) {
        return MAX_TEST_SESSIONS[plan];
    }
    throw new Error(
        'There was a problem getting the max test sessions in function getMaxTestSessions'
    );
};

export const getTargetDeviceNumbers = (devices: string[]): number[] => {
    const targetDevices: number[] = [];

    for (const device of devices) {
        switch (device) {
            case 'mobiles':
                targetDevices.push(TARGET_DEVICES.MOBILES);
                break;
            case 'desktops':
                targetDevices.push(TARGET_DEVICES.DESKTOPS);
                break;
            case 'tablets':
                targetDevices.push(TARGET_DEVICES.TABLETS);
                break;
            default:
                break;
        }
    }

    return targetDevices;
};

export const getPriceId = (input: keyof typeof PLAN_PRICE_IDS): string | undefined =>
    PLAN_PRICE_IDS[input];
