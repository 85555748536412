import React, { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { InputField } from '../../default/Form/Form';
import styles from '../../../css/default.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading, Error } from '../../default/States/States';
import { Link } from 'react-router-dom';
import { editAccountSchema } from '../../../schemas/AccountSchemas';
import { useEditAccount } from '../../../hooks/user/useEditAccount/useEditAccount';
import { EditAccountFormInputs } from './types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { UserContextType } from '../../../contexts/UserContext/types';

export const EditAccountForm = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const { loading, error, editAccount } = useEditAccount();
    const handleSubmit: SubmitHandler<EditAccountFormInputs> = async (formData) =>
        editAccount(formData);
    const methods = useForm<EditAccountFormInputs>({
        resolver: yupResolver(editAccountSchema),
        mode: 'onTouched',
        defaultValues: {
            email: user?.email,
        },
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={styles.formInner}>
                        <InputField type='text' title='email' placeholder='joe@bloggs.com' />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                            <Link to='/user/profile' className={styles.btnDark}>
                                Cancel
                            </Link>
                        </div>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error} marginTop={true} />}
        </>
    );
};
