import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { NO_MODAL_OVERLAY } from '../../../utils/global-variables';
import {
    COOKIE_BANNER_MSG_DETAILED,
    COOKIE_BANNER_MSG_BRIEF,
} from '../../../pages/legal/Cookies/variables';
import { CookieContextType } from '../../../contexts/CookieContext/types';
import { CookieContext } from '../../../contexts/CookieContext/CookieContext';

export const CookiePopup = () => {
    const { cookieConsent: cookieConsentSet, setCookieConsent } =
        useContext<CookieContextType>(CookieContext);
    const [cookiePopupIsOpen, setCookiePopupIsOpen] = useState<boolean>(false);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const btnOneText = showMoreInfo ? 'Allow All Cookies' : 'Allow';
    const btnTwoText = showMoreInfo ? 'Allow Essential Cookies' : 'Manage';
    const descriptiveText = showMoreInfo ? COOKIE_BANNER_MSG_DETAILED : COOKIE_BANNER_MSG_BRIEF;

    // @todo: check whether variables 'btnText' & 'descriptiveText' should be memoised
    // @todo: & check whether Fns 'openModal', 'closeModal', 'acceptCookies', 'declineCookies'
    // & 'manageCookies' should be memoised (with useCallack) since the component runs
    // on every page - ask on SO or SE
    // presumably they should be, otherwise will be recreated on every page, even when
    // modal doesnt show up if the user has already selected an option?

    // 1. Am I correct in memoising the `btnText` and `descriptiveText` variales?
    // 2. Should these functions also be memoised (with useCallback): `openModal`, `closeModal`, `acceptCookies`, `declineCookies` & `manageCookies`?

    // SE Post: https://codereview.stackexchange.com/questions/281736/improving-performance-with-a-react-modal-where-the-component-runs-on-every-page

    const openModal = () => {
        setCookiePopupIsOpen(true);
    };

    const closeModal = () => {
        setCookiePopupIsOpen(false);
        setShowMoreInfo(false);
    };

    const acceptCookies = () => {
        const expiryDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() + 1)); // 1 year from now
        document.cookie = `cookieConsent=all; expires=${expiryDate.toUTCString()};`;
        setCookieConsent('all');
        closeModal();
    };

    const declineCookies = () => {
        const expiryDate: Date = new Date(new Date().setDate(new Date().getDate() + 1)); // expires in 1 day
        document.cookie = `cookieConsent=essential; expires=${expiryDate.toUTCString()};`;
        setCookieConsent('essential');
        closeModal();
    };

    const manageCookies = () => {
        if (!showMoreInfo) {
            setShowMoreInfo(true);
            return;
        }
        declineCookies();
    };

    useEffect(() => {
        if (!cookieConsentSet) {
            openModal();
        }
    }, [cookieConsentSet]);

    if (cookieConsentSet) return null;

    return (
        <Modal
            style={NO_MODAL_OVERLAY}
            isOpen={cookiePopupIsOpen}
            className={styles.cookiePopup}
            contentLabel='Cookie Notification'
            portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
        >
            <div className={`${styles.content} ${showMoreInfo ? styles.expanded : ''}`}>
                {showMoreInfo && <h3>Cookie Preferences</h3>}
                <p className={styles.descriptiveText}>
                    {descriptiveText}{' '}
                    {showMoreInfo && (
                        <>
                            <Link to='/cookie-policy'>Learn more</Link>,{` `}
                        </>
                    )}
                    <button onClick={acceptCookies} className={styles.btnAsText}>
                        {btnOneText}
                    </button>
                    {` or `}
                    <button onClick={manageCookies} className={styles.btnAsText}>
                        {btnTwoText}
                    </button>
                    .
                </p>
                {/* <div
                    className={`${styles.buttonsContainer} ${styles.spaced} ${
                        styles.largeButtons
                    } ${styles.cookieBtns} ${styles.specificity} ${
                        showMoreInfo ? styles.expanded : ''
                    }`}
                >
                    <button onClick={acceptCookies} className={styles.btnAsText}>
                        {btnOneText}
                    </button>
                    <button onClick={manageCookies} className={styles.btnAsText}>
                        {btnTwoText}
                    </button>
                </div> */}
            </div>
        </Modal>
    );
};
