import React from 'react';
import { HeroArea } from '../../../../components/default/Areas/Areas';
import { PageErrorProps } from './types';

const PageError = ({
    title = 'Page Error',
    msg = 'Something went wrong.',
    customBtnPrimary = homepageBtn,
    customBtnSecondary = accountBtn,
}: PageErrorProps): JSX.Element => (
    <HeroArea
        title={title}
        description={msg}
        buttonText={customBtnPrimary.text}
        buttonLink={customBtnPrimary.url}
        buttonTwoText={customBtnSecondary.text}
        buttonTwoLink={customBtnSecondary.url}
        withBackButton={true}
    />
);

const homepageBtn = {
    text: 'Homepage',
    url: '/',
};

const accountBtn = {
    text: 'Account',
    url: '/user',
};

// export default withPage(PageError)({ meta: pageErrorMeta });
export default PageError;
