import React, { useContext, useEffect } from 'react';
import { HeroArea } from '../../../../../components/default/Areas/Areas';
import { withPage } from '../../../../../components/layout/Page/Page';
import { cancelPlanSuccessMeta } from '../../../../misc/MetaTags';
import { UserContextType } from '../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';

const CancelPlanSuccess = () => {
    const { setAccount } = useContext<UserContextType>(UserContext);

    useEffect(() => setAccount(null), [setAccount]);

    return (
        <HeroArea
            title='Cancel Plan Success'
            description="We're sorry to see you cancel your plan. Your paid plan has been cancelled and you are now enrolled on the free plan. Note that some of your active tests may have been paused. You have been logged out and can log back in below:"
            buttonText='Log In'
            buttonLink='/login'
        />
    );
};

export default withPage(CancelPlanSuccess)({ meta: cancelPlanSuccessMeta });
