import React, { useContext } from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { userProfileMeta } from '../../../misc/MetaTags';
import styles from '../../../../css/default.module.scss';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { UserContextType } from '../../../../contexts/UserContext/types';
import ChangePaymentInfoBtn from './ChangePaymentInfoBtn/ChangePaymentInfoBtn';
import StackedList from '../../../../components/default/StackedList/StackedList';
import { EDIT_ACCOUNT_LINKS } from './variables';
import { faAt, faFingerprint, faIdCard } from '@fortawesome/free-solid-svg-icons';
import stackedListStyles from '../../../../components/default/StackedList/styles.module.scss';
import { capitalise } from '../../../../utils/HelperFunctions/HelperFunctions';
import { Back } from '../../../../components/default/Navigation/Navigation';
import ProfilePrompt from '../../../../components/account/profile/ProfilePrompt/ProfilePrompt';

const UserProfile = () => {
    const { user } = useContext<UserContextType>(UserContext);

    if (!user) return null;

    const profileItemProps = [
        { icon: faAt, label: user.email },
        { icon: faFingerprint, label: `Your id: ${user.id}` },
        { icon: faIdCard, label: `${capitalise(user.userPlan)} plan` },
    ];

    return (
        <>
            <h1 className={styles.centralisedText}>User Profile</h1>
            <ProfilePrompt />
            <div className={stackedListStyles.panelsContainer}>
                <StackedList
                    items={profileItemProps}
                    title='Profile Information'
                    areLinks={false}
                />
                <StackedList items={EDIT_ACCOUNT_LINKS} title='Edit Account' areLinks={true} />
            </div>
            <ChangePaymentInfoBtn />
            <Back to='/user' text='Back to Dashboard' />
        </>
    );
};

export default withPage(UserProfile)({ meta: userProfileMeta, innerSpacing: true });
