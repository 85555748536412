import React from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { pricingMeta } from '../../misc/MetaTags';
import { HeroArea, Section } from '../../../components/default/Areas/Areas';
import styles from './styles.module.scss';
import { PlanCards } from '../../../components/default/Cards/PlanCards/PlanCards';
import { useChangePlanCheckout } from '../../../hooks/payments/useChangePlanCheckout/useChangePlanCheckout';
import {
    Loading,
    Error,
    InfoWithChildren,
    Warning,
} from '../../../components/default/States/States';
import { PlanTermsSnippet } from '../../account/private/Plan/PlanTermsSnippet/PlanTermsSnippet';
import { Link } from 'react-router-dom';

const Pricing = () => {
    const { changePlan, loading, error } = useChangePlanCheckout();

    return (
        <>
            <HeroArea
                title='Pricing'
                description='Get started with a free account today or select a paid plan. You can change plans any time.'
                buttonText='Create Free Account'
                buttonLink='/create-account'
                tall={false}
            />
            <Section
                id={styles.planCardsSectionContainer}
                clsInner={styles.inner}
                patterned={false}
            >
                <PlanCards changePlan={changePlan} />
                <PlanTermsSnippet />
            </Section>

            <CustomPricing />

            {loading && <Loading centralised={true} />}
            {error && <Error msg={error} />}
        </>
    );
};

export const CustomPricing = () => (
    <Section patterned={true}>
        <InfoWithChildren centralised={true}>
            Want more sessions than our expert plan? No problem -{' '}
            <Link to='/contact'>contact us</Link>.
        </InfoWithChildren>
        <Warning
            msg='Our app does not currently support single page applications.'
            centralised={true}
            marginTop={true}
        />
    </Section>
);

export default withPage(Pricing)({ meta: pricingMeta, fullWidth: true });
