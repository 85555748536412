import React, { useEffect } from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { accountCreatedSuccessfully } from '../../../misc/MetaTags';
import useQuoraCustomTrackingEvent from '../../../../hooks/tracking/useQuoraCustomTrackingEvent/useQuoraCustomTrackingEvent';
import { Section } from '../../../../components/default/Areas/Areas';
import { VideoModal } from '../../../../components/default/VideoModal/VideoModal';
import defaultStyles from '../../../../css/default.module.scss';
// @ts-ignore
import quickWinsPdf from '../../../../downloads/cro-simple-quick-wins.pdf';
import { Back } from '../../../../components/default/Navigation/Navigation';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import EbookImage from '../../../../images/ebook-ab-sm.png';

const AccountCreationSuccess = () => {
    const navigate = useNavigate();

    useQuoraCustomTrackingEvent('CompleteRegistration');

    // temporarily redirect to dashboard (until I want to change this page sometime?)
    useEffect(() => {
        navigate('/user');
    }, [navigate]);

    return (
        <>
            <Section patterned={true}>
                <h1>Account Created Successfully</h1>
                <div className={styles['lead-magnet-area-wrap']}>
                    <img src={EbookImage} alt='A/B Testing Guide' />
                    <div>
                        <h2>Free Web Experiments Guide</h2>
                        <p>
                            Get your free copy of this guide to discover how simple experiments can
                            boost your conversion rates.
                        </p>
                        <a
                            href={quickWinsPdf}
                            className={defaultStyles.btnPrimary}
                            target='_blank'
                            rel='noreferrer'
                        >
                            Download
                        </a>
                    </div>
                </div>
            </Section>

            <VideoModal />

            <Section patterned={true}>
                <h2>Get Started Now</h2>
                <p>
                    Why not start now? It's free and should only take a few minutes. All you need to
                    do is:
                </p>
                <ol>
                    <li>
                        Install the <Link to='/support/script-setup'>tracking script</Link>; and
                    </li>
                    <li>Create a simple test.</li>
                </ol>
                <Back to='/user' text='View Dashboard' paddingless={true} marginless={true} />
            </Section>
        </>
    );
};

export default withPage(AccountCreationSuccess)({
    meta: accountCreatedSuccessfully,
    fullWidth: true,
});
