import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProcessStepProps } from '../types';
import styles from './styles.module.scss';

const ProcessStepBoxes = ({ steps }: ProcessStepProps): JSX.Element => {
    // const parentClasses = `${styles.processSteps} ${version === 'two' ? styles.versionTwo : ''}`;
    const parentClasses = styles.processSteps;

    return (
        <ol className={parentClasses}>
            {steps.map(({ title, icon, ariaLabel, description }) => (
                <li key={ariaLabel}>
                    <div className={styles.inner}>
                        <FontAwesomeIcon {...icon} aria-label={ariaLabel} />
                        <p>
                            <span>{title}</span>
                            {description && (
                                <span className={styles.description}>{description}</span>
                            )}
                        </p>
                    </div>
                </li>
            ))}
        </ol>
    );
};

export default ProcessStepBoxes;
