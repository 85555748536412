import React, { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { InputField } from '../../default/Form/Form';
import styles from '../../../css/default.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Error, Loading } from '../../default/States/States';
import { newPasswordSchema } from '../../../schemas/AccountSchemas';
import { useLocation } from 'react-router-dom';
import { NewPasswordFormInputs } from './types';
import { UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { useNewPassword } from '../../../hooks/user/useNewPassword/useNewPassword';
import { Back } from '../../default/Navigation/Navigation';

export const NewPasswordForm = () => {
    const { loading, error, sendPasswordResetEmail } = useNewPassword();
    const handleSubmit: SubmitHandler<NewPasswordFormInputs> = async (formData) =>
        sendPasswordResetEmail(formData);
    const methods = useForm<NewPasswordFormInputs>({
        resolver: yupResolver(newPasswordSchema),
        mode: 'onTouched',
        defaultValues: {
            email: '',
        },
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={styles.formInner}>
                        <InputField type='text' title='email' placeholder='joe@bloggs.com' />
                    </div>

                    {loading ? (
                        <Loading />
                    ) : (
                        <button type='submit' className={styles.btnPrimary}>
                            Continue
                        </button>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error as string} marginTop={true} />}
        </>
    );
};

export const NewPasswordLinks = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const { state }: any = useLocation();
    const url = state?.previousPath || '/login';
    const preposition: string = state?.previousPath ? 'Back' : 'Go';

    if (user) return <Back to='/user/profile' text='Back to Profile' />;

    return <Back to={url} text={`${preposition} to login`} />;
};
