import React from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { privacyMeta } from '../../misc/MetaTags';
import { Section } from '../../../components/default/Areas/Areas';
import { Link } from 'react-router-dom';

const Privacy = () => (
    <>
        <Section patterned={true}>
            <h1>Popup Hawk Privacy Policy</h1>
            <p>
                For website visitors, we use cookies to collect the bare minimum of personal data
                (such as your email address), otherwise we do not collect any personal data. You can
                see more information on our <Link to='/cookie-policy'>cookie policy</Link> page. If
                you decide to create an account, we ask for the bare minimum and only share it with
                services that are absolutely necessary for the app to function.
            </p>
            <p>
                At Popup Hawk, we are committed to complying with GDPR, CCPA, PECR and other privacy
                regulations. The privacy of your data — and it is your data, not ours! — is a big
                deal to us. In this policy, we lay out what data we collect and why, how your data
                is handled and your rights to your data. We promise we never sell your data: never
                have, never will. If you have placed the Popup Hawk tracking script on your website,
                we only track aggregated, non-personal data, such as averages for form submits, time
                spent on a page, scrolled-down percentages, clicks, bounce rates and so on.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>As a visitor to our website</h2>
            <p>
                The privacy of our website visitors is important to us so we do not track any
                individual people. As a visitor to our website:
            </p>
            <ul>
                <li>
                    Only the bare minimum personal information is collected (for example, your email
                    address when creating an account)
                </li>
                <li>No information is shared with, sent to or sold to third-parties</li>
                <li>No information is shared with advertising companies</li>
                <li>No information is mined and harvested for personal and behavioral trends</li>
                <li>No information is monetized</li>
            </ul>
            <p>
                We run the Popup Hawk tracking script to collect some anonymous usage data for
                statistical purposes. The goal is to track overall trends for each campaign - it is
                not to track individual visitors. All the data is in aggregate only. No personal
                data is collected.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>As a customer or user of Popup Hawk</h2>
            <p>
                Our guiding principle is to collect only what we need and that we will solely
                process this information to provide you with the service you signed up for. We use a
                select number of trusted external service providers for certain service offerings.
                These service providers are carefully selected and meet high data protection, data
                privacy, and security standards. We only share information with them that is
                required for the services offered and we contractually bind them to keep any
                information we share with them as confidential and to process personal data only
                according to our instructions.
            </p>
            <h3>What we collect, what we use it for, and services we use</h3>
            <ul>
                <li>
                    An email address is required to create an account. You need to provide us with
                    your email address if you want to create a Popup Hawk account. That’s just so
                    you can log in and personalize your new account, and we can send you invoices,
                    updates, or other essential information.
                </li>
                <li>
                    A persistent first-party cookie is stored to remember you’re logged in. If you
                    log in to your Popup Hawk account, you give us permission to use cookies so you
                    don’t have to log in on each returning session. This makes it easier for you to
                    use our product. A cookie is a piece of text stored by your browser. You can
                    adjust cookie retention settings in your own browser. Cookies that are already
                    stored may be deleted at any time. You can see more details on our{' '}
                    <Link to='/cookie-policy'>cookie policy</Link> page.
                </li>
                <li>
                    The payment process is handled by a third-party payment provider. If you choose
                    to upgrade to a Popup Hawk paid plan, the billing information and the payment
                    process are handled by Stripe.
                </li>
            </ul>
        </Section>

        <Section patterned={true}>
            <h2>Retention of data</h2>
            <p>
                We will retain your information as long as your account is active, as necessary to
                provide you with the services or as otherwise set forth in this policy. We will also
                retain and use this information as necessary for the purposes set out in this policy
                and to the extent necessary to comply with our legal obligations, resolve disputes,
                enforce our agreements, and protect Popup Hawk’s legal rights. You can choose to
                delete your Popup Hawk account at any time. All your data will be permanently
                deleted immediately when you delete your account.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Changes and questions</h2>
            <p>
                We may update this policy as needed to comply with relevant regulations and reflect
                any new practices.
            </p>
            <p>
                <Link to='/contact'>Contact us</Link> if you have any questions, comments, or
                concerns about this privacy policy, your data, or your rights with respect to your
                information.
            </p>
            <p>Last updated: October 2, 2024</p>
        </Section>
    </>
);

export default withPage(Privacy)({ meta: privacyMeta, fullWidth: true });
