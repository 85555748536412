import {
    useCreateCampaignMutation,
    useCreatePopupMutation,
} from '../../../graphql/generated/schema';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { trueOrFalseStringToBoolean } from '../../../utils/HelperFunctions/trueOrFalseStringToBoolean/trueOrFalseStringToBoolean';
import { getDeviceTargeting } from '../../../utils/HelperFunctions/getDeviceTargeting/getDeviceTargeting';
import { minify as minifyCss } from 'csso';
import { minify as minifyJs } from 'terser';
// NOTE: html is minified at the BE (had issues trying to implement at FE) - may also minify CSS & JS

type CreateCampaignFormInputs = any;

export const useCreateCampaign: any = () => {
    const [, createCampaignFn] = useCreateCampaignMutation();
    const [, createPopup] = useCreatePopupMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();

    const createCampaign = useCallback(
        async (args: CreateCampaignFormInputs): Promise<void> => {
            const createCampaignInputs = {
                input: {
                    name: args.campaignName,
                    type: args.campaignType,
                    storeInOurDatabase: trueOrFalseStringToBoolean(args.campaignStoreInOurDatabase),
                    pageTargeting: args.campaignPages,
                    cookieTargeting: null,
                    urlParamTargeting: null,
                    deviceTargeting: getDeviceTargeting(args.campaignDevices),
                    scheduledStart: null,
                    scheduledEnd: null,
                },
            };

            try {
                setLoading(true);
                const createCampaignResponse = await createCampaignFn(createCampaignInputs);
                const insertedCampaignId = createCampaignResponse.data?.createCampaign?.insertedId;

                if (!insertedCampaignId || createCampaignResponse.error) {
                    throw new Error(
                        createCampaignResponse.error?.message ||
                            'Could not insert campaign into the database'
                    );
                }

                // Transform and minify CSS
                // const transformedCss = adjustCss(args.popupCss);
                // const minifiedCss = minifyCss(transformedCss).css;
                const minifiedCss = minifyCss(args.popupCss).css;
                const minifiedJs = (await minifyJs(args.popupJs || '')).code;

                const htmlWithCssAndJs = `
                    ${minifiedCss && `<style>${minifiedCss}</style>`}
                    <div class="popup-hawk-container-inner">${args.popupHtml}</div>
                    ${minifiedJs && `<script>${minifiedJs}</script>`} 
                `;

                const createPopupResponse = await createPopup({
                    input: {
                        campaignId: insertedCampaignId,
                        displayPriority: 3, // might be that I place display priority on the campaign table instead
                        howToShow: args.popupHowToShow,
                        htmlContent: htmlWithCssAndJs,
                        name: args.popupName,
                        showAfterScrollPercentage: args.popupShowAfterScrollPercentage
                            ? Number(args.popupShowAfterScrollPercentage)
                            : null,
                        triggeredByClickingElements: args.popupTriggeredByClickingElements,
                    },
                });
                const insertedPopupId = createPopupResponse.data?.createPopup?.insertedId;

                if (!insertedPopupId || createPopupResponse.error) {
                    throw new Error(
                        createPopupResponse.error?.message ||
                            'Could not insert popup into the database'
                    );
                }

                navigate('/user/campaigns/new/success');
            } catch (error: any) {
                setError(`Error creating campaign: ${error.message}`);
            } finally {
                setLoading(false);
            }
        },
        [createCampaignFn, createPopup, navigate]
    );

    return { createCampaign, loading, error };
};
