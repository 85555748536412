import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { useAuthenticateUserMutation } from '../../../graphql/generated/schema';
import {
    mappedPlan,
    mappedSubscriptionStatus,
} from '../../../utils/HelperFunctions/HelperFunctions';
import { UseLoginOutputs, LogUserInFn } from './types';

export const useLogin: UseLoginOutputs = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const { setAccount } = useContext<UserContextType>(UserContext);
    const [{ fetching: loading }, authenticateUser] = useAuthenticateUserMutation();

    const logUserIn: LogUserInFn = async (formData, customRedirect) => {
        const redirectUrl = customRedirect || '';

        try {
            const { data, error } = await authenticateUser({
                input: formData,
            });

            if (error || !data) {
                const errorText = error?.message || 'Could not log in';
                setError(`HTTP error: ${errorText}`);
                return;
            }

            const userPlan = mappedPlan(data.authenticateUser.userPlan);
            const subscriptionStatus = mappedSubscriptionStatus(
                data.authenticateUser.subscriptionStatusId
            );
            const { __typename, subscriptionStatusId, ...accData } = data.authenticateUser;
            const accountData = { ...accData, email: formData.email, userPlan, subscriptionStatus };
            setAccount(accountData);
            navigate(redirectUrl);
        } catch (error: any) {
            setError(error.message);
        }
    };

    return { loading, error, logUserIn };
};
