import React, { useContext, useEffect } from 'react';
import { HeroArea } from '../../../../../components/default/Areas/Areas';
import { withPage } from '../../../../../components/layout/Page/Page';
import { changePlanSuccessMeta } from '../../../../misc/MetaTags';
import { UserContextType } from '../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';

const ChangePlanSuccess = () => {
    const { setAccount } = useContext<UserContextType>(UserContext);

    useEffect(() => setAccount(null), [setAccount]);

    return (
        <HeroArea
            title='Change Plan Success'
            description='You have successfully changed your plan. Your monthly payments have been adjusted accordingly - your next payment may be slightly different as it may be prorated. Note that some of your active tests may have been paused. You have been logged out and can log back in below:'
            buttonText='Log In'
            buttonLink='/login'
        />
    );
};

export default withPage(ChangePlanSuccess)({ meta: changePlanSuccessMeta });
