import React from 'react';
import styles from '../../../../../../css/default.module.scss';
import { Back } from '../../../../../../components/default/Navigation/Navigation';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { userAvatarBusinessMeta } from '../../../../../misc/MetaTags';
import EditBusinessForm from '../../../../../../components/account/profile/EditBusinessForm/EditBusinessForm';

const Business = () => (
    <div>
        <h1 className={styles.centralisedText}>Your Business</h1>
        <EditBusinessForm />
        <Back />
    </div>
);

export default withPage(Business)({ meta: userAvatarBusinessMeta, innerSpacing: true });
