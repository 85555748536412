import { MetaTags } from './types';

export const homepageMeta: MetaTags = {
    title: 'Create Powerful Website Popups to Boost Conversions | Popup Hawk',
    description:
        'Popup Hawk makes it easy for website owners to create custom popups to capture leads, increase sales, and optimize conversions. Simple, effective, and affordable.',
};
export const aboutMeta: MetaTags = {
    title: 'About Popup Hawk | Powerful Lead Generation Popups Made Easy',
    description:
        'Learn how Popup Hawk helps businesses create high-converting website popups with ease. Simple, affordable, and designed to drive more leads and sales.',
};
export const blogMeta: MetaTags = {
    title: `Popup Hawk Blog | Expert Tips for Popup Optimization and Lead Generation`,
    description:
        'Explore the Popup Hawk Blog for expert guides, tips, and how-to articles on creating popups that convert. Learn how to optimize your website with actionable advice.',
};
export const paymentInfoMeta: MetaTags = {
    title: 'Secure Payment Information | Popup Hawk',
    description:
        'Secure and transparent payment options at Popup Hawk. Learn about our affordable plans for powerful website popups that drive results.',
};
export const contactMeta: MetaTags = {
    title: 'Contact Popup Hawk | Get Support for Your Popup Campaigns',
    description:
        'Need help with your popup campaigns? Contact Popup Hawk for expert support and assistance in optimizing your website’s lead generation efforts.',
};
export const loginMeta: MetaTags = {
    title: 'Login to Your Popup Hawk Account',
    description:
        'Access your Popup Hawk account to create, manage, and optimize your website popups. Log in now to drive conversions effortlessly.',
};
export const newPasswordMeta: MetaTags = {
    title: 'Set a New Password | Popup Hawk Account',
    description:
        'Securely set up a new password for your Popup Hawk account. Follow simple steps to protect your account and continue creating effective popups.',
};
export const resetPasswordMeta: MetaTags = {
    title: 'Reset Your Password | Popup Hawk',
    description:
        'Reset your Popup Hawk password quickly and securely. Recover access to your account and get back to building high-converting popups.',
};
export const newPasswordSuccessMeta: MetaTags = {
    title: 'Password Reset Successful | Popup Hawk',
    description:
        'Your password has been reset successfully. Log back into Popup Hawk to continue optimizing your website’s lead generation with popups.',
};
export const createAccountMeta: MetaTags = {
    title: 'Create Your Account | Popup Hawk',
    description:
        'Sign up for Popup Hawk to start building website popups that convert. Create your account easily and start optimizing lead generation for your business.',
};
export const userDashboardMeta: MetaTags = {
    title: 'Your Popup Hawk Dashboard | Manage Popups & Campaigns',
    description:
        'Access your Popup Hawk dashboard to manage and monitor your popup campaigns. Keep track of performance and optimize your website effortlessly.',
};
export const accountCreatedSuccessfully: MetaTags = {
    title: 'Account Successfully Created | Start Using Popup Hawk Now',
    description:
        'Your Popup Hawk account has been created successfully. Start building popups and capturing more leads today.',
};
export const userWebsitesMeta: MetaTags = {
    title: 'Manage Your Websites | Popup Hawk',
    description:
        'Easily manage all your websites with Popup Hawk’s popup creation platform. Optimize lead generation across multiple websites from a single dashboard.',
};
export const userCampaignsMeta: MetaTags = {
    title: 'Manage Your Popup Campaigns | Popup Hawk',
    description: 'Manage and optimize your popup campaigns with Popup Hawk’s easy-to-use platform.',
};
export const CampaignMeta: MetaTags = {
    title: 'Manage Your Popup Campaign | Popup Hawk',
    description:
        'View, activate, pause, or modify your popup campaigns with Popup Hawk’s simple management tools.',
};
export const userLeadsMeta: MetaTags = {
    title: 'Manage Your Leads | Popup Hawk',
    description:
        'Track and manage leads generated from your popups. Grow your business with Popup Hawk.',
};
export const userTestMeta: MetaTags = {
    title: 'Analyze Individual Popups | Popup Hawk',
    description:
        'Analyze and optimize your individual popups with Popup Hawk. Gain valuable insights to improve conversion rates effortlessly.',
};
export const userTestsMeta: MetaTags = {
    title: 'Your Popup Campaigns | Popup Hawk',
    description:
        'Manage and track all your popup campaigns at Popup Hawk. Drive more leads and conversions with easy-to-use tools.',
};
export const testVariantMeta: MetaTags = {
    title: 'Analyze Popup Variants | Popup Hawk',
    description:
        'Analyze and compare popup variants effortlessly with Popup Hawk. Optimize lead generation based on real data.',
};
export const createCampaignMeta: MetaTags = {
    title: 'Create a New Popup Campaign | Popup Hawk',
    description:
        'Easily create a new popup campaign using Popup Hawk’s intuitive platform. Start converting more visitors today.',
};
export const testCreatedMeta: MetaTags = {
    title: 'Popup Created Successfully | Popup Hawk',
    description:
        'Your popup campaign has been created successfully. Start optimizing your website for more conversions with Popup Hawk.',
};
export const testResultsMeta: MetaTags = {
    title: 'View Popup Results | Popup Hawk',
    description:
        'Analyze the performance of your popup campaigns and view detailed results. Optimize your website’s lead generation with actionable insights.',
};
export const userPagesMeta: MetaTags = {
    title: 'Manage Your Website Pages | Popup Hawk',
    description:
        'Manage all your website pages and their associated popups with Popup Hawk’s easy-to-use interface.',
};
export const userProfileMeta: MetaTags = {
    title: 'Manage Your Profile | Popup Hawk',
    description:
        'Personalize your Popup Hawk experience. Manage your profile settings and optimize lead generation effortlessly.',
};
export const userAvatarGoalsMeta: MetaTags = {
    title: 'Set Your Popup Campaign Goals | Popup Hawk',
    description:
        'Set and track your popup campaign goals with Popup Hawk. Optimize your strategy and drive conversions with tailored insights.',
};

export const userAvatarBusinessMeta: MetaTags = {
    title: 'Optimize Website Engagement | Popup Hawk Popups',
    description:
        'Boost your business performance with Popup Hawk. Create high-converting popups to engage users and capture leads effectively.',
};

export const userAvatarDemographicsMeta: MetaTags = {
    title: 'Analyze Audience Demographics | Popup Hawk Popups',
    description:
        'Discover your audience demographics with Popup Hawk. Tailor popups to specific segments and improve lead generation.',
};

export const userAvatarPreviousExperienceMeta: MetaTags = {
    title: 'Leverage Past Insights | Popup Hawk Popups',
    description:
        'Utilize previous campaign insights with Popup Hawk. Drive conversions by refining popup strategies based on data.',
};

export const scriptSetupMeta: MetaTags = {
    title: 'Easily Set Up Your Popup Script | Popup Hawk',
    description:
        'Set up your popup script quickly and easily with Popup Hawk. Follow our guide for seamless popup integration and lead capture.',
};

export const scriptVerificationMeta: MetaTags = {
    title: 'Verify Your Popup Script | Popup Hawk',
    description:
        'Ensure your popup script is running smoothly with Popup Hawk. Use our verification tools for effective popup display.',
};

export const userPlanMeta: MetaTags = {
    title: 'Your Popup Plan | Popup Hawk',
    description:
        'Manage your popup plan with Popup Hawk. Optimize engagement with our flexible plans designed to fit your business needs.',
};

export const cancelPlanMeta: MetaTags = {
    title: 'Cancel Your Popup Plan | Popup Hawk',
    description:
        'Cancel your Popup Hawk subscription hassle-free. Manage your account with our simple cancellation process.',
};

export const changePlanMeta: MetaTags = {
    title: 'Change Your Popup Plan | Popup Hawk',
    description:
        'Easily modify your Popup Hawk plan. Upgrade or downgrade based on your lead generation and engagement goals.',
};

export const cancelPlanSuccessMeta: MetaTags = {
    title: 'Plan Cancelled Successfully | Popup Hawk',
    description:
        'Your Popup Hawk subscription has been cancelled successfully. Explore other popup options to drive engagement.',
};

export const avatarProfileSuccessMeta: MetaTags = {
    title: 'Avatar Profile Created Successfully | Popup Hawk',
    description:
        'Your avatar profile has been successfully created at Popup Hawk. Personalize your popups for enhanced user engagement.',
};

export const changePlanSuccessMeta: MetaTags = {
    title: 'Plan Changed Successfully | Popup Hawk',
    description:
        'Your Popup Hawk plan has been updated. Start using your new features to enhance your lead generation and conversions.',
};

export const paymentFailedMeta: MetaTags = {
    title: 'Payment Failed | Popup Hawk',
    description:
        'Your payment has failed at Popup Hawk. Please check your payment method and try again to maintain uninterrupted access.',
};

export const paymentSuccessMeta: MetaTags = {
    title: 'Payment Successful | Popup Hawk',
    description:
        'Your payment has been confirmed at Popup Hawk. Enjoy uninterrupted access to premium popup features.',
};

export const paymentMethodUpdateSuccessMeta: MetaTags = {
    title: 'Payment Method Updated Successfully | Popup Hawk',
    description:
        'Your payment method has been successfully updated. Continue using Popup Hawk for seamless lead generation and optimization.',
};

export const paymentMethodUpdateCancelledMeta: MetaTags = {
    title: 'Payment Method Update Cancelled | Popup Hawk',
    description:
        'Your payment method update was cancelled. Review your settings and try again if necessary to continue using Popup Hawk.',
};

export const editAccountMeta: MetaTags = {
    title: 'Edit Your Account | Popup Hawk',
    description:
        'Update your account details with Popup Hawk to better manage your popup campaigns and user engagement strategies.',
};

export const styleGuideMeta: MetaTags = {
    title: 'Style Guide | Popup Hawk',
    description:
        'Explore Popup Hawk’s design elements with our style guide. Ensure your popups align with best practices and aesthetic standards.',
};

export const pageNotFoundMeta: MetaTags = {
    title: 'Page Not Found (404) | Popup Hawk',
    description:
        'The page you are looking for couldn’t be found on Popup Hawk. Return to the main site and explore our lead generation tools.',
};

export const newCampaignSuccessMeta: MetaTags = {
    title: 'Popup Campaign Created Successfully | Popup Hawk',
    description:
        'Your popup campaign has been successfully created. Start capturing leads with Popup Hawk now.',
};

export const pageErrorMeta: MetaTags = {
    title: 'Page Error | Popup Hawk',
    description:
        'An error occurred at Popup Hawk. Our team is on it! Return to the main page or contact support for assistance.',
};

export const supportMeta: MetaTags = {
    title: 'Support | Popup Hawk',
    description:
        'Need help? Popup Hawk’s support team is here to assist with any questions or issues regarding your popup campaigns.',
};

export const testCreationGuideMeta: MetaTags = {
    title: 'Popup Creation Guide | Popup Hawk',
    description:
        'Learn how to create high-converting popups with Popup Hawk’s comprehensive guide for capturing leads and boosting engagement.',
};

export const testExampleProductImageEffectivenessMeta: MetaTags = {
    title: 'Popup Product Image Test Example | Popup Hawk',
    description:
        'Discover how to test the effectiveness of product images using Popup Hawk’s testing tools.',
};

export const createFormTestMeta: MetaTags = {
    title: 'Landing Page Form Popup Test Example | Popup Hawk',
    description:
        'Learn how to create and test landing page form popups using Popup Hawk’s tools for optimized lead capture.',
};

export const privacyMeta: MetaTags = {
    title: 'Privacy Policy | Popup Hawk',
    description:
        'Review Popup Hawk’s privacy policy to understand how we handle your data while helping you capture leads with popups.',
};

export const cookiesMeta: MetaTags = {
    title: 'Cookie Policy | Popup Hawk',
    description:
        'Learn about Popup Hawk’s cookie policy and how cookies are used to enhance your popup campaigns and user engagement.',
};

export const termsMeta: MetaTags = {
    title: 'Terms & Conditions | Popup Hawk',
    description:
        'Review Popup Hawk’s terms and conditions to understand the guidelines for using our platform to create and manage popups.',
};

export const affiliateProgramMeta: MetaTags = {
    title: 'Popup Affiliate Program | Popup Hawk',
    description:
        'Join Popup Hawk’s affiliate program and earn recurring commissions by promoting our lead generation tools.',
};

export const partnershipsMeta: MetaTags = {
    title: 'Partner with Popup Hawk | CRO & A/B Testing Collaborations',
    description:
        'Offer CRO or A/B testing services? Partner with Popup Hawk to earn ongoing commissions for referring new clients. Let’s optimize conversions together.',
};

export const affiliateFaqMeta: MetaTags = {
    title: 'Affiliate Program FAQs | Popup Hawk',
    description:
        'Find answers to frequently asked questions about Popup Hawk’s affiliate program. Learn how to make the most out of your affiliate partnership.',
};

export const affiliateDashboardMeta: MetaTags = {
    title: 'Affiliate Dashboard | Track Conversions Easily | Popup Hawk',
    description:
        'Monitor and optimize your affiliate conversions with the Popup Hawk dashboard. Streamline your progress and boost your earnings with ease.',
};

export const referralListMeta: MetaTags = {
    title: 'Affiliate Referral List | Track Your Success | Popup Hawk',
    description:
        'Access your referral list at Popup Hawk to evaluate your affiliate performance and enhance your marketing efforts for better conversions.',
};

export const affiliatePaymentsMeta: MetaTags = {
    title: 'Quick & Reliable Affiliate Payments | Popup Hawk',
    description:
        'Get paid promptly for your affiliate efforts. Join Popup Hawk’s affiliate program and earn rewards for helping drive conversions.',
};

export const affiliateCommissionsMeta: MetaTags = {
    title: 'Maximize Affiliate Commissions | Popup Hawk',
    description:
        'Boost your income with Popup Hawk’s generous affiliate commissions. Help websites optimize conversions while you earn more.',
};

export const affiliateBalanceMeta: MetaTags = {
    title: 'Manage Your Affiliate Balance with Ease | Popup Hawk',
    description:
        'Easily manage and track your affiliate balance on Popup Hawk. Stay organized and optimize your earnings.',
};

export const affiliateSessionsMeta: MetaTags = {
    title: 'Track Affiliate Sessions for Better Performance | Popup Hawk',
    description:
        'Monitor affiliate session data on Popup Hawk to fine-tune your strategies and drive higher conversion rates. Take control of your results.',
};

export const createAffiliateAccountMeta: MetaTags = {
    title: 'Join Popup Hawk’s Affiliate Program | Create Your Account',
    description:
        'Sign up quickly for Popup Hawk’s affiliate program and start earning commissions by driving conversions for websites.',
};

export const affiliateLoginMeta: MetaTags = {
    title: 'Affiliate Login | Access Your Dashboard | Popup Hawk',
    description:
        'Securely log in to your Popup Hawk affiliate account to manage conversions, track progress, and optimize your strategies.',
};

export const pricingMeta: MetaTags = {
    title: 'Flexible Popup Pricing Plans | Free & Paid Tiers | Popup Hawk',
    description:
        'Discover Popup Hawk’s flexible pricing plans for creating popups. Choose from free and paid tiers based on your website’s visitor volume and needs.',
};

export const servicesMeta: MetaTags = {
    title: 'Custom A/B Testing Services for Your Website | Popup Hawk',
    description:
        'Need help with A/B testing? Popup Hawk offers expert A/B testing services, including setup, custom development, and ongoing optimization.',
};

export const quickWinsMeta: MetaTags = {
    title: 'Quick A/B Testing Wins for Your Website | Free Guide',
    description:
        'Download Popup Hawk’s free guide to A/B testing quick wins and start making immediate improvements to your website’s conversion rates.',
};

export const landingPageTemplateMeta: MetaTags = {
    title: 'Download Free HTML, CSS, and JavaScript Landing Page Template',
    description:
        'Get a free, customizable landing page template with full HTML, CSS, and JavaScript code. Start optimizing your project now.',
};

export const paidAdLandingPage: MetaTags = {
    title: 'Simple A/B Testing for Websites | Improve Conversions | Popup Hawk',
    description:
        'Optimize your website’s conversion rate easily with Popup Hawk’s A/B testing tool, perfect for beginners and pros alike.',
};

export const paidAdAdvertisersLandingPage: MetaTags = {
    title: 'Boost Paid Ad Conversions with A/B Testing | Popup Hawk',
    description:
        'Use Popup Hawk’s A/B testing to improve paid ad landing pages, optimize your CPA, and increase ROAS. Start testing today.',
};

export const paidAdEcommerceLandingPage: MetaTags = {
    title: 'Boost Ecommerce Sales with A/B Testing | Popup Hawk',
    description:
        'Maximize your ecommerce conversions by using Popup Hawk’s A/B testing platform to test and improve your store’s pages.',
};

export const newNewsletterSubscriber: MetaTags = {
    title: 'Welcome to Popup Hawk! Your Subscription is Active',
    description:
        'Thank you for subscribing to Popup Hawk. Enjoy your free content and start optimizing your website’s conversions today.',
};

export const blogTemplate: MetaTags = {
    title: 'Optimize Conversions with A/B Testing Insights | Popup Hawk Blog',
    description:
        'Discover tips and strategies for boosting your website’s conversions using A/B testing techniques on Popup Hawk’s blog.',
};

export const wordpressAndWoocommerceAbTesting: MetaTags = {
    title: 'A/B Testing for WordPress & WooCommerce | No Plugins Needed',
    description:
        'Use Popup Hawk’s plugin-free A/B testing tool to optimize your WordPress and WooCommerce store conversions without complex setup.',
};

export const improveProductPageConversionsMeta: MetaTags = {
    title: 'Optimize Product Pages for Higher Conversions | Popup Hawk',
    description:
        'Discover simple A/B testing changes that can boost your product page conversion rates and help you increase ecommerce sales.',
};

export const abTestingHeadlinesMeta: MetaTags = {
    title: 'Master A/B Testing for Headlines | Popup Hawk Guide',
    description:
        'Learn how A/B testing can enhance your headlines for higher engagement. Get expert tips and best practices from Popup Hawk.',
};

export const abTestingMultipleVariantsMeta: MetaTags = {
    title: 'Unlock Insights with Multivariate Testing | Popup Hawk',
    description:
        'A/B testing multiple variants can reveal deeper insights into your campaigns. Discover how Popup Hawk makes multivariate testing easy.',
};

export const abTestingToolFree: MetaTags = {
    title: 'Free Website A/B Testing Tool | Popup Hawk',
    description:
        'Popup Hawk offers a free, easy-to-use A/B testing platform that helps you create tests and improve conversions on your website.',
};

export const improveFormConversionsMeta: MetaTags = {
    title: 'Boost Form Submissions with A/B Testing | Popup Hawk',
    description:
        'Test different elements of your forms with Popup Hawk’s A/B testing to improve submission rates and reduce form abandonment.',
};
