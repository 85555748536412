const formatTimestampToYYYYMMDD = (timestamp?: string | number | null): string => {
    // Converts to yyyy-MM-dd format
    if (!timestamp) return '';

    const timestampAsNumber = typeof timestamp === 'string' ? parseInt(timestamp, 10) : timestamp;

    if (isNaN(timestampAsNumber)) {
        return ''; // Return empty string if the provided value is not a valid number
    }

    const dateObj = new Date(timestampAsNumber);
    const formattedDate = dateObj.toISOString().split('T')[0];
    return formattedDate;
};

export default formatTimestampToYYYYMMDD;
