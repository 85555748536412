import React, { useEffect, useState } from 'react';
import styles from '../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Loading, Error, Success } from '../../../default/States/States';
import { useDeleteCampaignMutation } from '../../../../graphql/generated/schema';
import campaignStyles from './styles.module.scss';
import { useParams, Params, useNavigate } from 'react-router-dom';
import { Uuid } from '../../../../utils/global-types';

export const DeleteCampaignModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [{ data, fetching, error }, deleteCampaign] = useDeleteCampaignMutation();
    const { campaignId } = useParams<Params<string>>();
    const navigate = useNavigate();

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        if (data?.deleteCampaign?.acknowledged) {
            const timeoutId = setTimeout(() => {
                navigate('/user/campaigns');
            }, 3000);

            // Cleanup function to clear the timeout if the component unmounts before the delay
            return () => clearTimeout(timeoutId);
        }
    }, [data?.deleteCampaign?.acknowledged, navigate]);

    return (
        <>
            <div className={styles.buttonsContainer}>
                <button
                    onClick={openModal}
                    className={`${styles.marginTop} ${styles.btnAsText} ${campaignStyles.delete}`}
                >
                    Delete Campaign
                </button>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Delete Campaign Modal'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                {!data?.deleteCampaign?.acknowledged ? (
                    <div>
                        <h2>Delete Campaign</h2>
                        <p>
                            Are you sure you want to delete this campaign? All popups and data
                            associated with this account would be deleted (this action is
                            irreversable).
                        </p>
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            {fetching ? (
                                <Loading />
                            ) : (
                                <button
                                    onClick={() => deleteCampaign({ id: campaignId as Uuid })}
                                    className={styles.btnRed}
                                >
                                    Delete
                                </button>
                            )}
                            <button onClick={closeModal} className={styles.btnPrimary}>
                                Cancel
                            </button>
                        </div>
                    </div>
                ) : (
                    <>
                        <Success
                            msg='Campaign deleted successfully. Now redirecting...'
                            marginTop={true}
                        />
                        <Loading centralised={true} marginTop={true} />
                    </>
                )}

                {error && <Error msg={error.message} marginTop={true} />}
            </Modal>
        </>
    );
};
