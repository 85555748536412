import React from 'react';
import { SelectField } from '../../../../../default/Form/Form';

const YES_OR_NO = [
    { label: 'Immediately', value: 'immediate' },
    { label: 'When the user is about to leave', value: 'exit_intent' },
];

const ExitIntentField = () => {
    return (
        <SelectField
            hasColon={false}
            title='popupHowToShow'
            type='select'
            alias='How should this popup be shown?'
            caption='Select yes if you want to show this popup when the user is about to leave the page.'
        >
            <option value=''>-- Select --</option>
            {YES_OR_NO.map((option) => (
                <option key={option.label} value={option.value}>
                    {option.label}
                </option>
            ))}
        </SelectField>
    );
};

export default ExitIntentField;
