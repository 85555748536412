import React from 'react';
import { HeroArea } from '../../../../../../components/default/Areas/Areas';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { avatarProfileSuccessMeta } from '../../../../../misc/MetaTags';

const AvatarSuccess = () => (
    <HeroArea
        title='Profile Created'
        description='You have now successfully completed your profile.'
        buttonText='View Dashboard'
        buttonLink='/user'
    />
);

export default withPage(AvatarSuccess)({ meta: avatarProfileSuccessMeta });
