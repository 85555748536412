import React from 'react';
import { SelectField } from '../../../../../default/Form/Form';

const YES_OR_NO = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

const StoreInDbDropdown = () => {
    return (
        <SelectField
            hasColon={false}
            title='campaignStoreInOurDatabase'
            type='select'
            alias='Store leads in our database?'
            caption="Select yes if you want us to store the email addresses of form submissions, or no if you're processing this data yourself. If you select yes, you will be able to visit a page where you can access the email addresses of all users who submit the form in this campaign."
        >
            <option value=''>-- Select --</option>
            {YES_OR_NO.map((option) => (
                <option key={option.label} value={option.value}>
                    {option.label}
                </option>
            ))}
        </SelectField>
    );
};

export default StoreInDbDropdown;
