import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSendEmMutation } from '../../../graphql/generated/schema';
import { UseNewPasswordOutputs, SendPasswordResetEmailFn } from './types';

export const useNewPassword: UseNewPasswordOutputs = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [{ fetching: loading }, sendPasswordEmail] = useSendEmMutation();

    const sendPasswordResetEmail: SendPasswordResetEmailFn = async ({ email }) => {
        try {
            const { data, error } = await sendPasswordEmail({
                input: { email },
            });

            if (error || !data) {
                const errorText = error?.message || 'Could not send password-reset email';
                setError(`HTTP error: ${errorText}`);
                return;
            }

            navigate('/new-password-success');
        } catch (err: any) {
            setError(err.message);
        }
    };

    return { loading, error, sendPasswordResetEmail };
};
