import React, { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { BelowFormLinks, InputField } from '../../default/Form/Form';
import styles from '../../../css/default.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from '../../../schemas/AccountSchemas';
import { Loading, Error } from '../../default/States/States';
import { Link } from 'react-router-dom';
import { useResetPassword } from '../../../hooks/user/useResetPassword/useResetPassword';
import { ResetPasswordFormProps, ResetPasswordFormInputs } from './types';

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ setSuccess }) => {
    const { loading, error, changePassword } = useResetPassword();
    const handleSubmit: SubmitHandler<ResetPasswordFormInputs> = async ({ password }) =>
        changePassword(password, setSuccess);
    const methods = useForm<ResetPasswordFormInputs>({
        resolver: yupResolver(resetPasswordSchema),
        mode: 'onTouched',
        defaultValues: {
            password: '',
            passwordConfirmation: '',
        },
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={styles.formInner}>
                        <InputField
                            type='password'
                            title='password'
                            placeholder='********'
                            alias='New Password'
                        />
                        <InputField
                            type='password'
                            title='passwordConfirmation'
                            placeholder='********'
                            alias='Re-enter Password'
                        />
                    </div>

                    {loading ? (
                        <Loading />
                    ) : (
                        <button type='submit' className={styles.btnPrimary}>
                            Reset Password
                        </button>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error} marginTop={true} />}
        </>
    );
};

export const ResetPasswordLinks = () => (
    <BelowFormLinks>
        Keep current password and <Link to='/login'>log in</Link>.
    </BelowFormLinks>
);
