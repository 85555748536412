import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import defaultStyles from '../../../css/default.module.scss';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../utils/global-variables';
import { PaginatorProps } from './types';

// note: you can still access 'disabled' buttons via the keyboard
export const Paginator: FC<PaginatorProps> = ({
    currentPage,
    itemCount,
    itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
    path,
    maxWidth = 'none',
}) => {
    const totalPages: number = Math.ceil(itemCount / itemsPerPage);
    const isLastPage: boolean = currentPage >= totalPages; // is last page (or 'above')
    const disablePrev: boolean = currentPage <= 1;
    let next: string = path,
        prev: string = path;

    if (totalPages <= 1 || !totalPages || !itemsPerPage || currentPage > totalPages) {
        return null;
    }

    if (currentPage + 1 <= itemCount && !isLastPage) {
        next = `${path}/page/${currentPage + 1}`;
    }

    if (currentPage === 1 || currentPage === 2) {
        prev = path;
    }

    if (currentPage - 1 > 1) {
        prev = `${path}/page/${currentPage - 1}`;
    }

    if (isLastPage) {
        next = `${path}/page/${totalPages}`;
    }

    return (
        <nav className={styles.paginatorOuter} style={{ maxWidth }}>
            <div className={styles.paginatorInner}>
                <div className={`${styles.paginatorBtnWrap} ${disablePrev ? styles.disabled : ''}`}>
                    <Link
                        to={prev}
                        className={`${disablePrev ? styles.disabled : ''} ${
                            defaultStyles.btnGrey
                        } ${defaultStyles.btnThin}`}
                    >
                        Prev
                    </Link>
                </div>

                <span className={styles.text}>
                    Page {currentPage} of {totalPages}
                </span>

                <div className={`${styles.paginatorBtnWrap} ${isLastPage ? styles.disabled : ''}`}>
                    <Link
                        to={next}
                        className={`${isLastPage ? styles.disabled : ''} ${defaultStyles.btnGrey} ${
                            defaultStyles.btnThin
                        }`}
                    >
                        Next
                    </Link>
                </div>
            </div>
        </nav>
    );
};
