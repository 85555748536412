import React, { Dispatch } from 'react';
import styles from './styles.module.scss';
import { useStoredCampaignsQuery } from '../../../../graphql/generated/schema';
import { Loading, Error, NoneFound } from '../../../default/States/States';

interface SelectCampaignDropdownFormProps {
    selectedCampaign: string;
    setSelectedCampaign: Dispatch<string>;
}

export const SelectCampaignDropdownForm = ({
    selectedCampaign,
    setSelectedCampaign,
}: SelectCampaignDropdownFormProps): JSX.Element | null => {
    const [{ data, fetching: loading, error }] = useStoredCampaignsQuery();
    const campaigns = data?.storedCampaigns;

    const handleCampaignChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCampaign(e.target.value);
    };

    if (loading) return <Loading centralised={true} marginBottom={true} />;
    if (error) return <Error msg={error.message} marginBottom={true} />;
    if (!campaigns?.length)
        return <NoneFound msg='No campaigns found. Please create a campaign.' />;

    return (
        <form className={styles.campaignsDropdownContainer}>
            <label htmlFor='campaign'>Select a Campaign:</label>
            <select id='campaign' value={selectedCampaign} onChange={handleCampaignChange}>
                <option value=''>-- Select --</option>
                {campaigns.map((campaign) => (
                    <option key={campaign.id} value={campaign.id} data-id={campaign.id}>
                        {campaign.name}
                    </option>
                ))}
            </select>
        </form>
    );
};
