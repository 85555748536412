import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CreateAccountForm,
    CreateAffiliateAccountLinks,
} from '../../../components/account/CreateAccount/CreateAccount';
import { withPage } from '../../../components/layout/Page/Page';
import { UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { createAffiliateAccountMeta } from '../../misc/MetaTags';
import defaultStyles from '../../../css/default.module.scss';

const AffiliateRegistry = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) navigate('/user/profile/affiliate');
    }, [user, navigate]);

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Register as an affiliate</h1>
            <CreateAccountForm affiliateForm={true} />
            <CreateAffiliateAccountLinks />
        </>
    );
};

export default withPage(AffiliateRegistry)({
    meta: createAffiliateAccountMeta,
    innerSpacing: true,
    whiteBox: true,
});
