import React from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { newPasswordSuccessMeta } from '../../../misc/MetaTags';
import { Back } from '../../../../components/default/Navigation/Navigation';

const NewPasswordSuccess = () => (
    <>
        <h1>Password Reset Instructions Sent</h1>
        <p>
            Check your email inbox in about 5 minutes to find an email with instructions about
            resetting your password. Don't forget to check your <u>junk</u> inbox.
        </p>
        <Back to='/login' text='Back to Login' block={true} />
    </>
);

export default withPage(NewPasswordSuccess)({
    meta: newPasswordSuccessMeta,
    innerSpacing: true,
});
