import React, { useEffect } from 'react';
import PageInclusionFields from './PageInclusionFields/PageInclusionFields';
import PageExclusionFields from './PageExclusionFields/PageExclusionFields';
import defaultStyles from '../../../../../../css/default.module.scss';
import pageStyles from './styles.module.scss';
import { useFormContext, useWatch } from 'react-hook-form';

const PageFields = () => {
    const { trigger } = useFormContext();
    const campaignPageValues = useWatch({
        name: 'campaignPages',
    });

    useEffect(() => {
        trigger('campaignPages');
    }, [campaignPageValues, trigger]);

    return (
        <>
            <hr className={defaultStyles.hr} />
            <div className={pageStyles.container}>
                <h3>Page Targeting</h3>
                <p>
                    By default, popups will attempt to display on every page of your site. You can
                    restrict this by adding inclusion and/or exclusion rules - for example, to
                    display a popup on checkout pages only, add an inclusion rule that the page
                    contains 'checkout' (provided that your checkout URL includes the word
                    'checkout').
                </p>
                <p>Page targeting rules do not check URL parameters.</p>
                <PageInclusionFields />
                <PageExclusionFields />
            </div>
        </>
    );
};

export default PageFields;
