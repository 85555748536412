import React, { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { BelowFormLinks, InputField } from '../../default/Form/Form';
import styles from '../../../css/default.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { createAccountSchema } from '../../../schemas/AccountSchemas';
import { Loading, Error } from '../../default/States/States';
import { Link } from 'react-router-dom';
import { useCreateAccount } from '../../../hooks/user/useCreateAccount/useCreateAccount';
import { CreateAccountFormInputs, CreateAccountFormProps } from './types';

export const CreateAccountForm: FC<CreateAccountFormProps> = ({
    affiliateForm,
    customRedirect,
}) => {
    const { error, loading, createAccount } = useCreateAccount();
    const handleSubmit: SubmitHandler<CreateAccountFormInputs> = async ({
        passwordConfirmation,
        ...releventFormData
    }) => createAccount(releventFormData, affiliateForm, customRedirect);
    const methods = useForm<CreateAccountFormInputs>({
        resolver: yupResolver(createAccountSchema),
        mode: 'onTouched',
        defaultValues: {
            email: '',
            password: '',
            passwordConfirmation: '',
        },
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={styles.formInner}>
                        <InputField type='text' title='email' placeholder='joe@bloggs.com' />
                        <InputField type='password' title='password' placeholder='********' />
                        <InputField
                            type='password'
                            title='passwordConfirmation'
                            placeholder='********'
                            alias='Re-enter Password'
                        />
                    </div>

                    {loading ? (
                        <Loading />
                    ) : (
                        <button type='submit' className={styles.btnPrimary}>
                            {affiliateForm ? 'Register' : 'Create Account'}
                        </button>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error} marginTop={true} />}
        </>
    );
};

export const CreateAccountLinks = () => (
    <BelowFormLinks>
        Already have an account? <Link to='/login'>Log in</Link>.
    </BelowFormLinks>
);

export const CreateAffiliateAccountLinks = () => (
    <BelowFormLinks>
        Already have an affiliate or user account? You can{' '}
        <Link to='/affiliates/login'>log in</Link> as an affiliate.
    </BelowFormLinks>
);
