import React, { useEffect } from 'react';
import styles from '../../../../../../css/default.module.scss';
import { Back } from '../../../../../../components/default/Navigation/Navigation';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { userAvatarPreviousExperienceMeta } from '../../../../../misc/MetaTags';
import PreviousExperienceForm from '../../../../../../components/account/profile/PreviousExperienceForm/PreviousExperienceForm';
import { useCheckUserPreviousExperienceExistsQuery } from '../../../../../../graphql/generated/schema';
import { useNavigate } from 'react-router-dom';

const PreviousExperience = () => {
    const [{ data }, checkExperience] = useCheckUserPreviousExperienceExistsQuery({
        pause: true,
    });
    const completedProfile = data?.checkUserPreviousExperienceExists;
    const navigate = useNavigate();

    useEffect(() => {
        checkExperience();

        if (completedProfile) {
            navigate('/user/profile');
        }
    }, [checkExperience, completedProfile, navigate]);

    return (
        <div>
            <h1 className={styles.centralisedText}>Previous Experience</h1>
            <PreviousExperienceForm />
            <Back />
        </div>
    );
};

export default withPage(PreviousExperience)({
    meta: userAvatarPreviousExperienceMeta,
    innerSpacing: true,
});
