import React from 'react';
import { paymentFailedMeta } from '../../../../misc/MetaTags';
import { withPage } from '../../../../../components/layout/Page/Page';
import { HeroArea } from '../../../../../components/default/Areas/Areas';

const PaymentCancelled = () => (
    <HeroArea
        title='Payment Aborted'
        description='You aborted making the payment.'
        buttonText='View Plans'
        buttonLink='/user/plan'
        withBackButton={true}
    />
);

export default withPage(PaymentCancelled)({ meta: paymentFailedMeta });
