import React from 'react';
import ScriptInstallation from '../../../components/account/Dashboard/ScriptInstallation/ScriptInstallation';
import { withPage } from '../../../components/layout/Page/Page';
import { scriptSetupMeta } from '../../misc/MetaTags';
import styles from '../../../css/default.module.scss';

const ScriptSetup = () => (
    <>
        <h1 className={styles.centralisedText}>Set up your tracking script</h1>
        <ScriptInstallation />
    </>
);

export default withPage(ScriptSetup)({ meta: scriptSetupMeta, innerSpacing: true });
