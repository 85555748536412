import * as Yup from 'yup';

// Function to validate single form presence
const validateSingleForm = (value: string | undefined): boolean => {
    const formMatches = value?.match(/<form[^>]*>/g);
    return formMatches ? formMatches.length === 1 : false;
};

// Function to validate the presence of an email input field
const validateEmailField = (value: string | undefined): boolean => {
    return /<input[^>]*\bname=["']?email["']?\b[^>]*>/i.test(value || '');
};

// Function to validate first_name field presence if two inputs are present
const validateFirstNameField = (value: string | undefined): boolean => {
    const inputFields = value?.match(/<input[^>]*>/gi) || [];
    if (inputFields.length < 2) {
        return true; // Valid if there are fewer than 2 input fields
    }

    const hasFirstNameField = inputFields.some((input) => {
        const nameMatch = input.match(/name=["']?([^"']+)["']?/i);
        return nameMatch && nameMatch[1] === 'first_name'; // Check if the name is exactly 'first_name'
    });

    return hasFirstNameField; // Returns true if at least one field is named 'first_name'
};

// Function to validate maximum input fields
const validateMaxInputFields = (value: string | undefined): boolean => {
    const inputFields = value?.match(/<input[^>]*>/gi) || [];
    return inputFields.length <= 2; // Fails if there are more than 2 inputs
};

export const createCampaignSchema = Yup.object().shape({
    campaignName: Yup.string().required('Campaign name is required'),
    campaignType: Yup.string().required('Campaign type is required'),
    campaignStoreInOurDatabase: Yup.string().required('Please select an option'),
    popupName: Yup.string().required('Popup name is required'),
    popupHtml: Yup.string()
        .required('Popup HTML is required')
        .when('campaignStoreInOurDatabase', {
            is: 'true', // Only run these validations if campaignStoreInOurDatabase is 'true'
            then: Yup.string()
                .test(
                    'single-form',
                    'Please include one form only. Remember in stage 1 you selected to store leads in our database, hence you need a form, otherwise select no for storing leads in stage 1.',
                    validateSingleForm
                )
                .test(
                    'email-field',
                    'The form must include an email field with a name attribute of "email".',
                    validateEmailField
                )
                .test(
                    'first-name-field',
                    'If there are two input fields, at least one must be titled "first_name".',
                    validateFirstNameField
                )
                .test(
                    'max-input-fields',
                    'The form must not contain more than 2 input fields.',
                    validateMaxInputFields
                ),
            otherwise: Yup.string(), // If campaignStoreInOurDatabase is not 'true', skip these tests
        }),
    popupCss: Yup.string(),
    popupJs: Yup.string(),
    popupHowToShow: Yup.string().oneOf(
        ['immediate', 'exit_intent'],
        'Select how to show this popup'
    ),
    popupShowAfterScrollPercentage: Yup.number()
        .nullable()
        .min(0, 'Percentage must be between 0 and 100')
        .max(100, 'Percentage must be between 0 and 100'),
    popupTriggeredByClickingElements: Yup.string().nullable(),
    campaignPages: Yup.object().shape({
        exclude: Yup.array()
            .of(
                Yup.object().shape({
                    type: Yup.string()
                        .oneOf(['contains', 'equals'], 'Invalid type')
                        .required('Select a condition'),
                    value: Yup.string().required('Value is required'),
                })
            )
            .nullable(),
        include: Yup.array()
            .of(
                Yup.object().shape({
                    type: Yup.string()
                        .oneOf(['contains', 'equals'], 'Invalid type')
                        .required('Select a condition'),
                    value: Yup.string().required('Value is required'),
                })
            )
            .nullable(),
    }),
    campaignCookies: Yup.object().shape({
        exclude: Yup.array().of(Yup.string()).nullable(),
        include: Yup.array().of(Yup.string()).nullable(),
    }),
    campaignUrlParams: Yup.object().shape({
        exclude: Yup.array().of(Yup.string()).nullable(),
        include: Yup.array().of(Yup.string()).nullable(),
    }),
    campaignDevices: Yup.array()
        .of(Yup.string().oneOf(['mobiles', 'desktops']))
        .min(1, 'At least one target device must be selected')
        .required('At least one target device must be selected'),
});
