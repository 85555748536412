import { useEffect, useState } from 'react';
import { useGetAvailableAvatarGoalsQuery } from '../../../graphql/generated/schema';
import { UseGetAvailableAvatarGoals } from './types';

// rename to something like useGetSelectOptions
const useGetAvailableAvatarGoals: UseGetAvailableAvatarGoals = () => {
    const [{ data: availableGoals, fetching: loadingAvailableGoals, error: availableGoalsError }] =
        useGetAvailableAvatarGoalsQuery();
    const [loading, setLoading] = useState<boolean>(loadingAvailableGoals);

    const refinedGoals = availableGoals?.getAvailableAvatarGoals?.map((goal) => ({
        name: goal?.goalName,
        value: goal?.id,
    }));

    useEffect(() => {
        setLoading(loadingAvailableGoals || (!refinedGoals?.length && !availableGoalsError));
    }, [refinedGoals, loadingAvailableGoals, availableGoalsError]);

    return { availableGoals: refinedGoals, loadingAvailableGoals: loading, availableGoalsError };
};

export default useGetAvailableAvatarGoals;
