import React from 'react';
import {
    NewPasswordForm,
    NewPasswordLinks,
} from '../../../../components/account/NewPassword/NewPassword';
import { withPage } from '../../../../components/layout/Page/Page';
import { newPasswordMeta } from '../../../misc/MetaTags';
import defaultStyles from '../../../../css/default.module.scss';

const NewPassword = () => (
    <>
        <h1 className={defaultStyles.centralisedText}>Want to Change your Password?</h1>
        <p>
            Enter the email address of your account to receive instructions about resetting your
            password.
        </p>
        <NewPasswordForm />
        <NewPasswordLinks />
    </>
);

export default withPage(NewPassword)({ meta: newPasswordMeta, innerSpacing: true, whiteBox: true });
