import React from 'react';
import { ProcessStepperProps } from './types';
import styles from './styles.module.scss';

const ProcessStepper = ({ count }: ProcessStepperProps): JSX.Element | null => {
    if (!count) return null;

    return (
        <ol className={styles.container}>
            {[...Array(count)].map((_, index) => (
                <li key={index + 1}>0{index + 1}</li>
            ))}
        </ol>
    );
};

export default ProcessStepper;
