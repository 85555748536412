import { useContext, useState } from 'react';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { UserContextType } from '../../../contexts/UserContext/types';
import { getPriceId } from '../../../utils/HelperFunctions/HelperFunctions';
import { PAID_PLANS_ARRAY, PaidPlans, Plans } from '../../../utils/global-variables';
import { useProcessCheckoutSessionMutation } from '../../../graphql/generated/schema';
import { useNavigate } from 'react-router-dom';
import { UseChangePlanCheckout } from './types';

export const useChangePlanCheckout: UseChangePlanCheckout = () => {
    const [, generateCheckoutUrl] = useProcessCheckoutSessionMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { user } = useContext<UserContextType>(UserContext);
    const { email, userPlan } = user || {};
    const navigate = useNavigate();

    const changePlan = async (plan: Plans): Promise<void> => {
        const hasPaidPlan = PAID_PLANS_ARRAY.includes(userPlan as PaidPlans);

        if (plan === 'FREE') {
            navigate('/user/plan/cancel');
            return;
        }

        if (hasPaidPlan) {
            navigate(`/user/plan/change?plan=${plan}`);
            return;
        }

        setLoading(true);

        const planPriceId = getPriceId(plan as PaidPlans);
        const res = await generateCheckoutUrl({
            email: email as string,
            planId: planPriceId as string,
        });
        const redirectUrl = res?.data?.processCheckoutSession;

        if (!redirectUrl || res.error) {
            setError(res.error?.message || 'Could not process plan change');
            setLoading(false);
            return;
        }

        window.location.href = redirectUrl;
    };

    return { changePlan, loading, error };
};
