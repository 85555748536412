import React, { useEffect } from 'react';
import { CodeField } from '../../../../default/Form/Form';
import { useFormContext } from 'react-hook-form';

const CreateCampaignStageTwo = () => {
    // @todo non-mvp: use a package to validate the HTML when clicking proceed

    const { setValue, watch, setError, clearErrors } = useFormContext();

    const popupHtml = watch('popupHtml');
    const popupCss = watch('popupCss');
    const popupJs = watch('popupJs');

    const handleHtmlEditorChange = (value: string): void => {
        setValue('popupHtml', value);

        if (value.trim() === '') {
            setError('popupHtml', {
                type: 'manual',
                message: 'HTML content is required.',
            });
        } else {
            clearErrors('popupHtml');
        }
    };

    const handleCssEditorChange = (value: string): void => {
        setValue('popupCss', value);
    };

    const handleJsEditorChange = (value: string): void => {
        setValue('popupJs', value);
    };

    useEffect(() => {
        // Check if popupHtml is empty on component mount
        if (popupHtml?.trim() === '') {
            setError('popupHtml', {
                type: 'manual',
                message: 'HTML content is required.',
            });
        } else {
            clearErrors('popupHtml');
        }
    }, [popupHtml, setError, clearErrors]);

    return (
        <>
            <CodeField
                alias='Enter HTML'
                title='popupHtml'
                language='html'
                onChange={handleHtmlEditorChange}
                defaultValue={popupHtml}
            />
            <CodeField
                alias='Enter CSS'
                title='popupCss'
                language='css'
                onChange={handleCssEditorChange}
                defaultValue={popupCss}
            />
            <CodeField
                alias='Enter JavaScript'
                title='popupJs'
                language='javascript'
                onChange={handleJsEditorChange}
                defaultValue={popupJs}
            />
        </>
    );
};

export default CreateCampaignStageTwo;
