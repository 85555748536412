import React from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { userDashboardMeta } from '../../../misc/MetaTags';
import styles from '../../../../components/default/StackedList/styles.module.scss';
import defaultStyles from '../../../../css/default.module.scss';
import StackedList from '../../../../components/default/StackedList/StackedList';
import { LINKS } from './variables';
import ProfilePrompt from '../../../../components/account/profile/ProfilePrompt/ProfilePrompt';
import { UserStatus } from '../../../../components/layout/Page/UserStatus/UserStatus';
import RecentCampaigns from '../../../../components/account/campaigns/RecentCampaigns/RecentCampaigns';
import CurrentMonthlySessions from '../../../../components/account/Dashboard/CurrentMonthlySessions/CurrentMonthlySessions';

const Dashboard = () => {
    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Dashboard</h1>
            <CurrentMonthlySessions />
            <UserStatus />
            <ProfilePrompt />
            <div className={styles.panelsContainer}>
                <StackedList items={LINKS} title='Quick Links' areLinks={true} />
                <RecentCampaigns />
            </div>
        </>
    );
};

export default withPage(Dashboard)({ meta: userDashboardMeta, innerSpacing: true });
