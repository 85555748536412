import React from 'react';
import { paymentMethodUpdateCancelledMeta } from '../../../../misc/MetaTags';
import { withPage } from '../../../../../components/layout/Page/Page';
import { HeroArea } from '../../../../../components/default/Areas/Areas';

const PaymentMethodUpdateCancelled = () => (
    <HeroArea
        title='Payment Details Update Cancelled'
        description='You cancelled updating your default payment details.'
        buttonText='View Account'
        buttonLink='/user'
        withBackButton={true}
    />
);

export default withPage(PaymentMethodUpdateCancelled)({ meta: paymentMethodUpdateCancelledMeta });
