const YES_OR_NO = [
    {
        title: 'Yes',
        value: 'true',
    },
    {
        title: 'No',
        value: 'false',
    },
];

export const CONDUCTED_TESTS_BEFORE_RADIO_NAME = 'conductedTestsBefore';
export const CONDUCTED_TESTS_BEFORE_RADIO_PROPS = {
    label: 'Have you created any A/B tests before?',
    group: CONDUCTED_TESTS_BEFORE_RADIO_NAME,
    options: YES_OR_NO,
};

export const MOVED_PLATFORM_RADIO_NAME = 'movedPlatform';
export const MOVED_PLATFORM_RADIO_PROPS = {
    label: 'Have you moved from another A/B testing platform?',
    group: MOVED_PLATFORM_RADIO_NAME,
    options: YES_OR_NO,
};
