import React, { FC } from 'react';
import styles from './styles.module.scss';
import {
    faCheckCircle,
    faExclamationTriangle,
    faInfoCircle,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingImage from '../../../images/loading.svg';
import {
    SuccessProps,
    LoadingProps,
    NoneFoundProps,
    ErrorPropsWithMessage,
    ErrorPropsWithChildren,
} from './types';

export const Error = ({
    msg,
    marginTop,
    marginBottom,
    cls = '',
}: ErrorPropsWithMessage): JSX.Element => (
    <div
        className={`${styles.situationText} ${styles.error} ${cls} ${
            marginTop ? styles.marginTop : ''
        } ${marginBottom ? styles.marginBottom : ''}`}
    >
        <FontAwesomeIcon icon={faTimesCircle} className={styles.error} aria-label='error' />{' '}
        <span>{msg}</span>
    </div>
);

export const ErrorWithChildren: FC<ErrorPropsWithChildren> = ({
    marginTop,
    marginBottom,
    cls = '',
    children,
    centralised = false,
}) => {
    const classes = `${styles.situationText} ${styles.error} ${
        centralised ? styles.centralised : ''
    } ${cls} ${marginTop ? styles.marginTop : ''} ${marginBottom ? styles.marginBottom : ''}`;

    return (
        <div className={classes}>
            <FontAwesomeIcon icon={faTimesCircle} className={styles.error} aria-label='error' />{' '}
            <span>{children}</span>
        </div>
    );
};

export const Success = ({ msg, marginTop, cls = '' }: SuccessProps): JSX.Element => (
    <div
        className={`${styles.situationText} ${styles.success} ${cls} ${
            marginTop ? styles.marginTop : ''
        }`}
    >
        <FontAwesomeIcon icon={faCheckCircle} className={styles.error} aria-label='success' />{' '}
        <span>{msg}</span>
    </div>
);

export const Loading = ({
    cls = '',
    centralised = false,
    entirePage = false,
    marginTop = false,
    marginBottom = false,
}: LoadingProps): JSX.Element => {
    const classes = `${styles.loading} ${cls} ${centralised ? styles.centralised : ''} ${
        entirePage ? styles.centralisedEntirePage : ''
    } ${marginTop ? styles.marginTop : ''} ${marginBottom ? styles.marginBottom : ''}`;

    return (
        <div className={classes}>
            <img src={LoadingImage} alt='loading' />
        </div>
    );
};

export const NoneFound = ({
    msg = 'None found',
    cls = '',
    centralised = false,
}: NoneFoundProps): JSX.Element => (
    <p className={`${styles.noneFound} ${cls} ${centralised ? styles.centralised : ''}`}>{msg}</p>
);

export const ErrorText = ({
    msg,
    marginTop,
    marginBottom,
    cls = '',
}: ErrorPropsWithMessage): JSX.Element => (
    <p
        className={`${styles.errorText} ${cls} ${marginTop ? styles.marginTop : ''} ${
            marginBottom ? styles.marginBottom : ''
        }`}
    >
        {msg}
    </p>
);

export const Warning = ({
    msg,
    marginTop,
    marginBottom,
    cls = '',
    centralised = false,
}: ErrorPropsWithMessage): JSX.Element => {
    const classes = `${styles.situationText} ${styles.warning} ${cls} ${
        marginTop ? styles.marginTop : ''
    } ${marginBottom ? styles.marginBottom : ''} ${centralised ? styles.centralised : ''}`;

    return (
        <div className={classes}>
            <FontAwesomeIcon
                icon={faExclamationTriangle}
                className={styles.warning}
                aria-label='warning'
            />{' '}
            <span>{msg}</span>
        </div>
    );
};

export const WarningWithChildren: FC<ErrorPropsWithChildren> = ({
    marginTop,
    marginBottom,
    cls = '',
    children,
    centralised = false,
}) => {
    const classes = `${styles.situationText} ${styles.warning} ${cls} ${
        marginTop ? styles.marginTop : ''
    } ${marginBottom ? styles.marginBottom : ''} ${centralised ? styles.centralised : ''}`;

    return (
        <div className={classes}>
            <FontAwesomeIcon
                icon={faExclamationTriangle}
                className={styles.warning}
                aria-label='warning'
            />{' '}
            <span>{children}</span>
        </div>
    );
};

export const Info = ({
    msg,
    marginTop,
    marginBottom,
    cls = '',
    centralised = false,
}: ErrorPropsWithMessage): JSX.Element => {
    const classes = `${styles.situationText} ${styles.info} ${cls} ${
        marginTop ? styles.marginTop : ''
    } ${marginBottom ? styles.marginBottom : ''} ${centralised ? styles.centralised : ''}`;

    return (
        <div className={classes}>
            <FontAwesomeIcon icon={faInfoCircle} className={styles.info} aria-label='info' />{' '}
            <span>{msg}</span>
        </div>
    );
};

export const InfoWithChildren: FC<ErrorPropsWithChildren> = ({
    marginTop,
    marginBottom,
    cls = '',
    children,
    centralised = false,
}) => {
    const classes = `${styles.situationText} ${styles.info} ${cls} ${
        marginTop ? styles.marginTop : ''
    } ${marginBottom ? styles.marginBottom : ''} ${centralised ? styles.centralised : ''}`;

    return (
        <div className={classes}>
            <FontAwesomeIcon icon={faInfoCircle} className={styles.info} aria-label='info' />{' '}
            <span>{children}</span>
        </div>
    );
};
