import {
    faUserCircle,
    // faDollarSign,
    faBullhorn,
    faFunnelDollar,
} from '@fortawesome/free-solid-svg-icons';

export const LINKS = [
    { to: '/user/campaigns', icon: faBullhorn, label: 'Campaigns' },
    { to: '/user/leads', icon: faFunnelDollar, label: 'Leads' },
    { to: '/user/profile', icon: faUserCircle, label: 'Profile' },
    // { to: '/user/profile/affiliate', icon: faDollarSign, label: 'Affiliates' }, // @todo RE_ADD_AFFILIATES
];
