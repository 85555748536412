import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const PlanTermsSnippet = () => (
    <p className={styles.container}>
        Prices are in British Pounds (GBP). If your primary currency differs, payments will be
        transferred to British Pounds automatically. By selecting a plan, you are confirming that
        you have read and agree to our <Link to='/terms'>terms of service</Link>.
    </p>
);
