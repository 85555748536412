import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AffiliateLoginFormLinks, LoginForm } from '../../../components/account/Login/Login';
import { withPage } from '../../../components/layout/Page/Page';
import { UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { affiliateLoginMeta } from '../../misc/MetaTags';
import defaultStyles from '../../../css/default.module.scss';

const AffiliateLogin = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) navigate('/user/profile/affiliate');
    }, [user, navigate]);

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Affiliate Login</h1>
            <LoginForm customRedirect='/user/profile/affiliate' />
            <AffiliateLoginFormLinks />
        </>
    );
};

export default withPage(AffiliateLogin)({
    meta: affiliateLoginMeta,
    innerSpacing: true,
    whiteBox: true,
});
