import React from 'react';
import { HeroArea } from '../../../../../../components/default/Areas/Areas';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { newCampaignSuccessMeta } from '../../../../../misc/MetaTags';

const NewCampaignSuccess = () => (
    <HeroArea
        title='Campaign Created Successfully'
        description='You have successfully created a new campaign. You can activate it from the campaigns page. Make sure you have also installed the tracking script if you have not done so already.'
        buttonText='View Campaigns'
        buttonLink='/user/campaigns'
        buttonTwoText='Install Tracking Script'
        buttonTwoLink='/support/script-setup'
    />
);

export default withPage(NewCampaignSuccess)({ meta: newCampaignSuccessMeta });
