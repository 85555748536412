import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { userLeadsMeta } from '../../../misc/MetaTags';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';
import { Loading, Error, NoneFound } from '../../../../components/default/States/States';
import { withPage } from '../../../../components/layout/Page/Page';
import { SelectCampaignDropdownForm } from '../../../../components/account/leads/SelectCampaignDropdownForm/SelectCampaignDropdownForm';
import { CampaignSignUpDataDocument, SignUpData } from '../../../../graphql/generated/schema';
import useLazyQuery from '../../../../hooks/other/useLazyQuery/useLazyQuery';
import { Paginator } from '../../../../components/default/Paginator/Paginator';
import { DynamicTable } from '../../../../components/default/Table/Table';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatTimestampToYYYYMMDD from '../../../../utils/HelperFunctions/formatTimestampToYYYYMMDD';
import DeleteLeadModal from '../../../../components/account/leads/DeleteLeadModal/DeleteLeadModal';
import defaultStyles from '../../../../css/default.module.scss';
import DeleteLeadsModal from '../../../../components/account/leads/DeleteLeadsModal/DeleteLeadsModal';
import styles from './styles.module.scss';
import { Back } from '../../../../components/default/Navigation/Navigation';

const Leads = () => {
    const [deleteLeadModalIsOpen, setDeleteLeadModalIsOpen] = useState<boolean>(false);
    const [deleteLeadsModalIsOpen, setDeleteLeadsModalIsOpen] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [total, setTotal] = useState<number>(0);
    const [selectedCampaign, setSelectedCampaign] = useState<string>('');
    const [signUpData, setSignUpData] = useState([]);
    const itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
    const params = useParams();
    const currentPage = Number(params.currentPage) || 1;
    const skip = (currentPage - 1) * itemsPerPage;
    const headers = useMemo(() => ['Date', 'Name', 'Email', 'Delete'], []);
    const [loadingCampaignData, setLoadingCampaignData] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [shouldFetch, setShouldFetch] = useState(true);

    const fetchCampaignData = useLazyQuery(CampaignSignUpDataDocument, {
        campaignId: selectedCampaign,
        limit: itemsPerPage,
        offset: skip,
    });

    const onClick = useCallback<(newRow: any) => void>((newRow: any) => {
        setDeleteLeadModalIsOpen(true);
        setSelectedRow(newRow);
    }, []);

    useEffect(() => {
        (async () => {
            if (!selectedCampaign && !shouldFetch) return;
            try {
                setLoadingCampaignData(true);
                const res = await fetchCampaignData();

                if (!res || res?.error) return;

                const sortedCampaigns = res?.data?.campaignSignUpData?.signUpData.map(
                    ({ createdAt, firstName, email, id }: SignUpData) => ({
                        date: formatTimestampToYYYYMMDD(createdAt),
                        name: firstName,
                        email,
                        id,
                        icon: <FontAwesomeIcon icon={faTrash} aria-label='edit' />,
                    })
                );

                setTotal(res?.data?.campaignSignUpData?.total);
                setSignUpData(sortedCampaigns);
                setShouldFetch(false);
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoadingCampaignData(false);
            }
        })();
    }, [
        fetchCampaignData,
        setLoadingCampaignData,
        selectedCampaign,
        itemsPerPage,
        skip,
        shouldFetch,
    ]);

    if (loadingCampaignData) return <Loading entirePage={true} />;
    if (error) return <Error msg={error} />;

    return (
        <>
            <h1>Leads</h1>
            <SelectCampaignDropdownForm
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
            />
            {signUpData?.length > 0 && selectedCampaign && (
                <>
                    <DynamicTable data={signUpData} headers={headers} onClick={onClick} />
                    <Paginator
                        currentPage={currentPage}
                        itemCount={total}
                        itemsPerPage={itemsPerPage}
                        path='/user/leads'
                    />
                    <DeleteLeadModal
                        selectedRow={selectedRow}
                        modalIsOpen={deleteLeadModalIsOpen}
                        setModalIsOpen={setDeleteLeadModalIsOpen}
                        refetch={() => setShouldFetch(true)}
                    />
                </>
            )}
            {selectedCampaign && !(signUpData?.length > 0) && (
                <NoneFound msg='No leads found' centralised={true} />
            )}
            {signUpData?.length > 0 && selectedCampaign && (
                <>
                    <p className={styles.totalLeads}>Total Leads: {total}</p>
                    <button
                        className={`${defaultStyles.btnAsText} ${styles.deleteLeadsLink}`}
                        onClick={() => setDeleteLeadsModalIsOpen(true)}
                    >
                        Delete Leads
                    </button>
                    <DeleteLeadsModal
                        campaignId={selectedCampaign}
                        modalIsOpen={deleteLeadsModalIsOpen}
                        setModalIsOpen={setDeleteLeadsModalIsOpen}
                        refetch={() => setShouldFetch(true)}
                    />
                </>
            )}
            <Back to='/user' text='Back to User Dashboard' />
        </>
    );
};

export default withPage(Leads)({ meta: userLeadsMeta, innerSpacing: true });
