import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useResetPwMutation } from '../../../graphql/generated/schema';
import { UseResetPasswordOutputs, ChangePasswordFn } from './types';

export const useResetPassword: UseResetPasswordOutputs = () => {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<string | null>(null);
    const [{ fetching: loading }, resetPasswordMutation] = useResetPwMutation();

    const changePassword: ChangePasswordFn = async (password, setSuccess) => {
        const [id, token] = [searchParams.get('id'), searchParams.get('jwt')];

        if (!id || !token) {
            setError(
                'Wrong ID or token. Reset password link may have expired - try requesting a new password-reset email again.'
            );
            return;
        }

        try {
            const { data, error } = await resetPasswordMutation({
                input: { password, id, token },
            });
            if (error || !data) {
                const errorText = error?.message || 'Could not reset password';
                setError(`HTTP error: ${errorText}`);
                return;
            }
            setSuccess(true);
        } catch (err: any) {
            setError(err.message);
        }
    };

    return { loading, error, changePassword };
};
