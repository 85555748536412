import React, { useCallback, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { userCampaignsMeta } from '../../../../misc/MetaTags';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../../utils/global-variables';
import { Loading, Error, NoneFound } from '../../../../../components/default/States/States';
import { withPage } from '../../../../../components/layout/Page/Page';
import { useGetCampaigns } from '../../../../../hooks/campaigns/useGetCampaigns/useGetCampaigns';
import { Paginator } from '../../../../../components/default/Paginator/Paginator';
import { DynamicTable } from '../../../../../components/default/Table/Table';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import defaultStyles from '../../../../../css/default.module.scss';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';

const Campaigns = () => {
    const itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
    const params = useParams();
    const currentPage = Number(params.currentPage) || 1;
    const skip = (currentPage - 1) * itemsPerPage;
    const headers = useMemo(
        () => [
            'Name',
            'Conversions',
            'Conversion Rate',
            'Click-through Rate',
            'Close Rate',
            'Active',
        ],
        []
    );
    const { total, campaigns, loading, error } = useGetCampaigns({
        limit: itemsPerPage,
        skip,
    });
    const navigate = useNavigate();
    const onClick = useCallback<(newRow: any) => void>(
        (newRow: any) => {
            navigate(`/user/campaigns/${newRow.id}`);
        },
        [navigate]
    );

    if (loading) return <Loading entirePage={true} />;
    if (error) return <Error msg={error.message} />;

    return (
        <>
            <div
                className={`${defaultStyles.buttonsContainer} ${defaultStyles.spaced} ${defaultStyles.centrallyAligned} ${styles.buttonsContainer} ${styles.specificity}`}
            >
                <h1>Campaigns</h1>
                <Link
                    to='/user/campaigns/new'
                    className={`${defaultStyles.btnPrimary} ${styles.newCampaignBtn} ${styles.specificity}`}
                >
                    <FontAwesomeIcon icon={faPlus} /> New Campaign
                </Link>
            </div>
            {campaigns?.length > 0 ? (
                <>
                    <DynamicTable data={campaigns} headers={headers} onClick={onClick} />
                    <Paginator
                        currentPage={currentPage}
                        itemCount={total}
                        itemsPerPage={itemsPerPage}
                        path='/user/campaigns'
                    />
                </>
            ) : (
                <NoneFound msg='No campaigns found' centralised={true} />
            )}
            <Back to='/user' text='Back to User Dashboard' />
        </>
    );
};

export default withPage(Campaigns)({ meta: userCampaignsMeta, innerSpacing: true });
