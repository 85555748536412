import React, { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { BelowFormLinks, InputField } from '../../default/Form/Form';
import styles from '../../../css/default.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading, Error } from '../../default/States/States';
import { Link, useLocation } from 'react-router-dom';
import { loginSchema } from '../../../schemas/AccountSchemas';
import { useLogin } from '../../../hooks/user/useLogin/useLogin';
import { LoginFormProps, LoginFormInputs } from './types';

export const LoginForm: FC<LoginFormProps> = ({ customRedirect }) => {
    const { loading, error, logUserIn } = useLogin();
    const handleSubmit: SubmitHandler<LoginFormInputs> = (formData) =>
        logUserIn(formData, customRedirect);
    const methods = useForm<LoginFormInputs>({
        resolver: yupResolver(loginSchema),
        mode: 'onTouched',
        defaultValues: {
            email: '',
            password: '',
        },
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={styles.formInner}>
                        <InputField type='text' title='email' placeholder='joe@bloggs.com' />
                        <InputField type='password' title='password' placeholder='********' />
                    </div>

                    {loading ? (
                        <Loading />
                    ) : (
                        <button type='submit' className={styles.btnPrimary}>
                            Log in
                        </button>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error as string} marginTop={true} />}
        </>
    );
};

export const LoginFormLinks = () => {
    const { pathname } = useLocation();

    return (
        <>
            <BelowFormLinks>
                <Link to='/new-password' state={{ previousPath: pathname }}>
                    Forgot password
                </Link>
                .
            </BelowFormLinks>
            <BelowFormLinks>
                Don't have an account? <Link to='/create-account'>Create an account</Link>.
            </BelowFormLinks>
        </>
    );
};

export const AffiliateLoginFormLinks = () => {
    const { pathname } = useLocation();

    return (
        <>
            <BelowFormLinks>
                <Link to='/new-password' state={{ previousPath: pathname }}>
                    Forgot password
                </Link>
                .
            </BelowFormLinks>
            <BelowFormLinks>
                Haven't registered as an affiliate? If you have a user account then you will
                automatically be an affiliate and can log in with that account, otherwise you'll
                need to <Link to='/affiliates/register'>create an account</Link>.
            </BelowFormLinks>
        </>
    );
};
