import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { checkTokenExpiry, getCookie } from '../../../utils/HelperFunctions/HelperFunctions';
// import AreasOfImprovementPopup from '../AreasOfImprovementPopup/AreasOfImprovementPopup';

export const PrivateRoute = ({ children }: any) => {
    const { user, setAccount } = useContext<UserContextType>(UserContext);
    const token = getCookie('accountToken');
    const tokenHasExpired = checkTokenExpiry(token);

    useEffect(() => {
        if (!token || tokenHasExpired) {
            /*
                --- DO NOT DELETE THIS COMMENT ---

                NOTE: if the FE & BE do not set congruent expiry dates (in setAccountCookies & User.signToken), the FE may still fetch an accountToken
                (i.e. a cookie, `const token = getCookie('accountToken')` as above) and send it to the BE with API requests (as !token above is false)
                and the BE will throw a 401 error and setAccount(null) will not run, hence the user will not log out automatically as the user in the
                context would not update - so make sure FE & BE set correct expiry dates!

                if for whatever reason this account context issue reoccurs, consider the following:
                - why not just use error thrown by BE & set account to null on FE?
                - or do it at FE by comparing date in token & seeing if it's greater than current date - if true, it's invalid - presumably should not
                need to do this because if the cookie has expired, `getCookie('accountToken')` should return null
                // @todo: have had to do it like this ^, so getCookie can get an expired cookie - is getCookie buggy?
                // if so, could fix getCookie so it cannot get expired cookies, then remove jwt_decode?
            */

            setAccount(null);
        }
    }, [token, setAccount, tokenHasExpired]);

    if (!user || !token || tokenHasExpired) {
        return <Navigate to='/login' />;
    }

    return (
        <>
            {/* <AreasOfImprovementPopup /> */}
            {children}
        </>
    );
};
