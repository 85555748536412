import { PlanCard } from '../../../components/default/Cards/PlanCards/types';

// n.b.: beware of changing the title, because the refinedPlans in <PlanCards/> uses it programatically
// also do not change the order of items just in case
export const PLANS: PlanCard[] = [
    {
        title: 'FREE',
        description: 'Everything needed to get started.',
        price: { dollars: 0, cents: 0 },
        button: {
            buttonLink: '/create-account',
            buttonText: 'Create Free Account',
        },
        benefits: [
            '500 visitors/month (approx 1,500 page views)', // @todo: make this come from a variable somewhere
            '1 active popup at a time',
            'Unlimited websites',
            'Unlimited subscribers',
            'No subscriber fees',
            'Our branding at bottom of pages',
        ],
    },
    {
        title: 'LITE',
        description: 'Drive more revenue without as many limits.',
        price: { dollars: 34, cents: 99 },
        button: { buttonLink: '/create-account', buttonText: 'Buy Now' },
        benefits: [
            '10,000 visitors/month (approx 30,000 page views)', // @todo: make this come from a variable somewhere
            'Unlimited popups',
            'Unlimited websites',
            'Unlimited subscribers',
            'No subscriber fees',
            'Unbranded',
        ],
    },
    {
        title: 'STANDARD',
        description: 'For growing businesses that want extra revenue.',
        price: { dollars: 64, cents: 99 },
        button: { buttonLink: '/create-account', buttonText: 'Buy Now' },
        benefits: [
            '30,000 visitors/month (approx 90,000 page views)', // @todo: make this come from a variable somewhere
            'Unlimited popups',
            'Unlimited websites',
            'Unlimited subscribers',
            'No subscriber fees',
            'Unbranded',
        ],
    },
    {
        title: 'PRO',
        description: 'For some of the best professionals.',
        price: { dollars: 119, cents: 99 },
        button: { buttonLink: '/create-account', buttonText: 'Buy Now' },
        benefits: [
            '100,000 visitors/month (approx 300,000 page views)', // @todo: make this come from a variable somewhere
            'Unlimited popups',
            'Unlimited websites',
            'Unlimited subscribers',
            'No subscriber fees',
            'Unbranded',
        ],
    },
    {
        title: 'EXPERT',
        description: 'For the best who need more.',
        price: { dollars: 249, cents: 99 },
        button: { buttonLink: '/create-account', buttonText: 'Buy Now' },
        benefits: [
            '250,000 visitors/month (approx 750,000 page views)', // @todo: make this come from a variable somewhere
            'Unlimited popups',
            'Unlimited websites',
            'Unlimited subscribers',
            'No subscriber fees',
            'Unbranded',
        ],
    },
];
