import React from 'react';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { userAvatarGoalsMeta } from '../../../../../misc/MetaTags';
import styles from '../../../../../../css/default.module.scss';
import EditGoalsForm from '../../../../../../components/account/profile/EditGoalsForm/EditGoalsForm';
import { Back } from '../../../../../../components/default/Navigation/Navigation';

const Goals = () => (
    <div>
        <h1 className={styles.centralisedText}>Your Goals</h1>
        <EditGoalsForm />
        <Back />
    </div>
);

export default withPage(Goals)({ meta: userAvatarGoalsMeta, innerSpacing: true });
