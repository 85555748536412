export enum PopupType {
    Newsletter = 'Newsletter',
    Christmas = 'Christmas',
    Easter = 'Easter',
    Halloween = 'Halloween',
    Coupon = 'Coupon',
}

export type PopupTemplate = {
    name: string;
    types: PopupType[];
    html: string;
    css: string;
};

/*
  note:
  - background-color must be set on .popup-hawk-container-inner class
  - should not use .popup-hawk-container-outer class
*/
export const popupTemplates: PopupTemplate[] = [
    {
        name: 'Simple Sign-up',
        types: [PopupType.Newsletter],
        html: `<button class="popup-hawk-close-btn" onclick="window.popupHawk.closePopup()"></button>
<h2>Subscribe for 10% Off!</h2>
<p>Don't miss out on our discounted offer on all orders.</p>
<form>
  <label for="first_name">Name:</label>
  <input type="text" id="first_name" name="first_name" required>
  <label for="email">Email:</label>
  <input type="email" id="email" name="email" required>
  <button type="submit" class="popup-submit-btn">Submit</button>
</form>
<p class="popup-hawk-submission hidden">Thank you - form submitted successfully.</p>`,
        css: `div * {
  box-sizing: border-box;
  font-family: inherit;
}
.popup-hawk-container-inner {
  font-family: "Tahoma", "Arial";
  background-color: #fff;
  overflow: auto;
  max-height: 90vh;
  min-width: 300px;
  padding: 2em;
  box-sizing: border-box;
}
.popup-hawk-close-btn {
  padding: 0;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: transparent;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.popup-hawk-close-btn::before {
  content: "✕";
  color: red;
}
.popup-hawk-submission.hidden {
  display: none;
}
form {
  display: flex;
  flex-direction: column;
}
form label {
  font-weight: bold;
}
form input {
  margin: 1em 0;
  border-radius: 0px;
  border: 1px solid grey;
  box-sizing: border-box;
  height: 40px;
  padding: .5em 1em;
  background-color: #f3f3f3;
}
form button {
  border-radius: 0;
  border: 1px solid grey;
  max-width: fit-content;
  cursor: pointer;
  font-size: inherit;
  padding: .5em 1.5em;
  height: 40px;
  background-color: black;
  color: white;
  transition: 0.4s ease all;
}
form button:hover {
  color: black;
  transition: 0.4s ease all;
  background-color: #f3f3f3;
}
h2 {
  margin-top: 0;
  color: inherit;
  display: block;
  font-size: 20px;
  font-weight: bold;;
}
p {
  display: block;
  margin: 1em 0;
}
.popup-submit-btn {
  text-transform: none;
  font-weight: 700;
}`,
    },
    {
        name: 'Dark Sign-up',
        types: [PopupType.Newsletter],
        html: `<button class="popup-hawk-close-btn" onclick="window.popupHawk.closePopup()"></button>
<h2>Subscribe for 10% Off!</h2>
<p>Don't miss out on our discounted offer on all orders.</p>
<form>
<input type="email" id="email" name="email" placeholder="Enter your email" required>
<button type="submit" class="popup-submit-btn">GET 10% OFF</button>
</form>
<p class="popup-hawk-submission hidden">Thank you - form submitted successfully.</p>`,
        css: `.popup-hawk-container-inner * {
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
}
.popup-hawk-container-inner {
  font-family: Tahoma, Arial;
  background-color: #1c1c1c;
  color: #fff;
  overflow: auto;
  max-height: 90vh;
  min-width: 300px;
  padding: 2em;
  box-sizing: border-box;
  font-size: 16px;
}
.popup-hawk-close-btn {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 24px;
  cursor: pointer;
}
.popup-hawk-close-btn::before {
  content: "✕";
  color: #fff;
}
.popup-hawk-submission {
  margin-bottom: 0;
}
.hidden {
  display: none;
}
form {
  display: flex;
  flex-direction: column;
}
form input {
  margin: 0 0 1em;
  height: 40px;
  border-radius: 0;
  border: 1px solid grey;
  box-sizing: border-box;
  padding: 0.5em 1.5em;
  font-size: inherit;
  text-align: inherit;
  background-color: white;
}
form button {
  height: 40px;
  border-radius: 0;
  border: 1px solid grey;
  cursor: pointer;
  font-size: inherit;
  padding: 0.5em 1.5em;
  background-color: #be925c;
  color: #fff;
  transition: 0.4s ease all;
}
form button:hover {
  background-color: #f8e2c6;
  color: #1c1c1c;
  transition: 0.4s ease all;
}
h2 {
  margin: 0 auto;
  color: inherit;
  display: block;
  font-size: 24px;
  font-weight: 700;
  max-width: 90%;

}
p {
  display: block;
  opacity: 0.8;
  color: inherit;
  margin: 1.5em 0;
}
.popup-submit-btn {
  text-transform: none;
  font-weight: 700;
}
`,
    },
    {
        name: 'Simple Coupon',
        types: [PopupType.Coupon],
        html: `<button class="popup-hawk-close-btn" onclick="window.popupHawk.closePopup()"></button>
<h3>Don't Miss Out...</h3>
<h2>Save $20 off your next order!</h2>
<p>Use the coupon code below:</p>
<span class="coupon">SAVE20</span>`,
        css: `.popup-hawk-close-btn {
  padding: 0;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: 0 0;
  border: none;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}
.popup-hawk-close-btn::before {
  content: "✕";
  color: red;
}
.popup-hawk-container-inner {
    text-align: center;
    padding: 2em;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgb(251, 251, 245);
}
h3 {
    font-size: 14px;
    margin-top: 0;
    color: inherit;
    display: block;
    text-align: inherit;
}
h2 {
    font-size: 20px;
    margin: 0 auto;
    color: inherit;
    display: block;
    text-align: inherit;
    margin: .5em;
}
p {
    font-size: 12px;
    color: inherit;
}
.popup-submit-btn {
  text-transform: none;
  font-weight: 700;
}
.coupon {
    border: 3px dashed rgb(94, 166, 31);
    background-color: rgb(213, 246, 213);
    padding: .75em;
    font-size: 18px;
    margin-top: 1em;
    display: block;
    text-align: inherit;
    font-weight: 700;
}`,
    },

    {
        name: 'Black Friday',
        types: [PopupType.Coupon],
        html: `<button class="popup-hawk-close-btn" onclick="window.popupHawk.closePopup()"></button>
<h3>SPECIAL OFFER</h3>
<h2>BLACK<span class="friday">FRIDAY</span></h2>
<p>UP TO <u>50%</u> OFF</p>
<a class='button' href='#'>SHOP NOW</a>`,
        css: `.popup-hawk-close-btn {
  padding: 0;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: 0 0;
  border: none;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}
.popup-hawk-close-btn::before {
  content: "✕";
  color: white;
}
.popup-hawk-container-inner {
    text-align: center;
    padding: 2em;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgb(44, 44, 44);
    color: white; 
    border-radius: 15px;
}
h3 {
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    color: inherit;
    display: block;
    text-align: inherit;
}
h2 {
    font-size: 50px;
    margin: 10px auto;
    line-height: 1;
    color: inherit;
    display: block;
    text-align: inherit;
    letter-spacing: 1.5px;
}
.friday {
    color: #f0f;
    letter-spacing: -1px;
    display: block;
    text-align: center;
    font-size: inherit;
    line-height: .8
}
p {
    font-size: 20px;
    color: inherit;
    display: block;
    text-align: inherit;
    margin-bottom: 10px;
}
p u {
    color: #f0f;
    font-size: inherit;
}
.popup-submit-btn {
  text-transform: none;
  font-weight: 700;
}
.button {
    color: rgb(44, 44, 44);
    background-color: white;
    text-decoration: none;
    padding: 1em 1.5em;
    display: inline-block;
    border-radius: 50px;
    font-weight: bold;
    font-size: 16px;
    transition: 0.4s ease all;
    cursor: pointer;
}
.button:hover {
    background-color: #f0f;
    color: white;
    transition: 0.4s ease all;
}`,
    },
];
