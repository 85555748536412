import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { InputField, SelectFieldWithOptions } from '../../../default/Form/Form';
import { Loading, Error } from '../../../default/States/States';
import styles from '../../../../css/default.module.scss';
import { FormInputs } from './types';
import { createAvatarGoalSchema } from '../../../../schemas/AccountSchemas';
import useGetAvailableAvatarGoals from '../../../../hooks/user/useGetAvailableAvatarGoals/useGetAvailableAvatarGoals';
import { SelectOption } from '../../../default/Form/types';
import customStyles from './styles.module.scss';
import { AVATAR_VALUES } from '../../../../utils/global-variables';
import { whereSelectOptions, SITE_TYPE } from './variables';
import {
    UseCase,
    useAvatarGoalQuery,
    useCreateAvatarGoalMutation,
    useUpdateAvatarGoalMutation,
} from '../../../../graphql/generated/schema';
import { useNavigate } from 'react-router-dom';
import { Forward } from '../../../default/Navigation/Navigation';

const EditGoalsForm = () => {
    const [{ data: createGoalData, fetching: loadingCreation, error: creationError }, createGoal] =
        useCreateAvatarGoalMutation();
    const [
        { data: updateGoalData, fetching: loadingUpdateGoal, error: updateGoalError },
        updateGoal,
    ] = useUpdateAvatarGoalMutation();
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
    const { availableGoals, loadingAvailableGoals, availableGoalsError } =
        useGetAvailableAvatarGoals();
    const navigate = useNavigate();

    const handleSubmitFn: SubmitHandler<FormInputs> = async ({
        category,
        comment,
        improvements,
        categoryOther,
        problems,
        where,
    }) => {
        const refinedInputs = {
            input: {
                goalCategoryId: category,
                goalComment: comment,
                improvements: improvements,
                otherGoalCategory: categoryOther,
                siteConcerns: problems,
                useCase: where as UseCase,
            },
        };

        if (shouldUpdate) {
            await updateGoal(refinedInputs);
        } else {
            await createGoal(refinedInputs);
        }
    };

    const [{ data: currentGoals, fetching: loadCurrentGoals }] = useAvatarGoalQuery();

    const { goalCategoryId, goalComment, improvements, otherGoalCategory, siteConcerns, useCase } =
        currentGoals?.avatarGoal || {};

    const methods = useForm<FormInputs>({
        resolver: yupResolver(createAvatarGoalSchema),
        mode: 'onTouched',
        defaultValues: {
            category: goalCategoryId,
            categoryOther: otherGoalCategory || '',
            comment: goalComment,
            where: useCase,
            problems: siteConcerns,
            improvements,
        },
    });

    const selectedOtherCategory = methods.watch('category') === AVATAR_VALUES.OTHER_GOAL;

    useEffect(() => {
        if (!loadCurrentGoals && currentGoals) {
            const {
                goalCategoryId: updatedCategoryId,
                goalComment: updatedComment,
                improvements: updatedImprovements,
                otherGoalCategory: updatedOtherCategory,
                siteConcerns: updatedSiteConcerns,
                useCase: updatedUseCase,
            } = currentGoals.avatarGoal || {};

            methods.reset({
                category: updatedCategoryId,
                categoryOther: updatedOtherCategory || '',
                comment: updatedComment,
                where: SITE_TYPE[updatedUseCase as keyof typeof SITE_TYPE],
                problems: updatedSiteConcerns,
                improvements: updatedImprovements,
            });

            setShouldUpdate(true);
        }
    }, [loadCurrentGoals, currentGoals, methods]);

    useEffect(() => {
        if (
            createGoalData?.createAvatarGoal?.success ||
            updateGoalData?.updateAvatarGoal?.success
        ) {
            navigate('/user/profile/edit/2');
        }
    }, [createGoalData, updateGoalData, navigate]);

    if (loadingAvailableGoals || loadCurrentGoals) return <Loading centralised={true} />;

    if (availableGoalsError) return <Error msg={availableGoalsError?.message} />;

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmitFn)}
                    className={customStyles.parent}
                >
                    <div
                        className={`${styles.formInner} ${customStyles.editGoalsForm} ${customStyles.specificity}`}
                    >
                        <SelectFieldWithOptions
                            title='category'
                            alias='What is your primary goal?'
                            options={availableGoals as SelectOption[]}
                            hasColon={false}
                        />
                        <InputField
                            type='text'
                            title='categoryOther'
                            alias='Other Goal'
                            placeholder='Enter your goal'
                            cls={`${customStyles.otherField} ${
                                selectedOtherCategory ? customStyles.show : ''
                            }`}
                        />
                        <InputField
                            type='text'
                            title='comment'
                            alias='In what way do you want to achieve your goal?'
                            placeholder='Enter a goal reason'
                            hasColon={false}
                        />
                        <SelectFieldWithOptions
                            title='where'
                            alias="Will you primarily test on your own website or clients' websites?"
                            options={whereSelectOptions}
                            hasColon={false}
                        />
                        <InputField
                            type='text'
                            title='problems'
                            alias='What problems or concerns do you have with the current website?'
                            placeholder='Enter problems or concerns with the website'
                            hasColon={false}
                        />
                        <InputField
                            type='text'
                            title='improvements'
                            alias='What would you like to improve?'
                            placeholder='Enter what you want to improve'
                            hasColon={false}
                        />
                    </div>
                    {loadingCreation || loadingUpdateGoal ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {(creationError || updateGoalError) && (
                <Error
                    msg={(creationError?.message || updateGoalError?.message) as string}
                    marginTop={true}
                />
            )}
            {shouldUpdate && <Forward to='/user/profile/edit/2' block={true} marginless={true} />}
        </>
    );
};

export default EditGoalsForm;
