import React from 'react';
import { ListWithArrowsProps } from './types';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const ListWithArrows = ({ items }: ListWithArrowsProps): JSX.Element => (
    <ul className={styles.outer}>
        {items.map(({ url, label }) => (
            <li key={label}>
                <Link to={url}>
                    <FontAwesomeIcon
                        icon={faAngleRight}
                        aria-label='right arrow'
                        className={styles.icon}
                    />
                    <span className={styles.label}>{label}</span>
                </Link>
            </li>
        ))}
    </ul>
);

export default ListWithArrows;
