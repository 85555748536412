import React, { useCallback } from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { To, useNavigate } from 'react-router-dom';
import { BackProps } from './types';
import styles from './styles.module.scss';

export const Back = ({
    to,
    text = 'Back',
    onClick,
    cls,
    block,
    marginless,
    paddingless,
    fullPageWidth = true,
}: BackProps): JSX.Element => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        onClick ? onClick() : navigate((to as To) ?? -1);
    }, [navigate, to, onClick]);

    const containerClasses = `${styles.outerWrap} ${paddingless ? styles.paddingless : ''}`;

    const buttonClasses = `${styles.navBtn} ${cls || ''} ${block ? styles.block : ''} ${
        marginless ? styles.marginless : ''
    }`;

    if (fullPageWidth) {
        return (
            <div className={containerClasses}>
                <button
                    type='button'
                    onClick={handleClick}
                    aria-label='Go back'
                    className={buttonClasses}
                >
                    <FontAwesomeIcon icon={faAngleLeft} aria-hidden='true' /> {text}
                </button>
            </div>
        );
    }

    return (
        <button type='button' onClick={handleClick} aria-label='Go back' className={buttonClasses}>
            <FontAwesomeIcon icon={faAngleLeft} aria-hidden='true' /> {text}
        </button>
    );
};

export const Forward = ({
    to,
    text = 'Next',
    onClick,
    cls,
    block,
    marginless,
}: BackProps): JSX.Element => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        onClick ? onClick() : navigate((to as To) ?? -1);
    }, [navigate, to, onClick]);

    const classes = `${styles.navBtn} ${cls || ''} ${block ? styles.block : ''} ${
        marginless ? styles.marginless : ''
    }`;

    return (
        <button type='button' onClick={handleClick} aria-label='Next' className={classes}>
            <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' /> {text}
        </button>
    );
};
