import React, { useContext, useEffect } from 'react';
import { paymentSuccessMeta } from '../../../../misc/MetaTags';
import { withPage } from '../../../../../components/layout/Page/Page';
import { HeroArea } from '../../../../../components/default/Areas/Areas';
import { useLocation } from 'react-router-dom';
// import { useProcessCheckoutCompletedMutation } from '../../../../../graphql/generated/schema';
import { UserContextType } from '../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';
import useQuoraCustomTrackingEvent from '../../../../../hooks/tracking/useQuoraCustomTrackingEvent/useQuoraCustomTrackingEvent';
// import { Loading } from '../../../../../components/default/States/States';

const PaymentSuccessful = () => {
    // e.g. url: /payment-success?plan_id=price_1NUVoPFVkQPWJoWkyiPcZlS9&userId=95212f19-fe98-43f4-9063-f8fb5837f04d
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
    const planId = searchParams.get('planId');
    // const [{ data, fetching }, processCheckoutCompleted] = useProcessCheckoutCompletedMutation();
    const { setAccount } = useContext<UserContextType>(UserContext);

    useQuoraCustomTrackingEvent('Purchase');

    useEffect(() => {
        (async () => {
            // await processCheckoutCompleted({
            //     userId: userId as string,
            // });
            setAccount(null);
        })();
        // }, [processCheckoutCompleted, userId, setAccount, planId]);
    }, [userId, setAccount, planId]);

    // if (fetching || data === undefined) return <Loading entirePage={true} centralised={true} />;

    return (
        <HeroArea
            title='Payment Successful'
            description='You successfully completed the payment and have changed your account plan. You have been logged out and can log back in again below:'
            buttonText='Log In'
            buttonLink='/login'
        />
    );
};

export default withPage(PaymentSuccessful)({ meta: paymentSuccessMeta });
