import React from 'react';
import styles from './styles.module.scss';
import { PlanKey } from '../../../../../../utils/global-variables';

const StandardChangeMessage = ({ plan }: { plan: PlanKey }): JSX.Element => (
    <div className={styles.container}>
        <p>You are about to change your plan to the {plan} plan.</p>
    </div>
);

export default StandardChangeMessage;
