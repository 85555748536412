import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import defaultStyles from '../../../../../../../css/default.module.scss';
import { SelectField, InputField } from '../../../../../../default/Form/Form';
import pageStyles from '../styles.module.scss';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RULE_TYPES = ['contains', 'equals'];

const PageInclusionFields = () => {
    const { control } = useFormContext();
    const {
        fields: campaignPageInclusionFields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'campaignPages.include',
    });

    return (
        <>
            <h3>Inclusions</h3>
            {campaignPageInclusionFields.length > 0 &&
                campaignPageInclusionFields.map((field, index) => (
                    <div key={field.id} className={pageStyles.ruleBlock}>
                        <SelectField
                            title={`campaignPages.include[${index}].type`}
                            type='select'
                            alias='Show on pages where the URL'
                        >
                            <option value=''>-- Select --</option>
                            {RULE_TYPES.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </SelectField>

                        <InputField
                            title={`campaignPages.include[${index}].value`}
                            type='text'
                            alias='The value'
                        />

                        <button
                            type='button'
                            onClick={() => remove(index)}
                            className={defaultStyles.btnRed}
                        >
                            <FontAwesomeIcon icon={faMinus} /> Remove Rule
                        </button>
                    </div>
                ))}

            <button
                className={defaultStyles.btnPrimary}
                type='button'
                onClick={() =>
                    append({
                        type: '',
                        value: '',
                    })
                }
            >
                <FontAwesomeIcon icon={faPlus} /> New Inclusion Rule
            </button>
        </>
    );
};

export default PageInclusionFields;
