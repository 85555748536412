/**
 * Formats a timestamp to DD/MM/YYYY.
 * @param timestamp - The timestamp as a string or number.
 * @returns A formatted date string in DD/MM/YYYY format.
 */
const formatToDDMMYYYY = (timestamp: string | number): string => {
    const date = new Date(parseInt(timestamp as string));
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

export default formatToDDMMYYYY;
