import React, { useContext } from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { homepageMeta } from '../../misc/MetaTags';
import styles from './styles.module.scss';
import { HeroArea, Section } from '../../../components/default/Areas/Areas';
import defaultStyles from '../../../css/default.module.scss';
import { Link } from 'react-router-dom';
import { UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import GetStarted from '../../../components/default/GetStarted/GetStarted';
import ProcessSteps from '../../../components/default/ProcessSteps/ProcessSteps';
import { POPUP_BENEFITS, PROCESS_STEPS, POPUP_CREATION_STEPS } from './variables';
import GreenTicksChecklist from '../../../components/default/GreenTicksChecklist/GreenTicksChecklist';
import HawkImage from './metallic-predatory-hawk-gliding.webp';

const Homepage = () => {
    return (
        <>
            <HeroArea
                title='Popups that Convert'
                description='Capture leads and convert leaving visitors into paying customers with simple and effective popups.'
                buttonText='Try Free Version'
                buttonLink='/pricing'
                leftText={true}
                image={HawkImage}
                imageAlt='Metallic predatory hawk gliding'
                extraImagePadding={true}
                clsOuter={styles.heroOuter}
                clsInner={styles.heroInner}
            />

            <Section inverse={true} patterned={true}>
                <h2>How it Works</h2>
                <p>
                    Popups yield big benefits in recovering lost sales. Making the most of them is
                    straightforward and effortless:
                </p>
                <ProcessSteps steps={PROCESS_STEPS} cls={styles.processSteps} />
                <p>
                    No complex setup, no agencies and no convolutions. Take full control by yourself
                    - do whatever you want.
                </p>
            </Section>

            <Section patterned={true}>
                <h2>Why use Popups?</h2>
                <p>
                    Popups are a proven, powerful and reliable tool for converting exiting traffic
                    into sales. By using popups, you can:
                </p>
                <GreenTicksChecklist items={POPUP_BENEFITS} primaryColour={true} />
                <p>
                    How will you prevent users from leaving if you don't incentivise them with
                    popups?
                </p>
            </Section>

            <Section patterned={true}>
                <h2>Creating a Popup</h2>
                <p>Creating a popup on our platform is very straightforward:</p>
                <ProcessSteps steps={POPUP_CREATION_STEPS} />
                <p>
                    When creating popups, you can just select a pre-made template and adjust that,
                    otherwise if you're more technical, you can use code (HTML, CSS &amp;/or
                    JavaScript).
                </p>
            </Section>

            {/* <FreeGuide /> */}

            <BottomArea />
        </>
    );
};

const BottomArea = () => {
    const { user } = useContext<UserContextType>(UserContext);

    if (user)
        return (
            <Section patterned={true} clsOuter={styles.ctaBottomSection}>
                <h2>Start Now</h2>
                <p>
                    Make the most of your plan by continuing to improve your website with more
                    popups.
                </p>
                <Link to='/user/campaigns' className={defaultStyles.btnPrimary}>
                    View Popups
                </Link>
            </Section>
        );

    return <GetStarted />;
};

export default withPage(Homepage)({
    meta: homepageMeta,
    clsPage: styles.homepage,
    fullWidth: true,
    widerInnerWidth: false,
    lightBackground: true,
});
