import React from 'react';
import Editor from '@monaco-editor/react';
import styles from './styles.module.scss';
import { CodeEditorProps } from './types';

// options: https://microsoft.github.io/monaco-editor/typedoc/modules/editor.html
const CodeEditor = ({
    language,
    onChange,
    cls = '',
    width = '100%',
    height = '200px',
    defaultValue,
}: CodeEditorProps): JSX.Element => (
    <Editor
        className={`${styles.input} ${cls}`}
        width={width}
        height={height}
        language={language}
        onChange={onChange}
        defaultValue={defaultValue}
        options={{
            fontSize: 14,
            fontFamily: 'monospace',
            minimap: { enabled: false },
            unusualLineTerminators: 'auto',
            scrollbar: {
                alwaysConsumeMouseWheel: false,
                useShadows: false,
            },
            padding: {
                top: 10,
                bottom: 10,
            },
            renderLineHighlight: 'none',
        }}
    />
);

export default CodeEditor;
