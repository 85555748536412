import React, { useContext, useEffect, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import styles from './styles.module.scss';
import { UserContext } from '../../../../../../contexts/UserContext/UserContext';

/* 
  FROM CHAT GPT:
  
  Popup Preview Styles with Shadow DOM and Style Isolation
  ========================================================

  Overview:
  ---------
  This CSS file contains styles for the popup preview component in a React application.
  The component uses a Shadow DOM to isolate the popup's styles, ensuring that they 
  do not affect the rest of the website, nor inherit any global styles that may cause 
  unintended styling issues.

  Key Concepts:
  -------------
  1. Shadow DOM:
     - The component leverages the Shadow DOM, which encapsulates the popup's content 
       and styles. This means that styles defined inside the Shadow DOM will only 
       apply to elements within it, and will not affect elements on the main page.
     - Additionally, global styles on the main page will not affect the popup unless 
       explicitly applied, ensuring complete isolation.

  2. `all: initial`:
     - The CSS property `all: initial` is applied to the outer container 
       (`.popup-hawk-container-outer`) to reset all CSS properties for that container 
       and its children. This prevents any inherited styles from the main document from 
       applying within the popup, ensuring a clean starting point for the popup's 
       styles.
     - After resetting all styles, specific popup styles can be applied to control 
       the appearance of the popup without interference from the parent page.

  3. Injecting Custom Styles:
     - The user-defined CSS (from `popupCss`) is dynamically injected into the 
       Shadow DOM to allow real-time style updates for the popup content. 
       This allows the preview to reflect custom CSS styling without affecting 
       other parts of the site.

  4. Scoped Class Names:
     - Class names like `.popup-hawk-container-outer` and `.popup-hawk-container-inner` 
       are used inside the Shadow DOM. Even though these class names are common, 
       they are completely scoped within the Shadow DOM and will not collide with 
       any other elements on the main page.

  Code Explanation:
  -----------------
  - The outer container for the popup preview is assigned the class `.popup-hawk-container-outer`. 
    This container has the `all: initial` property to reset any inherited styles, 
    providing a neutral styling environment.
  
  - A `<style>` tag is dynamically injected into the Shadow DOM to apply both 
    user-defined styles (popupCss) and any necessary default styles for the popup.
  
  - By using the Shadow DOM, we ensure that the popup's styles are entirely 
    self-contained, making it easier to avoid style conflicts with other parts of the website.
  
  Why These Changes Were Made:
  ----------------------------
  - When using a popup within a React application, applying CSS styles directly to the 
    DOM can lead to unintended side effects, such as styles leaking outside the popup 
    or inherited styles from the website causing unexpected results.
  
  - To fix these issues, we encapsulated the popup inside a Shadow DOM, and used 
    `all: initial` to reset inherited styles. This ensures that only the styles we 
    specifically define are applied, and the popup behaves predictably within its 
    own styling scope.
*/

const sharedStyles = `margin:0!important;z-index:1!important;background:unset!important;position:relative!important;top:unset!important;bottom:unset!important;left:unset!important;right:unset!important;visibility:visible!important;transform:unset!important;clip-path:unset!important;opacity:1!important;text-indent:unset!important;clip:unset!important;`;
const linkStyles = `${sharedStyles}display:inline!important;font-size:unset!important;padding:0!important;text-decoration:underline;cursor:pointer`;
const divStyles = `${sharedStyles}width:100%!important;text-align:center!important;font-size:12px!important;flex:0 0 100%!important;padding:0.25em!important;box-sizing:border-box!important;border-radius:0!important;display:block!important;`;
const PREVIEW_OUTER_STYLES = `
  .popup-hawk-container-outer { 
      position: relative; /* different */
      /*
        below is used in script but excluded here:
        position: fixed;
        top: 50%;
        left: 50%;
      */
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      flex-direction: column;
      margin: 0;
      padding: 0;
      overflow: hidden;
      width: auto;
      /* transform: translate(-50%, -50%); used in main script only */
      /* z-index: 999999999999999; */
      z-index: 999; /* just for the previewer */
    }

    /*
    .popup-hawk-free-popup {
      ${sharedStyles}
      ${divStyles}
    }

    .popup-hawk-free-popup a {
      ${sharedStyles}
      ${linkStyles}
    }
    */

    /* below is excluded in script */
    .popup-hawk-container-inner {
      box-sizing: border-box;
      @media (min-width: 768px) {
        width: 90vw;
        max-width: 400px
      }
    }
`;

const PopupPreview = () => {
    const popupHtml = useWatch({
        name: 'popupHtml',
    });
    const popupCss = useWatch({
        name: 'popupCss',
    });
    const { user } = useContext(UserContext);
    const userPlan = user?.userPlan;
    const isFreePlan = userPlan === 'FREE';

    const containerInnerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerInnerRef.current) {
            // Clear existing content
            containerInnerRef.current.innerHTML = '';

            // Check if shadow root already exists
            let shadowRoot = containerInnerRef.current.shadowRoot;
            if (!shadowRoot) {
                // Attach shadow root if it doesn't exist yet
                shadowRoot = containerInnerRef.current.attachShadow({ mode: 'open' });
            }

            // Clear shadow root content
            shadowRoot.innerHTML = '';

            // Create the outer container div
            const outerDiv = document.createElement('div');
            outerDiv.className = 'popup-hawk-container-outer'; // Keep the class name

            // Extract all @import statements using regex
            const fontImports = popupCss?.match(/@import\s+url\(['"].*?['"]\);/g) || [];

            // Inject each @import into the document head if not already added
            fontImports.forEach((fontImport: string | null) => {
                const globalStyle = document.createElement('style');
                globalStyle.textContent = fontImport;
                document.head.appendChild(globalStyle);
            });

            // Remove all @import statements from the popupCss for shadow DOM
            const sanitizedCss = popupCss?.replace(/@import\s+url\(['"].*?['"]\);/g, '') || '';

            // Inject both the popup CSS and reset styles with `all: initial`
            const styleTag = document.createElement('style');
            styleTag.textContent = `
              *:not(style){all:initial}
              ${sanitizedCss}  /* Inject any user-defined CSS */
              ${PREVIEW_OUTER_STYLES}
          `;
            shadowRoot.appendChild(styleTag); // Append the style to shadow DOM

            // Create the inner content div
            const innerDiv = document.createElement('div');
            innerDiv.className = 'popup-hawk-container-inner'; // Use plain class name
            innerDiv.innerHTML = `${popupHtml}`;

            // Append the inner div to the outer div
            outerDiv.appendChild(innerDiv);

            // // Create the free style div
            // const brandDiv = document.createElement('div');
            // brandDiv.className = 'popup-hawk-free-popup';
            // brandDiv.innerHTML = isFreePlan
            //     ? 'Free popups by <a href="https://www.popuphawk.com">Popup Hawk</a>.'
            //     : '';

            // // Append the brand div to the outer div
            // outerDiv.appendChild(brandDiv);

            // Append the outer div to the shadow root
            shadowRoot.appendChild(outerDiv);
        }
    }, [popupHtml, popupCss, isFreePlan]);

    return (
        <div className={styles.containerOuter}>
            <div ref={containerInnerRef} className={styles.containerInner}>
                {/* The content will be dynamically inserted into the shadow DOM here */}
            </div>
        </div>
    );
};

export default PopupPreview;
