import React, { useContext } from 'react';
import { useUpdatePaymentDetails } from '../../../../../hooks/payments/useUpdatePaymentDetails/useUpdatePaymentDetails';
import { Loading, Error } from '../../../../../components/default/States/States';
import styles from '../../../../../css/default.module.scss';
import { UserContextType } from '../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';
import componentStyles from './styles.module.scss';
import { useHasEnteredPaymentDetailsQuery } from '../../../../../graphql/generated/schema';

const ChangePaymentInfoBtn = () => {
    const { loading, redirecting, error, redirectUser } = useUpdatePaymentDetails();
    const [{ data }] = useHasEnteredPaymentDetailsQuery();
    const { user } = useContext<UserContextType>(UserContext);
    const hasEnteredPaymentDetails = data?.hasEnteredPaymentDetails === true;
    const { subscriptionStatus } = user || {};
    const isRestricted = subscriptionStatus === 'RESTRICTED';

    if (!hasEnteredPaymentDetails || isRestricted) return null;
    if (loading || redirecting) return <Loading centralised={true} marginTop={true} />;
    if (error) return <Error msg={error} marginTop={true} />;

    return (
        <p className={componentStyles.container}>
            Change your{' '}
            <button onClick={redirectUser} className={styles.btnAsText}>
                payment information
            </button>
            .
        </p>
    );
};

export default ChangePaymentInfoBtn;
