export const trueOrFalseStringToBoolean = (value: string): boolean => {
    const lowerValue = value.toLowerCase();
    if (lowerValue === 'true') {
        return true;
    } else if (lowerValue === 'false') {
        return false;
    } else {
        throw new Error('Invalid boolean string');
    }
};
