import React, { useContext } from 'react';
import { CodeBlock } from '../../../default/Cards/Cards';
import { TRACKING_SCRIPT_PATH } from '../../../../utils/global-variables';
import { SCRIPT_INSTRUCTIONS_LOGGED_IN, SCRIPT_INSTRUCTIONS_LOGGED_OUT } from './variables';
import { Back } from '../../../default/Navigation/Navigation';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { Info } from '../../../default/States/States';

const ScriptInstallation = () => {
    const { user } = useContext(UserContext);
    const sid = user?.id || 'XXXXXX';
    const code = `<script src="${TRACKING_SCRIPT_PATH}?sid=${sid}" defer></script>`;

    return (
        <div>
            <h2>Installing the Tracking Script:</h2>
            <>
                <p>{user ? SCRIPT_INSTRUCTIONS_LOGGED_IN : SCRIPT_INSTRUCTIONS_LOGGED_OUT}</p>
                <CodeBlock
                    title='Installation Script'
                    code={code}
                    marginBottom={true}
                    marginTop={true}
                />
                {!user?.id && (
                    <Info
                        marginBottom={true}
                        msg="The 'XXXXXX' above would be replaced with your id which would be shown automatically when logged in to your account."
                    />
                )}
                <p>
                    It doesn't necessarily need adding to the closing body tag - it could also go in
                    the head, but that's what we just recommend.
                </p>
                {/* @todo non mvp - add preview URL IF LOGGED IN ONLY? OR NOT? */}
                {/* ^ i.e. it doesn’t need to have a ‘click to verify the script has been installed successfully’ step when setting it up - Perhaps make them visit mysite.com/?popup-verified=1 where that would show a ‘verified’ pop up */}
                {/* <p>
                    After you have done this, you may want to verify that it has installed
                    correctly.
                </p> */}
                <Back to='/support' text='Support Hub' block={true} />
            </>
        </div>
    );
};

export default ScriptInstallation;
