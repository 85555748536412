import React, { useContext } from 'react';
import defaultStyles from '../../../../../css/default.module.scss';
import { InfoAreaWrap } from '../../../../../components/default/Cards/Cards';
import { FormProvider, useForm } from 'react-hook-form';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCampaignSchema } from '../../../../../schemas/CreateCampaignSchema';
import { withPage } from '../../../../../components/layout/Page/Page';
import { createCampaignMeta } from '../../../../misc/MetaTags';
import { useCreateCampaignStage } from '../../../../../hooks/campaigns/useCreateCampaignStage/useCreateCampaignStage';
import CreateCampaignStageOne from '../../../../../components/account/campaigns/create/CreateCampaignStageOne/CreateCampaignStageOne';
import CreateCampaignStageTwo from '../../../../../components/account/campaigns/create/CreateCampaignStageTwo/CreateCampaignStageTwo';
import CreateCampaignStageThree from '../../../../../components/account/campaigns/create/CreateCampaignStageThree/CreateCampaignStageThree';
import CreateCampaignStageFour from '../../../../../components/account/campaigns/create/CreateCampaignStageFour/CreateCampaignStageFour';
import { FINAL_STAGE } from '../../../../../hooks/campaigns/useCreateCampaignStage/variables';
import { NextStage } from '../../../../../components/account/campaigns/create/Shared/Shared';
import { ErrorText, Info, Warning } from '../../../../../components/default/States/States';
import usePreventPageRefresh from '../../../../../hooks/campaigns/usePreventPageRefresh/usePreventPageRefresh';
import PopupPreview from '../../../../../components/account/campaigns/create/CreateCampaignStageTwo/PopupPreview/PopupPreview';
import styles from './styles.module.scss';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';
import PopupTemplatePicker from '../../../../../components/account/campaigns/create/CreateCampaignStageTwo/PopupTemplatePicker/PopupTemplatePicker';

type CreateCampaignFormInputs = any;

const NewCampaign = () => {
    const { user } = useContext(UserContext);
    const userPlan = user?.userPlan;
    const isFreePlan = userPlan === 'FREE';
    /*
        mutations:
        - createCampaign
        - createPopup
        - users must have to option of us storing emails, which would require mutation createSignUpData in the HTML

        stages:
        1. initial info:
        - campaign: name, type, storeInOurDatabase
        - popup: name
        2. appearance:
        - popup: htmlContent
        - add CSS field?
        - add JS field?
        - display preview button
        - display html content preview
        3. display:
        - popup: exitIntent, showAfterScrollPercentage, triggeredByClickingElements
        4. targeting:
        - campaign: pages, cookies, url params, devices
    */
    const methods = useForm<CreateCampaignFormInputs>({
        resolver: yupResolver(createCampaignSchema),
        mode: 'onTouched',
        defaultValues: {
            campaignName: '',
            campaignType: '',
            campaignStoreInOurDatabase: null,
            popupName: '',
            popupHtml: '',
            popupCss: '',
            popupJs: null,
            popupHowToShow: '',
            popupShowAfterScrollPercentage: null,
            popupTriggeredByClickingElements: null,
            campaignPages: {
                exclude: null,
                include: null,
                // exclude: [
                //     {
                //         type: 'contains',
                //         value: 'some_part_of_url',
                //     },
                //     {
                //         type: 'contains',
                //         value: 'another_url_part',
                //     },
                // ],
                // include: [
                // {
                //     type: 'equals',
                //     value: 'https://example.com/home',
                // },
                //     {
                //         type: 'includes',
                //         value: 'some_part_of_das_url',
                //     },
                // ],
            },
            campaignCookies: {
                exclude: [], // or null?
                include: [], // or null?
                // exclude: ['excluded_cookie_1', 'excluded_cookie_2'],
                // include: ['included_cookie_1', 'included_cookie_2'],
            },
            campaignUrlParams: {
                exclude: [], // or null?
                include: [], // or null?
                // exclude: ['excluded_param_1', 'excluded_param_2'],
                // include: ['included_param_1', 'included_param_2'],
            },
            campaignDevices: ['mobiles', 'desktops'],
        },
    });

    const {
        currentStage,
        currentStageComplete,
        proceed,
        showPrevStep,
        title,
        loadingSubmission,
        submissionError,
    } = useCreateCampaignStage(methods);

    usePreventPageRefresh();

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Create Campaign</h1>
            {currentStage === 3 && (
                <Warning
                    msg='We recommend using a computer or similar when creating popups, otherwise you may encounter display issues.'
                    marginBottom={true}
                    cls={styles.mobileOnly}
                />
            )}
            <FormProvider {...methods}>
                <div className={currentStage === 3 ? styles.whenStageTwo : ''}>
                    <InfoAreaWrap cls={currentStage === 3 ? styles.infoArea : ''}>
                        <form>
                            <div className={defaultStyles.formInner}>
                                <h2>{title}</h2>
                                {currentStage === 1 && <CreateCampaignStageOne />}
                                {currentStage === 2 && <PopupTemplatePicker onSkip={proceed} />}
                                {currentStage === 3 && <CreateCampaignStageTwo />}
                                {currentStage === 4 && <CreateCampaignStageThree />}
                                {currentStage === FINAL_STAGE && <CreateCampaignStageFour />}
                                <NextStage
                                    disabled={!currentStageComplete}
                                    onClick={proceed}
                                    type='button'
                                    loading={loadingSubmission}
                                />
                                {currentStage > 1 && <Back onClick={showPrevStep} />}
                                {submissionError && (
                                    <ErrorText msg={submissionError} marginTop={true} />
                                )}
                            </div>
                        </form>
                    </InfoAreaWrap>
                    {currentStage === 3 && <PopupPreview />}
                </div>
            </FormProvider>
            {currentStage === 3 && isFreePlan && (
                <Info msg="The bottom of every page will display a subtle 'Free website popups by Popup Hawk' message because you are on the free plan. You can remove this by upgrading to a paid plan." />
            )}
            <Back to='/user/campaigns' text='Back to Campaigns' />
        </>
    );
};

export default withPage(NewCampaign)({ meta: createCampaignMeta, innerSpacing: true });
