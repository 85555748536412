import React, { Dispatch, useCallback, useEffect, useRef } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../../../css/default.module.scss';
import Modal from 'react-modal';
import { useDeleteSignUpDataByCampaignMutation } from '../../../../graphql/generated/schema';
import { Loading, Error } from '../../../default/States/States';

interface DeleteLeadsModalProps {
    campaignId: any;
    modalIsOpen: boolean;
    setModalIsOpen: Dispatch<boolean>;
    refetch: () => void;
}

const DeleteLeadsModal = ({
    campaignId,
    modalIsOpen,
    setModalIsOpen,
    refetch,
}: DeleteLeadsModalProps): JSX.Element | null => {
    const [{ data, fetching: loading, error }, deleteSignUpLeads] =
        useDeleteSignUpDataByCampaignMutation();
    const toggledRef = useRef(false); // Use useRef to avoid rerenders

    const closeModal = useCallback(() => {
        setModalIsOpen(false);
    }, [setModalIsOpen]);

    useEffect(() => {
        if (data?.deleteSignUpDataByCampaign?.acknowledged && !toggledRef.current) {
            toggledRef.current = true; // Set the ref instead of state
            refetch();
            closeModal();
        }
    }, [data?.deleteSignUpDataByCampaign?.acknowledged, closeModal, refetch]);

    useEffect(() => {
        toggledRef.current = false; // Reset the ref when campaignId changes
    }, [campaignId]);

    if (!campaignId) return null;

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Delete Leads'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div>
                    <h2>Delete All Leads</h2>
                    <p>Are you sure you want to delete all leads for this campaign?</p>
                    {loading ? (
                        <Loading centralised={true} />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button
                                className={styles.btnRed}
                                onClick={() =>
                                    deleteSignUpLeads({
                                        id: campaignId,
                                    })
                                }
                            >
                                Delete
                            </button>
                            <button onClick={() => closeModal()} className={styles.btnGreen}>
                                Cancel
                            </button>
                        </div>
                    )}
                    {error && <Error msg={error.message} marginTop={true} />}
                </div>
            </Modal>
        </>
    );
};

export default DeleteLeadsModal;
