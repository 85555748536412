import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import {
    DateField,
    InputField,
    RadioOptions,
    SelectFieldWithOptions,
} from '../../../default/Form/Form';
import { Loading, Error } from '../../../default/States/States';
import styles from '../../../../css/default.module.scss';
import { FormInputs } from './types';
import { createAvatarDemographicsSchema } from '../../../../schemas/AccountSchemas';
import { SelectOption } from '../../../default/Form/types';
import customStyles from '../EditGoalsForm/styles.module.scss';
import { AVATAR_VALUES } from '../../../../utils/global-variables';
import {
    useAvatarDemographicsQuery,
    useCheckUserPreviousExperienceExistsQuery,
    useCountriesQuery,
    useCreateAvatarDemographicsMutation,
    useEducationLevelsQuery,
    useJobCategoriesQuery,
    useUpdateAvatarDemographicsMutation,
} from '../../../../graphql/generated/schema';
import { useNavigate } from 'react-router-dom';
import useGetSelectOptions from '../../../../hooks/user/useGetSelectOptions/useGetSelectOptions';
import {
    BUSINESS_POSITION_RADIO_PROPS,
    GENDER_RADIO_PROPS,
    TECH_SAVVY_RADIO_PROPS,
} from './variables';
import localStyles from './styles.module.scss';
import formatTimestampToYYYYMMDD from '../../../../utils/HelperFunctions/formatTimestampToYYYYMMDD';
import { Forward } from '../../../default/Navigation/Navigation';

const EditDemographicsForm = () => {
    const [
        { data: createDemographicsData, fetching: loadingCreation, error: creationError },
        createDemographics,
    ] = useCreateAvatarDemographicsMutation();
    const [
        {
            data: updateDemographicData,
            fetching: loadingUpdateDemographics,
            error: updateDemographicsError,
        },
        updateDemographics,
    ] = useUpdateAvatarDemographicsMutation();
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
    const {
        items: jobCategories,
        loading: loadingJobCategories,
        error: jobCategoriesError,
    } = useGetSelectOptions(useJobCategoriesQuery, 'jobCategories');
    const {
        items: countries,
        loading: loadingCountries,
        error: countriesError,
    } = useGetSelectOptions(useCountriesQuery, 'countries');
    const {
        items: educationLevels,
        loading: loadingEducationLevels,
        error: educationLevelsError,
    } = useGetSelectOptions(useEducationLevelsQuery, 'educationLevels');
    const [{ data }] = useCheckUserPreviousExperienceExistsQuery();
    const completedProfile = data?.checkUserPreviousExperienceExists;
    const navigate = useNavigate();

    const handleSubmitFn: SubmitHandler<FormInputs> = async ({
        businessPosition,
        countryId,
        dob,
        educationLevelId,
        gender,
        jobCategoryId,
        otherJobCategory,
        nickname,
        techSavvy,
    }) => {
        const refinedInputs = {
            input: {
                businessPosition,
                countryId,
                dob,
                educationLevelId,
                gender,
                jobCategoryId,
                otherJobCategory,
                nickname,
                techSavvy,
            },
        };

        if (shouldUpdate) {
            await updateDemographics(refinedInputs);
        } else {
            await createDemographics(refinedInputs);
        }
    };

    const [{ data: currentDemographics, fetching: loadCurrentDemographics }] =
        useAvatarDemographicsQuery();

    const {
        businessPosition,
        countryId,
        dob,
        educationLevelId,
        gender,
        jobCategoryId,
        otherJobCategory,
        nickname,
        techSavvy,
    } = currentDemographics?.avatarDemographics || {};

    const methods = useForm<FormInputs>({
        resolver: yupResolver(createAvatarDemographicsSchema),
        mode: 'onTouched',
        defaultValues: {
            businessPosition,
            countryId,
            dob,
            educationLevelId,
            gender,
            jobCategoryId,
            otherJobCategory: otherJobCategory || '',
            nickname,
            techSavvy,
        },
    });

    const selectedOtherJobCategory =
        methods.watch('jobCategoryId') === AVATAR_VALUES.OTHER_JOB_CATEGORY;

    useEffect(() => {
        if (!loadCurrentDemographics && currentDemographics) {
            const {
                businessPosition,
                countryId,
                dob,
                educationLevelId,
                gender,
                jobCategoryId,
                otherJobCategory,
                nickname,
                techSavvy,
            } = currentDemographics?.avatarDemographics || {};

            const formattedDob = formatTimestampToYYYYMMDD(dob);

            methods.reset({
                businessPosition,
                countryId,
                dob: formattedDob,
                educationLevelId,
                gender,
                jobCategoryId,
                otherJobCategory: otherJobCategory || '',
                nickname,
                techSavvy: techSavvy ? 'true' : 'false',
            });

            setShouldUpdate(true);
        }
    }, [loadCurrentDemographics, currentDemographics, methods]);

    useEffect(() => {
        if (
            createDemographicsData?.createAvatarDemographics?.success ||
            updateDemographicData?.updateAvatarDemographics?.success
        ) {
            navigate('/user/profile/edit/4');
        }
    }, [createDemographicsData, updateDemographicData, navigate]);

    if (
        loadingJobCategories ||
        loadCurrentDemographics ||
        loadingCountries ||
        loadingEducationLevels
    )
        return <Loading centralised={true} />;

    if (jobCategoriesError || countriesError || educationLevelsError)
        return (
            <Error
                msg={
                    jobCategoriesError?.message ||
                    countriesError?.message ||
                    educationLevelsError?.message
                }
            />
        );

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmitFn)}
                    className={customStyles.parent}
                >
                    <div
                        className={`${styles.formInner} ${customStyles.editGoalsForm} ${customStyles.specificity}`}
                    >
                        <InputField
                            type='text'
                            title='nickname'
                            alias='What would you like us to call you? Enter your preferred nickname'
                            hasColon={false}
                            cls={localStyles.noTextTransform}
                        />
                        <RadioOptions {...TECH_SAVVY_RADIO_PROPS} />
                        <RadioOptions {...BUSINESS_POSITION_RADIO_PROPS} />
                        <RadioOptions {...GENDER_RADIO_PROPS} />
                        <SelectFieldWithOptions
                            title='countryId'
                            alias='Select country'
                            options={countries as SelectOption[]}
                        />
                        <DateField title='dob' alias='Date of birth' />
                        <SelectFieldWithOptions
                            title='educationLevelId'
                            alias='Select education'
                            options={educationLevels as SelectOption[]}
                        />
                        <SelectFieldWithOptions
                            title='jobCategoryId'
                            alias='Select job category'
                            options={jobCategories as SelectOption[]}
                        />
                        <InputField
                            type='text'
                            title='otherJobCategory'
                            alias='Other Job Category'
                            placeholder='Enter your job category'
                            cls={`${customStyles.otherField} ${
                                selectedOtherJobCategory ? customStyles.show : ''
                            }`}
                        />
                    </div>
                    {loadingCreation || loadingUpdateDemographics ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {(creationError || updateDemographicsError) && (
                <Error
                    msg={(creationError?.message || updateDemographicsError?.message) as string}
                    marginTop={true}
                />
            )}
            {shouldUpdate && !completedProfile && (
                <Forward to='/user/profile/edit/4' block={true} marginless={true} />
            )}
        </>
    );
};

export default EditDemographicsForm;
