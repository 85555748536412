import React, { FC } from 'react';
import styles from './styles.module.scss';
import defaultStyles from '../../../css/default.module.scss';
import { Link } from 'react-router-dom';
import { HeroAreaProps, ButtonsProps, SectionProps } from './types';
import { Back } from '../Navigation/Navigation';

export const HeroArea = ({
    title,
    description,
    descriptionJsx,
    buttonText,
    buttonLink,
    buttonLinkExternal,
    buttonOnClick,
    clsOuter = '',
    clsInner = '',
    clsBtn = defaultStyles.btnPrimary,
    fullWidth,
    image,
    imageAlt,
    extraImagePadding = false,
    buttonTwoText,
    buttonTwoLink,
    buttonTwoLinkExternal,
    buttonTwoOnClick,
    clsBtnTwo = defaultStyles.btnDark,
    tall = true,
    withBackButton = false,
    leftText = false,
    additionalContent,
    extraBottomSpace = false,
    onImageClick,
}: HeroAreaProps): JSX.Element => {
    const outerClasses: string = `${styles.heroAreaOuter} ${
        tall ? styles.tall : styles.small
    } ${clsOuter} ${extraBottomSpace ? styles.extraBottomSpace : ''}`;
    const innerClasses: string = `${styles.heroAreaInner} ${styles.specificity} ${
        fullWidth ? styles.fullWidth : ''
    } ${clsInner}`;
    const btnProps: ButtonsProps = {
        buttonLink,
        buttonText,
        clsBtn,
        buttonOnClick,
        buttonTwoLink,
        buttonTwoText,
        clsBtnTwo,
        buttonLinkExternal,
        buttonTwoLinkExternal,
        buttonTwoOnClick,
        leftText,
    };
    const hasButtons = btnProps.buttonText || btnProps.buttonTwoText;
    const paragraphClasses = hasButtons ? '' : styles.noMargin;

    return (
        <Section clsOuter={outerClasses} clsInner={innerClasses} tag='section'>
            <div className={`${styles.textAndButtonContainer} ${leftText && styles.leftText}`}>
                {title && <h1>{title}</h1>}
                {description && <p className={paragraphClasses}>{description}</p>}
                {descriptionJsx && descriptionJsx}
                {hasButtons && <Buttons {...btnProps} />}
                {withBackButton && <Back />}
            </div>
            {image && (
                <div
                    className={`${styles.imgWrap} ${
                        extraImagePadding ? styles.extraImagePadding : ''
                    }`}
                >
                    <img src={image} alt={imageAlt} onClick={onImageClick} />
                </div>
            )}
            {!image && additionalContent && additionalContent}
        </Section>
    );
};

const Buttons: FC<ButtonsProps> = ({
    buttonLink,
    buttonText,
    clsBtn,
    buttonTwoLink,
    buttonTwoText,
    buttonOnClick,
    clsBtnTwo,
    buttonLinkExternal,
    buttonTwoLinkExternal,
    buttonTwoOnClick,
    leftText,
}) => {
    const btnOneAnchor = buttonLinkExternal || buttonLink?.[0] === '#';
    const btnTwoAnchor = buttonTwoLinkExternal || buttonTwoLink?.[0] === '#';
    const containerClasses = `${defaultStyles.buttonsContainer} ${defaultStyles.largeButtons} ${
        leftText ? '' : styles.centralButtons
    }`;

    return (
        <div className={containerClasses}>
            {(buttonLink || buttonOnClick) &&
                (btnOneAnchor ? (
                    <a href={buttonLink} className={clsBtn}>
                        {buttonText}
                    </a>
                ) : buttonOnClick ? (
                    <button onClick={buttonOnClick} className={clsBtn}>
                        {buttonText}
                    </button>
                ) : (
                    <Link to={buttonLink as string} className={clsBtn}>
                        {buttonText}
                    </Link>
                ))}
            {(buttonTwoLink || buttonTwoOnClick) &&
                (btnTwoAnchor ? (
                    <a href={buttonTwoLink} className={clsBtnTwo}>
                        {buttonTwoText}
                    </a>
                ) : buttonTwoOnClick ? (
                    <button onClick={buttonTwoOnClick} className={clsBtnTwo}>
                        {buttonTwoText}
                    </button>
                ) : (
                    <Link to={buttonTwoLink as string} className={clsBtnTwo}>
                        {buttonTwoText}
                    </Link>
                ))}
        </div>
    );
};

export const Section: FC<SectionProps> = ({
    children,
    clsOuter = '',
    clsInner = '',
    patterned,
    tag: Tag = 'section',
    id,
    centralised = false,
    inverse = false,
}) => {
    const outerClasses = `${defaultStyles.outer} ${
        patterned ? defaultStyles.patterned : ''
    } ${clsOuter} ${centralised ? defaultStyles.centralised : ''}`;
    const innerClasses = `${defaultStyles.inner} ${clsInner}`;

    return (
        <>
            {inverse && <section className={styles.hide}></section>}
            <Tag id={id} className={outerClasses}>
                <div className={innerClasses}>{children}</div>
            </Tag>
        </>
    );
};
