import React from 'react';
import { Loading, WarningWithChildren, Error } from '../../../../default/States/States';
import { useUpdatePaymentDetails } from '../../../../../hooks/payments/useUpdatePaymentDetails/useUpdatePaymentDetails';
import styles from '../../../../../css/default.module.scss';

const InArrears = () => {
    const { loading, redirecting, error, redirectUser } = useUpdatePaymentDetails();

    if (loading || redirecting) return <Loading centralised={true} marginTop={true} />;
    if (error) return <Error msg={error} marginTop={true} />;

    return (
        <WarningWithChildren marginBottom={true}>
            Your account is in arrears. We could not take the last payment from you, which is now
            overdue. Please{' '}
            <button onClick={redirectUser} className={styles.btnAsText}>
                update your payment details
            </button>{' '}
            now to ensure we can collect the overdue and future payments to prevent your account
            from being restricted.
        </WarningWithChildren>
    );
};

export default InArrears;
