import { cacheExchange, Client, createClient, dedupExchange, fetchExchange } from 'urql';
import { getCookie } from '../utils/HelperFunctions/HelperFunctions';

export const client: Client = createClient({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT as string,
    exchanges: [dedupExchange, cacheExchange, fetchExchange],
    fetchOptions: () => {
        const token = getCookie('accountToken');
        return {
            headers: { authorization: token ? `Bearer ${token}` : '' },
        };
    },
    requestPolicy: 'cache-and-network',
});
