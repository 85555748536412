import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import defaultStyles from '../../../css/default.module.scss';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Section } from '../Areas/Areas';

const GetStarted = () => (
    <Section patterned={true} clsOuter={styles.ctaBottomSection}>
        <h2>Get Started Now</h2>
        <p>
            Why not try our <u>free</u> version? There's no need to spend any money to get started.
        </p>
        <Link to='/pricing' className={defaultStyles.btnPrimary}>
            Try Free Version
        </Link>
        <FontAwesomeIcon
            icon={faAngleDoubleLeft}
            className={styles.arrowsIcon}
            aria-label='double left arrows'
        />
    </Section>
);

export default GetStarted;
