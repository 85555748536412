import React from 'react';
import { CheckboxFields } from '../../../../default/Form/Form';
import PageFields from './PageFields/PageFields';

const CreateCampaignStageFour = () => {
    return (
        <>
            <CheckboxFields
                title='campaignDevices'
                alias='Campaign Devices'
                values={['mobiles', 'desktops']}
                caption='Select the devices that should show the popup'
            />
            <PageFields />
            {/* @todo non-mvp: cookies */}
            {/* @todo non-mvp: url params */}
        </>
    );
};

export default CreateCampaignStageFour;
