import React from 'react';
import styles from './styles.module.scss';
import { GreenTicksChecklistProps } from './types';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GreenTicksChecklist = ({
    items,
    primaryColour = false,
}: GreenTicksChecklistProps): JSX.Element => (
    <ul className={styles.outer}>
        {items.map((item) => (
            <li key={item}>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={`${styles.icon} ${primaryColour ? styles.primaryColour : ''}`}
                    aria-label='tick'
                />
                <span>{item}</span>
            </li>
        ))}
    </ul>
);

export default GreenTicksChecklist;
