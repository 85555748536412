import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { useChangeUserEmailMutation } from '../../../graphql/generated/schema';
import { UseEditAccountOutputs, EditAccountFn } from './types';

export const useEditAccount: UseEditAccountOutputs = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const { user, setAccount } = useContext<UserContextType>(UserContext);
    const [{ fetching: loading }, changeUserEmail] = useChangeUserEmailMutation();

    const editAccount: EditAccountFn = async (formData) => {
        try {
            const { data, error } = await changeUserEmail({
                input: { email: formData.email },
            });

            if (error || !data) {
                const errorText = error?.message || 'Could not change email';
                setError(`HTTP error: ${errorText}`);
                return;
            }

            setAccount({ ...user, ...formData } as User);
            navigate('/user/profile');
        } catch (err: any) {
            setError(err.message);
        }
    };

    return { loading, error, editAccount };
};
