import React from 'react';
import { newNewsletterSubscriber } from '../../misc/MetaTags';
import { Section } from '../../../components/default/Areas/Areas';
import { withPage } from '../../../components/layout/Page/Page';
import styles from '../../../css/default.module.scss';
// @ts-ignore
import quickWinsPdf from '../../../downloads/cro-simple-quick-wins.pdf';
// import landingPageTemplate from '../../../downloads/landing-page-template.zip';

const NewNewsletterSubscriber = () => (
    <Section patterned={true}>
        <h1>Signup Successful</h1>
        <p>Congratulations - your signup was successful. You can now download your freebies.</p>
        <h2>A/B Testing Quick Wins:</h2>
        <a href={quickWinsPdf} className={styles.btnPrimary} target='_blank' rel='noreferrer'>
            Download
        </a>
        <h2>Static Landing Page Code Template:</h2>
        <a
            href='../../../downloads/landing-page-template.zip'
            className={styles.btnPrimary}
            target='_blank'
            rel='noreferrer'
        >
            Download
        </a>
        <h2>Video Background Landing Page Code Template:</h2>
        <a
            href='../../../downloads/video-bg-landing-page-template.zip'
            className={styles.btnPrimary}
            target='_blank'
            rel='noreferrer'
        >
            Download
        </a>
    </Section>
);

export default withPage(NewNewsletterSubscriber)({
    meta: newNewsletterSubscriber,
    noindex: true,
});
