import React, { useContext, useEffect } from 'react';
import { paymentMethodUpdateSuccessMeta } from '../../../../misc/MetaTags';
import { withPage } from '../../../../../components/layout/Page/Page';
import { HeroArea } from '../../../../../components/default/Areas/Areas';
import { UserContextType } from '../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';

const PaymentMethodUpdateSuccess = () => {
    const { setAccount } = useContext<UserContextType>(UserContext);

    useEffect(() => setAccount(null), [setAccount]);

    return (
        <HeroArea
            title='Payment Details Successfully Updated'
            description='You successfully updated your default payment details. You can log in again below:'
            buttonText='Log In'
            buttonLink='/login'
        />
    );
};

export default withPage(PaymentMethodUpdateSuccess)({ meta: paymentMethodUpdateSuccessMeta });
