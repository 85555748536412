import * as Yup from 'yup';
import { AVATAR_VALUES } from '../utils/global-variables';

export const emailSchema = Yup.string()
    .email('Enter a valid email address')
    .required('Enter an email address');
const passwordSchema = Yup.string().required('Enter a password');
const passwordConfirmation = Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
);

export const createAccountSchema = Yup.object().shape({
    email: emailSchema,
    password: passwordSchema,
    passwordConfirmation,
});

export const loginSchema = Yup.object().shape({
    email: emailSchema,
    password: passwordSchema,
});

export const editAccountSchema = Yup.object().shape({
    email: emailSchema,
});

export const newPasswordSchema = Yup.object().shape({
    email: emailSchema,
});

export const resetPasswordSchema = Yup.object().shape({
    password: passwordSchema,
    passwordConfirmation,
});

export const createAvatarGoalSchema = Yup.object().shape({
    category: Yup.string().required('Select a goal'),
    categoryOther: Yup.string(),
    comment: Yup.string().required('Enter a goal reason'),
    where: Yup.string()
        .required('Select where you intend to test on')
        .oneOf(['OWN_SITE', 'CLIENT_SITES'], 'Invalid value for "where"'),
    problems: Yup.string().required('Enter website problems or concerns'),
    improvements: Yup.string().required('Enter what you want to improve'),
});

export const createAvatarBusinessSchema = Yup.object().shape({
    businessName: Yup.string().required('Enter a business name'),
    numEmployees: Yup.number().required('Enter a number'),
    industryId: Yup.string().required('Select an industry'),
    otherIndustry: Yup.string(),
    monthlyWebsiteTrafficId: Yup.string().required('Select monthly traffic'),
    primaryObjectiveId: Yup.string().required('Select a primary objective'),
    otherPrimaryObjective: Yup.string(),
});

export const createAvatarDemographicsSchema = Yup.object().shape({
    businessPosition: Yup.string()
        .oneOf(['OWNER', 'EMPLOYEE'])
        .required('Are you the owner or an employee?'),
    countryId: Yup.string().required('Select a country'),
    dob: Yup.date().required('Enter your date of birth'),
    educationLevelId: Yup.string().required('Select an education level'),
    gender: Yup.string().oneOf(['M', 'F']).required('Gender is required'),
    jobCategoryId: Yup.string().required('Select a job category'),
    otherJobCategory: Yup.string(),
    nickname: Yup.string().required('What would you like us to call you?'),
    techSavvy: Yup.boolean().required('Do you know how to code?'),
});

export const createAvatarPreviousExperienceSchema = Yup.object().shape({
    conductedTestsBefore: Yup.boolean().required('Have you created A/B tests before?'),
    movedPlatform: Yup.boolean().required('Have you moved from another A/B testing platform?'),
    platformLeaveReason: Yup.string().when('movedPlatform', {
        is: true,
        then: Yup.string().required('Platform leave reason is required'),
        otherwise: Yup.string(),
    }),
    platformOther: Yup.string().when('previousPlatformId', {
        is: AVATAR_VALUES.OTHER_COMPETITOR,
        then: Yup.string().required('Which A/B testing platform did you move from?'),
        otherwise: Yup.string(),
    }),
    previousPlatformId: Yup.string().when('movedPlatform', {
        is: true,
        then: Yup.string().required('Which A/B testing platform did you move from?'),
        otherwise: Yup.string(),
    }),
    previousTestProblems: Yup.string().when('conductedTestsBefore', {
        is: true,
        then: Yup.string().required('What problems did you have?'),
        otherwise: Yup.string(),
    }),
});
