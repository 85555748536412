import React, { useEffect, useState } from 'react';
import { InputField } from '../../../../../default/Form/Form';
import { useFormContext, useWatch } from 'react-hook-form';

const ScrollDownPercentageField = () => {
    const scrollPercentageFromWatch = useWatch({
        name: 'popupShowAfterScrollPercentage',
    });
    const [shouldShowDisplayPercentage, setShouldShowDisplayPercentage] = useState(
        Boolean(scrollPercentageFromWatch) || false
    );
    const { setValue, clearErrors } = useFormContext();

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const shouldShowDisplayPercentage = e.target.value === 'true';
        setShouldShowDisplayPercentage(shouldShowDisplayPercentage);
    };

    useEffect(() => {
        if (!shouldShowDisplayPercentage) {
            setValue('popupShowAfterScrollPercentage', null);
            clearErrors('popupShowAfterScrollPercentage');
        } else {
            setValue('popupShowAfterScrollPercentage', scrollPercentageFromWatch || 50);
        }
    }, [shouldShowDisplayPercentage, clearErrors, setValue, scrollPercentageFromWatch]);

    return (
        <>
            <p>Should this also show after scrolling down a percentage of the page?</p>
            <select value={String(shouldShowDisplayPercentage)} onChange={handleSelectChange}>
                <option value=''>-- Select --</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
            </select>
            {shouldShowDisplayPercentage && (
                <InputField
                    type='number'
                    title='popupShowAfterScrollPercentage'
                    placeholder='Enter a percentage'
                    alias='What Percentage?'
                    hasColon={false}
                />
            )}
        </>
    );
};

export default ScrollDownPercentageField;
