import React from 'react';
import defaultStyles from '../../css/default.module.scss';
import { withPage } from '../../components/layout/Page/Page';
import { supportMeta } from '../misc/MetaTags';
import { Link } from 'react-router-dom';
import ListWithArrows from '../../components/default/ListWithArrows/ListWithArrows';
import { TRACKING_SCRIPT_SUPPORT_LINK_ITEMS } from './variables';

const Support = () => (
    <>
        <h1 className={defaultStyles.centralisedText}>Support Hub</h1>
        <p>
            Welcome to the support hub. We're here to help you get the most out of our popup tools.
            Whether you're new or experienced, you'll find useful resources here to guide you in
            optimizing your popups.
        </p>
        <h3>The Tracking Script:</h3>
        <ListWithArrows items={TRACKING_SCRIPT_SUPPORT_LINK_ITEMS} />
        {/* // @todo RE_ADD_AFFILIATES */}
        {/* <h3>Affiliates:</h3>
        <ListWithArrows items={AFFILIATE_SUPPORT_LINK_ITEMS} /> */}
        <h3>Contact Us:</h3>
        <p>
            If you can't find the answer you're looking for or need further assistance, don't
            hesitate to <Link to='/contact'>reach out</Link> to us. We're here to help.
        </p>
    </>
);

export default withPage(Support)({ meta: supportMeta, innerSpacing: true });
