import React from 'react';
import { useParams, Params } from 'react-router-dom';
import { CampaignMeta } from '../../../../misc/MetaTags';
import { withPage } from '../../../../../components/layout/Page/Page';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import {
    useCampaignPopupIdsQuery,
    useCampaignQuery,
} from '../../../../../graphql/generated/schema';
import { Loading, Error, Info } from '../../../../../components/default/States/States';
import formatToDDMMYYYY from '../../../../../utils/HelperFunctions/formatToDDMMYYYY';
import styles from '../../../../../css/default.module.scss';
import campaignStyles from './styles.module.scss';
import { DeleteCampaignModal } from '../../../../../components/account/campaigns/DeleteCampaignModal/DeleteCampaignModal';
import { ToggleCampaignModal } from '../../../../../components/account/campaigns/ToggleCampaignModal/ToggleCampaignModal';
import { Uuid } from '../../../../../utils/global-types';

const Campaign = () => {
    const { campaignId } = useParams<Params<string>>();
    const [{ data: campaignData, fetching, error }, refetch] = useCampaignQuery({
        variables: {
            campaignId: campaignId as string,
        },
    });
    const [{ data: popupData, fetching: fetchingPopupIds, error: popupError }] =
        useCampaignPopupIdsQuery({
            variables: {
                campaignId: campaignId as Uuid,
            },
        });

    if (fetching || fetchingPopupIds) return <Loading entirePage={true} />;
    if (error) return <Error msg={error.message} />;
    if (popupError) return <Error msg={popupError.message} />;

    const campaign = campaignData?.campaign;
    const popupId = popupData?.campaignPopupIds?.[0]; // @todo: needs changing when A/B testing means there are multiple popups per campaign

    if (!campaign) {
        return <Error msg='Campaign not found' />;
    }

    const {
        createdAt,
        deviceTargeting = '',
        isActive = false,
        name = 'Unnamed Campaign',
        pageTargeting: { include = [], exclude = [] } = {},
        storeInOurDatabase = false,
        type = 'unknown',
    } = campaign;

    const hasInclusionRules = include && include.length > 0;
    const hasExclusionRules = exclude && exclude.length > 0;

    return (
        <>
            <h1>Campaign: {name}</h1>

            <div className={campaignStyles.container}>
                <div className={`${styles.infoContainer} ${campaignStyles.infoContainer}`}>
                    <h2>About</h2>
                    <p>Created: {formatToDDMMYYYY(createdAt)}</p>
                    <p>Device Targeting: {deviceTargeting}</p>
                    <p>Active: {isActive ? 'Yes' : 'No'}</p>
                    <p>Type: {type}</p>
                    <p>
                        Store form submissions in our database: {storeInOurDatabase ? 'Yes' : 'No'}
                    </p>
                </div>
                <div className={styles.infoContainer}>
                    <h2>Page Targeting</h2>
                    {!hasInclusionRules && !hasExclusionRules && (
                        <p>None set - defaults to sitewide.</p>
                    )}
                    {hasInclusionRules && (
                        <>
                            <h3>Include</h3>
                            <ul>
                                {include.map((rule, index) => (
                                    <li key={index}>
                                        {rule?.type}: {rule?.value}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {hasExclusionRules && (
                        <>
                            <h3>Exclude</h3>
                            <ul>
                                {exclude?.map((rule, index) => (
                                    <li key={index}>
                                        {rule?.type}: {rule?.value}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>

            {popupId && (
                <Info
                    msg={`Popup preview URL: www.yoursite.com/?popup-hawk-preview-id=${popupId} - replace yoursite with your website`}
                    marginBottom={true}
                />
            )}

            <ToggleCampaignModal isActive={isActive} refetch={refetch} />
            <DeleteCampaignModal />

            <Back to='/user/campaigns' text='Back to Campaigns' paddingless={true} />
        </>
    );
};

export default withPage(Campaign)({ meta: CampaignMeta, innerSpacing: true });
