export type ObjectType = Record<string, any>;

export const camelCaseKeys = (obj: ObjectType): ObjectType | undefined => {
    if (!obj) return;
    return Object.keys(obj).reduce((acc: ObjectType, key: string) => {
        const camelCasedKey = key.replace(/_([a-z])/g, (match) => match[1].toUpperCase());
        acc[camelCasedKey] = obj[key];
        return acc;
    }, {});
};

export const camelCaseKeysArray = (arr: Array<ObjectType>): Array<ObjectType> | undefined => {
    if (!arr) return;
    return arr.reduce((acc: ObjectType[], obj: ObjectType) => {
        const camelCased = camelCaseKeys(obj);
        if (camelCased) acc.push(camelCased);
        return acc;
    }, []);
};
