import React, { useContext } from 'react';
import { DynamicTable } from '../../../components/default/Table/Table';
import { withPage } from '../../../components/layout/Page/Page';
import { CookieContext } from '../../../contexts/CookieContext/CookieContext';
import { CookieContextType } from '../../../contexts/CookieContext/types';
import { cookiesMeta } from '../../misc/MetaTags';
import styles from './styles.module.scss';
import { ALL_COOKIES, COOKIE_BANNER_MSG_DETAILED, COOKIE_TABLE_HEADERS } from './variables';
import defaultStyles from '../../../css/default.module.scss';

const Cookies = () => {
    const { cookieConsent, setCookieConsent } = useContext<CookieContextType>(CookieContext);

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Cookie Policy</h1>
            <h2>About Cookies</h2>
            <p>{COOKIE_BANNER_MSG_DETAILED}</p>
            <p>
                None of the data collected by cookies will be misused - we just technically need to
                use cookies for our site to function correctly and need you to know.
            </p>
            <h2>Our Cookies</h2>
            <DynamicTable
                data={ALL_COOKIES}
                headers={COOKIE_TABLE_HEADERS}
                clsOuter={styles.cookieTable}
                clsInner={styles.inner}
                keyField='names'
            />
            <h2>Your Preferences</h2>
            <p>
                You can{' '}
                <button onClick={() => setCookieConsent(null)} className={defaultStyles.btnAsText}>
                    reset your cookie preferences
                </button>{' '}
                at any time.
            </p>
            {cookieConsent && (
                <p>
                    You chose to allow <em>{cookieConsent}</em> cookies.
                </p>
            )}
        </>
    );
};

export default withPage(Cookies)({ meta: cookiesMeta, innerSpacing: true });
