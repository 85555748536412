import React, { useContext, useEffect } from 'react';
import { LoginForm, LoginFormLinks } from '../../../../components/account/Login/Login';
import { withPage } from '../../../../components/layout/Page/Page';
import { loginMeta } from '../../../misc/MetaTags';
import { useNavigate } from 'react-router-dom';
import { UserContextType } from '../../../../contexts/UserContext/types';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import defaultStyles from '../../../../css/default.module.scss';

const Login = () => {
    const { user: loggedInUser } = useContext<UserContextType>(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!loggedInUser) return;

        if (loggedInUser.userPlan === 'FREE') {
            return navigate('/user/plan');
        }

        return navigate('/user/profile');
    }, [loggedInUser, navigate]);

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Login</h1>
            <LoginForm />
            <LoginFormLinks />
        </>
    );
};

export default withPage(Login)({ meta: loginMeta, innerSpacing: true, whiteBox: true });
