export const TECH_SAVVY_RADIO_PROPS = {
    label: 'Do you know how to code?',
    group: 'techSavvy',
    options: [
        {
            title: 'True',
            value: 'true',
        },
        {
            title: 'False',
            value: 'false',
        },
    ],
};

export const BUSINESS_POSITION_RADIO_PROPS = {
    label: 'Are you the owner or employee?',
    group: 'businessPosition',
    options: [
        {
            title: 'Owner',
            value: 'OWNER',
        },
        {
            title: 'Employee',
            value: 'EMPLOYEE',
        },
    ],
};

export const GENDER_RADIO_PROPS = {
    label: 'Select gender:',
    group: 'gender',
    options: [
        {
            title: 'Male',
            value: 'M',
        },
        {
            title: 'Female',
            value: 'F',
        },
    ],
};
