import { useEffect } from 'react';
import {
    camelCaseKeys,
    ObjectType,
} from '../../../utils/HelperFunctions/camelCaseKeys/camelCaseKeys';

/*
    gets all of the URL search params and adds them to local storage
    used so that whenever a new account is created, it can credit the source
    in the marketing_source table (i.e. for paid ad campaigns, etc)

    example URL: https://www.popuphawk.com/campaign/ab-testing-tool?utm_source=facebook&utm_medium=cpc&utm_campaign=first_ad_campaign&utm_content=ad1&utm_term=ab%20testing%20tool&is_paid=true&ad_set=mobiles_keyword_history

    have these params:
    - utm_source (e.g. facebook)
    - utm_medium (e.g. cpc)
    - utm_campaign (e.g. summer_sale_2024)
    - utm_content (e.g. ad1)
    - utm_term (e.g. ab%20testing%20tool)
    - is_paid (e.g. true)
    - ad_set (optional - e.g. mobiles_keyword_history)
*/

const useTrackingParams = () => {
    useEffect(() => {
        const getDeviceType = (): 'mobile' | 'tablet' | 'desktop' => {
            const screenWidth =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;

            if (screenWidth < 768) {
                return 'mobile';
            } else if (screenWidth < 1024) {
                return 'tablet';
            } else {
                return 'desktop';
            }
        };

        const getParams = () => {
            // Check if trackingParams already exists
            const existingParams = localStorage.getItem('trackingParams');
            if (existingParams) return;

            const searchParams = new URLSearchParams(window.location.search);
            let params: ObjectType = {};

            // Add UTM parameters
            searchParams.forEach((value, key) => {
                params[key] = value;
            });

            // Add landing page URL
            params['landingPageUrl'] = window.location.href;

            // Add device type based on viewport width
            params['device'] = getDeviceType();

            // camel case keys
            params = camelCaseKeys(params) as ObjectType;

            // handle isPaid
            params['isPaid'] = params.isPaid === 'true';

            // set in local storage
            localStorage.setItem('trackingParams', JSON.stringify(params));
        };

        getParams();
    }, []);
};

export default useTrackingParams;
