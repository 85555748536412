import React from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { userPlanMeta } from '../../../misc/MetaTags';
import styles from '../../../../css/default.module.scss';
import { Section } from '../../../../components/default/Areas/Areas';
import { PlanCards } from '../../../../components/default/Cards/PlanCards/PlanCards';
import { Loading, Error } from '../../../../components/default/States/States';
import { useChangePlanCheckout } from '../../../../hooks/payments/useChangePlanCheckout/useChangePlanCheckout';
import { PlanTermsSnippet } from './PlanTermsSnippet/PlanTermsSnippet';
import { Back } from '../../../../components/default/Navigation/Navigation';
import { CustomPricing } from '../../../other/Pricing/Pricing';
import pricingStyles from '../../../other/Pricing/styles.module.scss';

const Plan = () => {
    const { changePlan, loading, error } = useChangePlanCheckout();

    return (
        <>
            <h1 className={styles.centralisedText}>Manage Plan</h1>

            <Section id={pricingStyles.planCardsSectionContainer} clsInner={pricingStyles.inner}>
                <PlanCards changePlan={changePlan} />
                <PlanTermsSnippet />
            </Section>

            <CustomPricing />

            {loading && <Loading centralised={true} />}
            {error && <Error msg={error} />}

            <Back to='/user' text='View Dashboard' />
        </>
    );
};

export default withPage(Plan)({
    meta: userPlanMeta,
    innerSpacing: true,
    fullWidth: true,
    marginTop: true,
});
