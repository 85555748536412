import React, { useEffect, useState } from 'react';
import styles from '../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Loading, Error } from '../../../default/States/States';
import { useSetCampaignActiveStatusMutation } from '../../../../graphql/generated/schema';
import campaignStyles from './styles.module.scss';
import { useParams, Params } from 'react-router-dom';

export const ToggleCampaignModal = ({
    isActive,
    refetch,
}: {
    isActive: boolean;
    refetch: () => void;
}): JSX.Element => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [{ data, fetching, error }, setCampaignStatus] = useSetCampaignActiveStatusMutation();
    const { campaignId } = useParams<Params<string>>();

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        if (data?.setCampaignActiveStatus?.acknowledged) {
            // If the mutation succeeded, close the modal and refetch the campaign data
            closeModal();
            refetch(); // Refetch the campaign data to update the UI
        }
    }, [data, refetch]);

    return (
        <>
            <div className={styles.buttonsContainer}>
                <button
                    onClick={openModal}
                    className={`${styles.btnPrimary} ${campaignStyles.toggler} ${campaignStyles.specificity}`}
                >
                    Turn {isActive ? 'Off' : 'On'}
                </button>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Toggle Campaign Modal'
                portalClassName={styles.modalOverlayWrap}
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <div>
                    <h2>Activate Campaign</h2>
                    <p>
                        Your campaign is currently {isActive ? 'on' : 'off'}. Would you like to turn
                        it {isActive ? 'off' : 'on'}?
                    </p>
                    {fetching ? (
                        <Loading centralised={true} />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button
                                className={styles.btnPrimary}
                                onClick={() =>
                                    setCampaignStatus({
                                        id: campaignId as string,
                                        active: !isActive,
                                    })
                                }
                            >
                                Turn {isActive ? 'off' : 'on'}
                            </button>
                            <button onClick={closeModal} className={styles.btnRed}>
                                Cancel
                            </button>
                        </div>
                    )}
                    {error && <Error msg={error.message} marginTop={true} />}
                </div>
            </Modal>
        </>
    );
};
