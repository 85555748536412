import React from 'react';
import { ErrorWithChildren } from '../../../../default/States/States';
import { Link } from 'react-router-dom';

const Restricted = () => (
    <ErrorWithChildren marginBottom={true}>
        Your account has been reverted back to the free plan as we could not take payments from your
        payment method. To upgrade back again, you can{' '}
        <Link to='/user/plan'>buy a new subscription</Link> again.
    </ErrorWithChildren>
);

export default Restricted;
