import { useCallback, useEffect, useRef } from 'react';
import { Query } from './types';
import { AnyVariables, OperationResult, useClient } from 'urql';

const useLazyQuery = (
    query: Query,
    variables: AnyVariables = {}
): (() => Promise<OperationResult<any, AnyVariables>>) => {
    const client = useClient();
    const variablesRef = useRef<AnyVariables | undefined>(variables);

    const lazyQuery = useCallback(
        () => client.query(query, variablesRef.current).toPromise(),
        [client, query]
    );

    useEffect(() => {
        variablesRef.current = variables;
    }, [variables]);

    return lazyQuery;
};

export default useLazyQuery;
