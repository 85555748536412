import React, { useContext, useEffect } from 'react';
import {
    CreateAccountForm,
    CreateAccountLinks,
} from '../../../../components/account/CreateAccount/CreateAccount';
import { withPage } from '../../../../components/layout/Page/Page';
import { createAccountMeta } from '../../../misc/MetaTags';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { UserContextType } from '../../../../contexts/UserContext/types';
import defaultStyles from '../../../../css/default.module.scss';

const CreateAccount = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) navigate('/user/profile');
    }, [user, navigate]);

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Create Account</h1>
            <CreateAccountForm affiliateForm={false} />
            <CreateAccountLinks />
        </>
    );
};

export default withPage(CreateAccount)({
    meta: createAccountMeta,
    innerSpacing: true,
    whiteBox: true,
});
