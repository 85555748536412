import {
    faScroll,
    faFile,
    faPoundSign,
    faPaintBrush,
    faEllipsisH,
    faPenAlt,
    faFunnelDollar,
    faMouse,
    faCreditCard,
    faRecycle,
    faHandPointRight,
    faDollar,
    faWindowMaximize,
    faBullseye,
    faEdit,
    faEye,
} from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.scss';

export const POPUP_BENEFITS = [
    'Reduce Cart Abandonment',
    'Increase Conversion Rates',
    'Grow Email Lists',
    'Increase Sales',
    'Improve Customer Engagement',
    'Capture Lost Users',
    'Gather Valuable Feedback',
    'Collect Useful Information',
    'Boost Average Order Value',
    'Reduce Bounce Rates',
    'Drive Traffic to Key Pages',
    'Grow Social Channels',
    'Promote New Products or Services',
    'Retarget Returning Visitors',
    'Promote Special or Seasonal Offers',
];

export const PROCESS_STEPS = [
    {
        icon: { icon: faScroll },
        title: 'Install Our Tracking Script',
        description: 'Once only',
        ariaLabel: 'popup script',
    },
    {
        icon: { icon: faWindowMaximize },
        title: (
            <>
                <span className={styles.primaryColour}>Create</span> a Popup
            </>
        ),
        ariaLabel: 'popup creation',
    },
    {
        icon: { icon: faFunnelDollar },
        title: (
            <>
                <span className={styles.primaryColour}>Catch</span> Visitors' Attention
            </>
        ),
        ariaLabel: 'capture traffic',
    },
    {
        icon: { icon: faDollar },
        title: (
            <>
                Improve <span className={styles.primaryColour}>Sales</span>
            </>
        ),
        description: '(e.g. new leads, sales from coupons, etc)',
        ariaLabel: 'improve sales',
    },
];

export const POPUP_CREATION_STEPS = [
    {
        icon: { icon: faFile },
        title: (
            <>
                Select <span className={styles.primaryColour}>Template</span>
            </>
        ),
        description: 'Optional',
        ariaLabel: 'page',
    },
    {
        icon: { icon: faEdit },
        title: (
            <>
                Use Editor to <span className={styles.primaryColour}>Design</span> Popup
            </>
        ),
        description: '(i.e. change appearance, content, etc)',
        ariaLabel: 'variants',
    },
    {
        icon: { icon: faEye },
        title: (
            <>
                Configure <span className={styles.primaryColour}>Display</span> Options
            </>
        ),
        description: '(e.g. show immediately, on scroll, etc)',
        ariaLabel: 'traffic',
    },
    {
        icon: { icon: faBullseye },
        title: (
            <>
                Configure <span className={styles.primaryColour}>Targeting</span> Options
            </>
        ),
        description: '(e.g. which pages to show it on, etc)',
        ariaLabel: 'desktop',
    },
];

export const HOMEPAGE_LIST_BOXES = [
    {
        icon: faPenAlt,
        label: 'pen',
        title: 'Forms',
        list: ['Fields', 'Length', 'Required Inputs'],
    },
    {
        icon: faFunnelDollar,
        label: 'funnel',
        title: 'Marketing Campaigns',
        list: ['Discounts', 'Promotions', 'Urgency Offers'],
    },
    {
        icon: faPoundSign,
        label: 'currency',
        title: 'Pricing',
        list: ['Amount', 'Model', 'Upsells', 'Cross-sells'],
    },
    {
        icon: faEllipsisH,
        label: 'ellipsis',
        title: 'Content Effectiveness',
        list: ['Product Images', 'Descriptions', 'Videos'],
    },
    {
        icon: faPaintBrush,
        label: 'paint brush',
        title: 'Styles',
        list: ['Layouts', 'Colors', 'Fonts'],
    },
    {
        icon: faHandPointRight,
        label: 'pointing',
        title: 'Calls-to-action',
        list: ['Options', 'Placement', 'Style'],
    },
    {
        icon: faCreditCard,
        label: 'credit card',
        title: 'Checkout',
        list: ['Payment Options', 'Abandonment Incentives', 'Trust Logos'],
    },
    {
        icon: faRecycle,
        label: 'recycling symbol',
        title: 'Traffic Capture',
        list: ['Exit Pop-ups', 'Lead Magnets'],
    },
    {
        icon: faMouse,
        label: 'mouse',
        title: 'Engagement',
        list: ['Clicks', 'Time-on-page', 'Bounces'],
    },
];
