// import { PRICES_MONTHLY } from '../../../../../../utils/global-variables';

export const CHANGE_PLAN_ERROR_MSG =
    "We're sorry - something went wrong when trying to change your plan. Please save this message or take a screenshot and contact customer support. Apologies for any inconvenience.";

// export const PRO_UPGRADE_CONFIRMATION_MESSAGE = `Please confirm that you would like to upgrade to the Pro Plan. Your payments will change to PRICES_MONTHLY.PRO todo mvp PRICECHANGES782 per month, where the first month's payment may differ based on your current billing cycle (it will be prorated).`;

// export const BEGINNER_DOWNGRADE_CONFIRMATION_MESSAGE = `Are you sure you want to downgrade to the Beginner Plan? You will lose all the benefits of the Pro Plan. Your payments will change to PRICES_MONTHLY.LITE todo mvp PRICECHANGES782 per month, where the first month's payment may differ based on your current billing cycle (it will be prorated).`;

export const DEFAULT_PLAN_CHANGE_CONTINUATION_MESSAGE = `Please confirm that you would like to change your plan. Your payments will change to your new plan's rate, where the first month's payment may differ based on your current billing cycle (it will be prorated).`;
