import { CookieList } from './types';

export const COOKIE_TABLE_HEADERS: string[] = [
    'names',
    'purpose',
    'duration',
    'party',
    'shared with third-parties',
];

export const ALL_COOKIES: CookieList = [
    {
        names: 'accountId, accountToken, accountEmail, accountReferrerId',
        purpose:
            'Allows user to remain being logged in after closing their browser and revisiting the site',
        duration: '1 day',
        party: 'first-party',
        shareWithThirdParties: 'No',
    },
    {
        names: 'cookieConsent',
        purpose: 'Allows user to set and change their cookie preferences',
        duration: '1 day for essential cookies or 1 year for all cookies',
        party: 'first-party',
        shareWithThirdParties: 'No',
    },
    {
        names: 'referrer',
        purpose: 'Allows affiliates to be credited for referring new users to our site',
        duration: '1 year',
        party: 'first-party',
        shareWithThirdParties: 'No',
    },
];

export const COOKIE_BANNER_MSG_BRIEF: string =
    'Our website uses cookies, some of which are essential for site use.';

export const COOKIE_BANNER_MSG_DETAILED: string =
    "We use cookies to make our website functional and enhance your experience. There are some essential cookies that we have to use in order to operate, such as account login cookies. Allowing us to use cookies also helps us improve our products and services. If you'd prefer, you can choose to only accept essential cookies. You can also change your preferences at any time.";
