export const whereSelectOptions = [
    {
        name: 'Own site',
        value: 'OWN_SITE',
    },
    {
        name: 'Client sites',
        value: 'CLIENT_SITES',
    },
];

export const SITE_TYPE = {
    'own site': 'OWN_SITE',
    'client sites': 'CLIENT_SITES',
};
