import React, { createContext, FC, useState } from 'react';
import { Plans, SubscriptionStatusKey } from '../../utils/global-variables';
import { deleteCookies, getCookie } from '../../utils/HelperFunctions/HelperFunctions';
import {
    UserContextType,
    UserProviderProps,
    SetAccountArgs,
    User,
    SetAccountCookiesFn,
} from './types';

export const UserContext = createContext<UserContextType>({
    user: undefined,
    setAccount: () => {},
});

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<SetAccountArgs | undefined>(getAccountFromCookies());
    const setAccount = (userData: SetAccountArgs): void => {
        setUser(userData);
        setAccountCookies(userData);
    };
    return <UserContext.Provider value={{ user, setAccount }}>{children}</UserContext.Provider>;
};

export const getAccountFromCookies = (): User | undefined => {
    const [
        id,
        token,
        email,
        userPlan,
        subscriptionStatus,
        cardNearingExpiryCookie,
        currentMonthlySessions,
    ] = [
        getCookie('accountId'),
        getCookie('accountToken'),
        getCookie('accountEmail'),
        getCookie('accountPlan') as Plans | null,
        getCookie('accountSubscriptionStatus') as SubscriptionStatusKey | null,
        getCookie('accountCardNearingExpiry'),
        getCookie('accountCurrentMonthlySessions'),
    ];
    const cardNearingExpiry = cardNearingExpiryCookie === 'true';
    if (!id || !token || !email || !userPlan || !subscriptionStatus || !currentMonthlySessions)
        return; // omit if !accountCardNearingExpiry
    return {
        id,
        token,
        email,
        userPlan,
        subscriptionStatus,
        cardNearingExpiry,
        currentMonthlySessions: parseInt(currentMonthlySessions),
    };
};

const setAccountCookies: SetAccountCookiesFn = (userData, path = '/'): void => {
    if (!userData) {
        deleteCookies([
            'accountId',
            'accountToken',
            'accountEmail',
            'accountPlan',
            'accountSubscriptionStatus',
            'accountCardNearingExpiry',
            'accountCurrentMonthlySessions',
        ]);
        return;
    }

    const {
        id,
        token,
        email,
        userPlan,
        subscriptionStatus,
        cardNearingExpiry,
        currentMonthlySessions,
    } = userData;
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 1); // expires in 1 day
    // note: expiry date set here should presumably match expiry date in USER.signToken in BE
    const expiryAndPath = `expires=${expiryDate}; path=${path}`;
    document.cookie = `accountId=${id}; ${expiryAndPath}`;
    document.cookie = `accountToken=${token}; ${expiryAndPath}`;
    document.cookie = `accountEmail=${email}; ${expiryAndPath}`;
    document.cookie = `accountPlan=${userPlan}; ${expiryAndPath}`;
    document.cookie = `accountSubscriptionStatus=${subscriptionStatus}; ${expiryAndPath}`;
    document.cookie = `accountCardNearingExpiry=${cardNearingExpiry}; ${expiryAndPath}`;
    document.cookie = `accountCurrentMonthlySessions=${currentMonthlySessions}; ${expiryAndPath}`;
};
