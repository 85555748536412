import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { LinkItem, StackedListProps } from './types';
import styles from './styles.module.scss';

const StackedList = ({ items, title, areLinks }: StackedListProps): JSX.Element => (
    <article className={styles.container}>
        <h2>{title}</h2>
        {areLinks ? (
            <nav>
                {items.map((item) => (
                    <Link key={item.label} to={(item as LinkItem).to}>
                        <FontAwesomeIcon icon={item.icon} aria-label={item.label} /> {item.label}
                    </Link>
                ))}
            </nav>
        ) : (
            <ul>
                {items.map((item) => (
                    <li key={item.label}>
                        <FontAwesomeIcon icon={item.icon} aria-label={item.label} /> {item.label}
                    </li>
                ))}
            </ul>
        )}
    </article>
);

export default StackedList;
