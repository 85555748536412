import React, { useEffect, useState } from 'react';
import styles from '../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Section } from '../Areas/Areas';
import customStyles from './styles.module.scss';
import { VideoModalProps } from './types';
import VideoPlaceholderImage from './video-placeholder.webp';

export const VideoModal = ({
    title = 'Video Demo',
    description = 'Want to see a 99 second video of how our product works?',
    image: { imageSrc, imageAlt } = {
        imageSrc: VideoPlaceholderImage,
        imageAlt: 'A/B video demonstration',
    },
    buttonText = 'Watch Now',
    customOpenState: { isCustomOpen, setCustomOpen } = {
        isCustomOpen: false,
        setCustomOpen: () => {},
    },
    videoEmbedUrl = 'https://www.youtube.com/embed/zu6S60b6JdI?si=TKoyYbjXGvUaDtGv',
    id = 'video-demo',
}: VideoModalProps): JSX.Element => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(isCustomOpen);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => setIsOpen(isCustomOpen), [isCustomOpen]);
    useEffect(() => {
        if (!modalIsOpen) {
            setCustomOpen(false);
        }
    }, [modalIsOpen, setCustomOpen]);

    return (
        <Section patterned={true} clsInner={customStyles.containerInner} id={id}>
            <div className={customStyles.content}>
                <h2>{title}</h2>
                <p>{description}</p>
                <button onClick={openModal} className={styles.btnPrimary}>
                    {buttonText}
                </button>
            </div>
            <img src={imageSrc} alt={imageAlt} onClick={openModal} />
            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className={styles.modal}
                    contentLabel='Video Modal'
                    portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
                >
                    <button onClick={closeModal} className={styles.close} aria-label='close'>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <div>
                        <h2>Watch Video</h2>
                        <p>Click play to watch the video below:</p>
                        <div className={customStyles.videoWrapper}>
                            <iframe
                                src={videoEmbedUrl}
                                title='YouTube video player'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                referrerPolicy='strict-origin-when-cross-origin'
                                allowFullScreen
                                className={customStyles.video}
                            ></iframe>
                        </div>
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button onClick={closeModal} className={styles.btnRed}>
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </Section>
    );
};
