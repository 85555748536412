import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { InputField, SelectFieldWithOptions } from '../../../default/Form/Form';
import { Loading, Error } from '../../../default/States/States';
import styles from '../../../../css/default.module.scss';
import { FormInputs } from './types';
import { createAvatarBusinessSchema } from '../../../../schemas/AccountSchemas';
import { SelectOption } from '../../../default/Form/types';
import customStyles from '../EditGoalsForm/styles.module.scss';
import { AVATAR_VALUES } from '../../../../utils/global-variables';
import {
    useAvatarBusinessQuery,
    useCreateAvatarBusinessMutation,
    useGetAvatarBusinessIndustriesQuery,
    useGetAvatarBusinessTrafficOptionsQuery,
    useGetAvatarBusinessWebsiteObjectivesQuery,
    useUpdateAvatarBusinessMutation,
} from '../../../../graphql/generated/schema';
import { useNavigate } from 'react-router-dom';
import useGetSelectOptions from '../../../../hooks/user/useGetSelectOptions/useGetSelectOptions';
import { Forward } from '../../../default/Navigation/Navigation';

const EditBusinessForm = () => {
    const [
        { data: createBusinessData, fetching: loadingCreation, error: creationError },
        createBusiness,
    ] = useCreateAvatarBusinessMutation();
    const [
        { data: updateBusinessData, fetching: loadingUpdateBusiness, error: updateBusinessError },
        updateBusiness,
    ] = useUpdateAvatarBusinessMutation();
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
    const {
        items: industries,
        loading: loadingIndustries,
        error: industriesError,
    } = useGetSelectOptions(useGetAvatarBusinessIndustriesQuery, 'getAvatarBusinessIndustries');
    const {
        items: websiteObjectives,
        loading: loadingWebsiteObjectives,
        error: websiteObjectivesError,
    } = useGetSelectOptions(
        useGetAvatarBusinessWebsiteObjectivesQuery,
        'getAvatarBusinessWebsiteObjectives'
    );
    const {
        items: trafficOptions,
        loading: loadingTrafficOptions,
        error: trafficOptionsError,
    } = useGetSelectOptions(
        useGetAvatarBusinessTrafficOptionsQuery,
        'getAvatarBusinessTrafficOptions'
    );
    const navigate = useNavigate();

    const handleSubmitFn: SubmitHandler<FormInputs> = async ({
        businessName,
        numEmployees,
        industryId,
        otherIndustry,
        monthlyWebsiteTrafficId,
        primaryObjectiveId,
        otherPrimaryObjective,
    }) => {
        const refinedInputs = {
            input: {
                businessName,
                numEmployees,
                industryId,
                otherIndustry,
                monthlyWebsiteTrafficId,
                primaryObjectiveId,
                otherPrimaryObjective,
            },
        };

        if (shouldUpdate) {
            await updateBusiness(refinedInputs);
        } else {
            await createBusiness(refinedInputs);
        }
    };

    const [{ data: currentBusiness, fetching: loadCurrentBusiness }] = useAvatarBusinessQuery();

    const {
        businessName,
        numEmployees,
        industryId,
        otherIndustry,
        monthlyWebsiteTrafficId,
        primaryObjectiveId,
        otherPrimaryObjective,
    } = currentBusiness?.avatarBusiness || {};

    const methods = useForm<FormInputs>({
        resolver: yupResolver(createAvatarBusinessSchema),
        mode: 'onTouched',
        defaultValues: {
            businessName,
            numEmployees,
            industryId,
            otherIndustry: otherIndustry || '',
            monthlyWebsiteTrafficId,
            primaryObjectiveId,
            otherPrimaryObjective: otherPrimaryObjective || '',
        },
    });

    const selectedOtherIndustry = methods.watch('industryId') === AVATAR_VALUES.OTHER_INDUSTRY;
    const selectedOtherPrimaryObjective =
        methods.watch('primaryObjectiveId') === AVATAR_VALUES.OTHER_PRIMARY_OBJECTIVE;

    useEffect(() => {
        if (!loadCurrentBusiness && currentBusiness) {
            const {
                businessName,
                numEmployees,
                industryId,
                otherIndustry,
                monthlyWebsiteTrafficId,
                primaryObjectiveId,
                otherPrimaryObjective,
            } = currentBusiness?.avatarBusiness || {};

            methods.reset({
                businessName,
                numEmployees,
                industryId,
                otherIndustry: otherIndustry || '',
                monthlyWebsiteTrafficId,
                primaryObjectiveId,
                otherPrimaryObjective: otherPrimaryObjective || '',
            });

            setShouldUpdate(true);
        }
    }, [loadCurrentBusiness, currentBusiness, methods]);

    useEffect(() => {
        if (
            createBusinessData?.createAvatarBusiness?.success ||
            updateBusinessData?.updateAvatarBusiness?.success
        ) {
            navigate('/user/profile/edit/3');
        }
    }, [createBusinessData, updateBusinessData, navigate]);

    if (
        loadingIndustries ||
        loadCurrentBusiness ||
        loadingWebsiteObjectives ||
        loadingTrafficOptions
    )
        return <Loading centralised={true} />;

    if (industriesError || websiteObjectivesError)
        return (
            <Error
                msg={
                    industriesError?.message ||
                    websiteObjectivesError?.message ||
                    trafficOptionsError?.message
                }
            />
        );

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmitFn)}
                    className={customStyles.parent}
                >
                    <div
                        className={`${styles.formInner} ${customStyles.editGoalsForm} ${customStyles.specificity}`}
                    >
                        <InputField
                            type='text'
                            title='businessName'
                            alias="What is your business' name?"
                            placeholder='Enter your business name'
                            hasColon={false}
                        />
                        <InputField
                            type='number'
                            title='numEmployees'
                            alias='Number of employees'
                            placeholder='Enter the number of employees'
                        />
                        <SelectFieldWithOptions
                            title='industryId'
                            alias='Select an industry'
                            options={industries as SelectOption[]}
                        />
                        <InputField
                            type='text'
                            title='otherIndustry'
                            alias='Other Industry'
                            placeholder='Enter your industry'
                            cls={`${customStyles.otherField} ${
                                selectedOtherIndustry ? customStyles.show : ''
                            }`}
                        />
                        <SelectFieldWithOptions
                            title='monthlyWebsiteTrafficId'
                            alias='Select your website traffic'
                            options={trafficOptions as SelectOption[]}
                        />
                        <SelectFieldWithOptions
                            title='primaryObjectiveId'
                            alias='Primary objective of your website'
                            options={websiteObjectives as SelectOption[]}
                        />
                        <InputField
                            type='text'
                            title='otherPrimaryObjective'
                            alias='Other Primary Objective'
                            placeholder='Enter your primary objective'
                            cls={`${customStyles.otherField} ${
                                selectedOtherPrimaryObjective ? customStyles.show : ''
                            }`}
                        />
                    </div>
                    {loadingCreation || loadingUpdateBusiness ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {(creationError || updateBusinessError) && (
                <Error
                    msg={(creationError?.message || updateBusinessError?.message) as string}
                    marginTop={true}
                />
            )}
            {shouldUpdate && <Forward to='/user/profile/edit/3' block={true} marginless={true} />}
        </>
    );
};

export default EditBusinessForm;
