export const getDeviceTargeting = (pages: string[]): string => {
    if (pages.length === 1 && pages[0] === 'mobiles') {
        return 'mobiles';
    } else if (pages.length === 1 && pages[0] === 'desktops') {
        return 'desktops';
    } else if (pages.length === 2 && pages.includes('mobiles') && pages.includes('desktops')) {
        return 'all';
    } else {
        throw new Error('Invalid page targeting input');
    }
};
