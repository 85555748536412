import React, { useContext, useEffect, useState } from 'react';
import defaultStyles from '../../../../../css/default.module.scss';
import { withPage } from '../../../../../components/layout/Page/Page';
import { cancelPlanMeta } from '../../../../misc/MetaTags';
import { Link, useNavigate } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { UserContextType } from '../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';
import { Loading } from '../../../../../components/default/States/States';
import PageError from '../../../../other/errors/PageError.tsx/PageError';
import DissuasionMessage from './DissuasionMessage/DissuasionMessage';

const CancelPlan = () => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { user } = useContext<UserContextType>(UserContext);
    const navigate = useNavigate();
    const userPlan = user?.userPlan;
    const isFreePlan = userPlan === 'FREE';

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isFreePlan) {
            navigate('/user/plan');
        }
    }, [navigate, isFreePlan]);

    if (!userPlan || isFreePlan) {
        return <Loading entirePage={true} centralised={true} />;
    }

    if (error) {
        return (
            <PageError
                title='Something Went Wrong'
                msg={error}
                customBtnPrimary={{
                    text: 'Contact Us',
                    url: '/contact',
                }}
            />
        );
    }

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Cancel Plan</h1>
            <DissuasionMessage />
            <div
                className={`${defaultStyles.buttonsContainer} ${defaultStyles.spaced} ${defaultStyles.marginTop} ${defaultStyles.marginBottom}`}
            >
                <Link to='/user/plan' className={defaultStyles.btnPrimary}>
                    View Plans
                </Link>
                <button onClick={openModal} className={defaultStyles.btnRed}>
                    Continue Cancellation
                </button>
            </div>
            <ConfirmationModal
                closeModal={closeModal}
                modalIsOpen={modalIsOpen}
                setError={setError}
            />
        </>
    );
};

export default withPage(CancelPlan)({ meta: cancelPlanMeta, innerSpacing: true, whiteBox: true });
