import React, { useContext } from 'react';
import styles from './styles.module.scss';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { Plan, SESSION_CAPS } from '../../../../utils/global-variables';
import { Link } from 'react-router-dom';
import { ErrorWithChildren } from '../../../default/States/States';

const CurrentMonthlySessions = () => {
    const { user } = useContext(UserContext);

    if (typeof user?.currentMonthlySessions !== 'number' || !user?.userPlan) return null;

    const plan = Plan[user.userPlan];
    const sessionCap = SESSION_CAPS[plan];
    const remainingSessions = sessionCap - user.currentMonthlySessions;

    if (remainingSessions <= 0) {
        return (
            <ErrorWithChildren marginBottom={true} centralised={true}>
                Your popups are not currently showing because you have 0 sessions remaining. Either{' '}
                <Link to='/user/plan'>upgrade to a plan with more sessions</Link> or wait until next
                month for your free monthly session cap to be renewed.
            </ErrorWithChildren>
        );
    }

    return (
        <div className={styles.infoContainer}>
            <div className={styles.inner}>
                <p>
                    Remaining Monthly Sessions: <b>{remainingSessions}</b>
                </p>
            </div>
        </div>
    );
};

export default CurrentMonthlySessions;
