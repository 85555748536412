import { useEffect, useMemo, useState } from 'react';
import { UseGetSelectOptions, SelectItem, UnrefinedSelectItem } from './types';

const useGetSelectOptions: UseGetSelectOptions = (query, childKey) => {
    const [{ data, fetching: loadingItems, error: fetchError }] = query(); // query e.g. useGetAvailableAvatarGoalsQuery
    const [loading, setLoading] = useState<boolean>(loadingItems);

    // e.g. data?.getAvailableAvatarGoals?.map
    const refinedItems = useMemo<SelectItem[]>(
        () =>
            data?.[childKey]?.map(({ name, id }: UnrefinedSelectItem) => ({
                name: name,
                value: id,
            })),
        [data, childKey]
    );

    useEffect(() => {
        setLoading(loadingItems || (!refinedItems?.length && !fetchError));
    }, [refinedItems, loadingItems, fetchError]);

    return { items: refinedItems, loading, error: fetchError };
};

export default useGetSelectOptions;
