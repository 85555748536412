import { useEffect } from 'react';

type QPFunction = (...args: any[]) => void;
type QuoraEvent = 'CompleteRegistration' | 'Purchase' | 'GenerateLead';
declare global {
    interface Window {
        qp?: (...args: any[]) => void;
    }
}

/*
    this custom hook essentially does the equivalent of this:

    <script>qp('track', 'CompleteRegistration');</script>

    it runs on page load, and can be used to add other events, such as:

    <script>qp('track', 'Purchase');</script>
*/

const useQuoraCustomTrackingEvent = (event: QuoraEvent) => {
    // hide logging 'Quora' - using Q instead to conceal from competitors

    useEffect(() => {
        const qp: QPFunction | undefined = window.qp;

        if (qp) {
            qp('track', event);
            console.log(`Listening for Q tracking event ${event} where applicable`);
        } else {
            console.error(`Q tracking error for ${event} event: qp is not defined`);
        }
    }, [event]);
};

export default useQuoraCustomTrackingEvent;
