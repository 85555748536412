import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ScrollDownPercentageField from './ScrollDownPercentageField/ScrollDownPercentageField';
import ExitIntentField from '../CreateCampaignStageOne/ExitIntentField/ExitIntentField';

const CreateCampaignStageThree = () => {
    const { watch, setError, clearErrors } = useFormContext();

    const popupExitIntent = watch('popupExitIntent');
    const popupShowAfterScrollPercentage = watch('popupShowAfterScrollPercentage');

    useEffect(() => {
        if (popupExitIntent === 'false' && popupShowAfterScrollPercentage === null) {
            // @todo: BUG occurs - sometimes both values can be no
            // I just can't allow my time to be wasted on this
            // Decision: allow the bug to go into production
            // @todo: if you see this sometime, only fix it if MVP is deployed
            // note: clicking back/forward and then back/forward again triggers it
            // does it need to re-render or trigger a state update or something?
            setError('popupExitIntent', {
                type: 'manual',
                message: 'Both values cannot be no, otherwise the popup will never show',
            });
        } else {
            clearErrors('popupExitIntent');
        }
    }, [popupExitIntent, popupShowAfterScrollPercentage, setError, clearErrors]);

    return (
        <>
            <ExitIntentField />
            <ScrollDownPercentageField />
            {/* leave triggeredByClickingElements for now - non-MVP */}
        </>
    );
};

export default CreateCampaignStageThree;
