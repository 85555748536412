import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { useCampaignSummariesQuery } from '../../../graphql/generated/schema';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../utils/global-variables';
import { oneDP } from '../../../utils/HelperFunctions/HelperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const useGetCampaigns = (options: { limit?: number; skip?: number } = {}) => {
    const { limit = DEFAULT_ITEMS_PER_PAGE, skip = 0 } = options;
    const [{ data, fetching: loading, error }, refetchCampaignSummaries] =
        useCampaignSummariesQuery({
            variables: { limit, offset: skip },
        });

    const refinedCampaigns =
        data?.campaignSummaries?.campaigns?.map((campaign) => ({
            // ...campaign, // N.B.!!! do NOT spread this in, otherwise it confusingly FUCKS UP THE TABLE ORDER BIG TIME...
            id: campaign.id,
            name: campaign.name,
            totalFormSubmissions: campaign.totalFormSubmissions || 0,
            conversionRate: oneDP(campaign.conversionRate || 0) + '%',
            clickThroughRate: oneDP(campaign.clickThroughRate || 0) + '%',
            closeRate: oneDP(campaign.closeRate || 0) + '%',
            icon: (
                <FontAwesomeIcon
                    icon={campaign.isActive ? faToggleOn : faToggleOff}
                    aria-label='toggle'
                    size='xl'
                />
            ),
            doNotDisplay: {
                isActive: campaign.isActive,
            },
        })) || [];

    return {
        total: data?.campaignSummaries?.total || 0,
        campaigns: refinedCampaigns,
        loading,
        error,
        refetchCampaignSummaries,
    };
};
