import React, { createContext, FC, useState } from 'react';
import { getCookie } from '../../utils/HelperFunctions/HelperFunctions';
import { CookieConsent, CookieContextType, CookieProviderProps } from './types';

export const CookieContext = createContext<CookieContextType>({
    cookieConsent: getCookie('cookieConsent'),
    setCookieConsent: () => {},
});

export const CookieProvider: FC<CookieProviderProps> = ({ children }) => {
    const [cookieConsent, setCookieConsent] = useState<CookieConsent>(getCookie('cookieConsent'));
    return (
        <CookieContext.Provider value={{ cookieConsent, setCookieConsent }}>
            {children}
        </CookieContext.Provider>
    );
};
