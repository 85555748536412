import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { UserContextType } from '../../../../contexts/UserContext/types';
import { PLANS } from '../../../../pages/other/Pricing/variables';
import { Plans } from '../../../../utils/global-variables';
import { Button, PlanCard as PlanCardProps, PlanCardsProps } from './types';
import styles from './styles.module.scss';
import defaultStyles from '../../../../css/default.module.scss';

export const PlanCard = ({
    title,
    description,
    price,
    button: { buttonLink, buttonText, onClick },
    benefits,
    currentPlan,
    monthly = true,
}: PlanCardProps): JSX.Element => {
    const isPOA = price === 'POA';
    const isFree = !isPOA && price.dollars === 0 && price.cents === 0;

    return (
        <article className={styles.planCardContainer} aria-label={title}>
            <header className={styles.header}>
                <h2>{title}</h2>
                <p>{description}</p>
            </header>
            <section
                className={`${styles.price} ${isFree ? styles.free : ''}`}
                aria-label='pricing'
            >
                <span className={styles.currency}>£</span>
                <div className={styles.amount}>
                    {isPOA ? (
                        <span className={styles.dollars}>POA</span>
                    ) : (
                        <>
                            <span className={styles.dollars}>{price.dollars}</span>
                            <span className={styles.separator}>.</span>
                            <span className={styles.cents}>{price.cents}</span>
                        </>
                    )}
                </div>
                <span className={styles.frequency}>{monthly && <>/month</>}</span>
            </section>
            <ul className={styles.benefits}>
                {benefits.map((benefit: string) => (
                    <li className={styles.benefit} key={benefit}>
                        {benefit}
                    </li>
                ))}
            </ul>
            {currentPlan ? (
                <p className={styles.currentPlan}>Your current plan</p>
            ) : buttonLink ? (
                <Link to={buttonLink} className={`${defaultStyles.btnPrimary} ${styles.btn}`}>
                    {buttonText}
                </Link>
            ) : (
                <button onClick={onClick} className={`${defaultStyles.btnPrimary} ${styles.btn}`}>
                    {buttonText}
                </button>
            )}
        </article>
    );
};

export const PlanCards = ({ changePlan }: PlanCardsProps): JSX.Element => {
    const { user } = useContext<UserContextType>(UserContext);
    const currentUserPlan = user?.userPlan;

    const getButtonText = (currentUserPlan: Plans, planTitle: string): string => {
        if (currentUserPlan === 'FREE') {
            return 'Upgrade';
        }

        return 'Change';
    };

    const getButtonConfig = (plan: PlanCardProps): Button => {
        if (!user || plan.title === currentUserPlan) {
            return plan.button;
        }

        return {
            onClick: () => changePlan(plan.title),
            buttonText: getButtonText(currentUserPlan as Plans, plan.title),
        };
    };

    const refinedPlans = PLANS.map((plan) => ({
        ...plan,
        button: getButtonConfig(plan),
        currentPlan: plan.title === currentUserPlan,
    }));

    return (
        <div className={styles.planCardsContainer} aria-label='plans'>
            {refinedPlans.map((plan) => (
                <PlanCard {...plan} key={plan.title} />
            ))}
        </div>
    );
};

interface StandardCardsProps {
    cards: PlanCardProps[];
}

export const StandardCards: React.FC<StandardCardsProps> = ({ cards }) => {
    const twoCardsOnly = cards.length === 2;
    const containerClasses = `${styles.standardCardsContainer} ${
        twoCardsOnly ? styles.narrow : ''
    }`;

    return (
        <div className={containerClasses} aria-label='services'>
            {cards.map((card) => (
                <PlanCard {...card} key={card.title} />
            ))}
        </div>
    );
};
