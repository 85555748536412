import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { InputField, RadioOptions, SelectFieldWithOptions } from '../../../default/Form/Form';
import { Loading, Error } from '../../../default/States/States';
import styles from '../../../../css/default.module.scss';
import { FormInputs } from './types';
import { createAvatarPreviousExperienceSchema } from '../../../../schemas/AccountSchemas';
import { SelectOption } from '../../../default/Form/types';
import customStyles from '../EditGoalsForm/styles.module.scss';
import { useCreateAvatarPreviousExperienceMutation } from '../../../../graphql/generated/schema';
import {
    CONDUCTED_TESTS_BEFORE_RADIO_NAME,
    CONDUCTED_TESTS_BEFORE_RADIO_PROPS,
    MOVED_PLATFORM_RADIO_NAME,
    MOVED_PLATFORM_RADIO_PROPS,
} from './variables';
import { useNavigate } from 'react-router-dom';
import useLazyCompetitors from '../../../../hooks/user/useLazyCompetitors/useLazyCompetitors';
import { AVATAR_VALUES } from '../../../../utils/global-variables';

const PreviousExperienceForm = () => {
    const [
        { data: createPreviousExperienceData, fetching: loadingCreation, error: creationError },
        createDemographics,
    ] = useCreateAvatarPreviousExperienceMutation();
    const navigate = useNavigate();

    const { competitors, loadingCompetitorList, competitorListError, getCompetitors } =
        useLazyCompetitors();

    const handleSubmitFn: SubmitHandler<FormInputs> = async ({
        conductedTestsBefore,
        movedPlatform,
        platformLeaveReason,
        platformOther,
        previousPlatformId,
        previousTestProblems,
    }) => {
        const refinedInputs = {
            input: {
                conductedTestsBefore,
                movedPlatform,
                platformLeaveReason,
                platformOther,
                previousPlatformId,
                previousTestProblems,
            },
        };

        await createDemographics(refinedInputs);
    };

    const methods = useForm<FormInputs>({
        resolver: yupResolver(createAvatarPreviousExperienceSchema),
        mode: 'onTouched',
    });

    const movedFromCompetitor = methods.watch(MOVED_PLATFORM_RADIO_NAME) === 'true';
    const hasCreatedTestsBefore = methods.watch(CONDUCTED_TESTS_BEFORE_RADIO_NAME) === 'true';
    const selectedOtherPlatform =
        methods.watch('previousPlatformId') === AVATAR_VALUES.OTHER_COMPETITOR;

    useEffect(() => {
        if (movedFromCompetitor) getCompetitors();
    }, [movedFromCompetitor, getCompetitors]);

    useEffect(() => {
        if (createPreviousExperienceData?.createAvatarPreviousExperience?.success) {
            navigate('/user/profile/edit/success');
        }
    }, [createPreviousExperienceData, navigate]);

    if (loadingCompetitorList) return <Loading centralised={true} />;

    if (competitorListError) return <Error msg={competitorListError?.message} />;

    const movedFromCompetitorClasses = `${customStyles.otherField} ${
        movedFromCompetitor ? customStyles.show : ''
    }`;
    const problemsClasses = `${customStyles.otherField} ${
        hasCreatedTestsBefore ? customStyles.show : ''
    }`;
    const otherPlatformClasses = `${customStyles.otherField} ${
        selectedOtherPlatform ? customStyles.show : ''
    }`;

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmitFn)}
                    className={customStyles.parent}
                >
                    <div
                        className={`${styles.formInner} ${customStyles.editGoalsForm} ${customStyles.specificity}`}
                    >
                        <RadioOptions {...CONDUCTED_TESTS_BEFORE_RADIO_PROPS} />
                        <InputField
                            type='text'
                            title='previousTestProblems'
                            alias='What problems did you have or how could your previous test have been improved?'
                            hasColon={false}
                            cls={problemsClasses}
                        />
                        <RadioOptions {...MOVED_PLATFORM_RADIO_PROPS} />
                        {competitors && (
                            <SelectFieldWithOptions
                                title='previousPlatformId'
                                alias='Which platform did you move from?'
                                options={competitors as SelectOption[]}
                                hasColon={false}
                                cls={movedFromCompetitorClasses}
                            />
                        )}
                        <InputField
                            type='text'
                            title='platformOther'
                            alias='Other Platform'
                            placeholder='Enter platform'
                            cls={otherPlatformClasses}
                        />
                        <InputField
                            type='text'
                            title='platformLeaveReason'
                            alias='Why did you switch platform?'
                            hasColon={false}
                            cls={movedFromCompetitorClasses}
                        />
                    </div>
                    {loadingCreation ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {creationError && <Error msg={creationError?.message} marginTop={true} />}
        </>
    );
};

export default PreviousExperienceForm;
