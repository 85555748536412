export const STAGE_FIELDS = new Map<number, { fieldName: string; nullable: boolean }[]>([
    [
        1,
        [
            { fieldName: 'campaignName', nullable: false },
            { fieldName: 'popupName', nullable: false },
            { fieldName: 'campaignType', nullable: false },
            { fieldName: 'campaignStoreInOurDatabase', nullable: false },
        ],
    ],
    [
        2,
        [
            { fieldName: 'campaignName', nullable: false },
            { fieldName: 'popupName', nullable: false },
            { fieldName: 'campaignType', nullable: false },
            { fieldName: 'campaignStoreInOurDatabase', nullable: false },
        ],
    ],
    [3, [{ fieldName: 'popupHtml', nullable: false }]],
    [
        4,
        [
            { fieldName: 'popupHowToShow', nullable: false },
            { fieldName: 'popupShowAfterScrollPercentage', nullable: true },
        ],
    ],
    [
        5,
        [
            { fieldName: 'campaignDevices', nullable: false },
            { fieldName: 'campaignPages', nullable: false },
        ],
    ],
]);

export enum STAGE_TITLES {
    'Initial Details' = 1,
    'Select Popup Template' = 2,
    'Appearance' = 3,
    'Display' = 4,
    'Targeting Options' = 5,
}

export const FINAL_STAGE = Array.from(STAGE_FIELDS.keys()).pop() || 0;
