import React from 'react';
// import { DeleteAccountModal } from '../../../../components/account/DeleteAccountModal/DeleteAccountModal';
import { EditAccountForm } from '../../../../components/account/EditAccount/EditAccount';
import { withPage } from '../../../../components/layout/Page/Page';
import { editAccountMeta } from '../../../misc/MetaTags';
import defaultStyles from '../../../../css/default.module.scss';
import { Back } from '../../../../components/default/Navigation/Navigation';

const EditAccount = () => (
    <>
        <h1 className={defaultStyles.centralisedText}>Edit Account</h1>
        <EditAccountForm />
        {/* <DeleteAccountModal /> */}
        <Back to='/user/profile' text='Back to Profile' />
    </>
);

export default withPage(EditAccount)({ meta: editAccountMeta, innerSpacing: true, whiteBox: true });
