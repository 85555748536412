import React from 'react';
import { ProcessStepProps } from './types';
import ProcessStepBoxes from './ProcessStepBoxes/ProcessStepBoxes';
import ProcessStepper from './ProcessStepper/ProcessStepper';
import styles from './styles.module.scss';

const ProcessSteps = ({ steps, cls = '' }: ProcessStepProps): JSX.Element => (
    <div className={`${styles.container} ${cls}`}>
        <ProcessStepper count={steps.length} />
        <ProcessStepBoxes steps={steps} />
    </div>
);

export default ProcessSteps;
