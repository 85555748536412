import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import styles from '../../../../components/default/StackedList/styles.module.scss';
import { useRecentCampaignsQuery } from '../../../../graphql/generated/schema';
import { Loading, Error } from '../../../default/States/States';
import { NoneFound } from '../../../default/States/States';

const RecentCampaigns = () => {
    const [{ data, fetching: loading, error }] = useRecentCampaignsQuery({
        variables: {
            limit: 3,
            offset: 0,
        },
    });

    if (loading) return <Loading centralised={true} />;
    if (error) return <Error msg={error?.message as string} />;

    return (
        <article className={styles.container}>
            <h2>Recent Campaigns</h2>
            {!data?.campaignSummaries?.campaigns?.length ? (
                <NoneFound msg='No campaigns found. Please create a campaign.' />
            ) : (
                <nav>
                    {data.campaignSummaries.campaigns.map((campaign) => (
                        <Link key={campaign.id} to={`/user/campaigns`}>
                            <FontAwesomeIcon icon={faBullhorn} aria-label={campaign.name} />{' '}
                            {campaign.name}
                        </Link>
                    ))}
                </nav>
            )}
        </article>
    );
};

export default RecentCampaigns;
