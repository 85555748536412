import React from 'react';
import { Loading, WarningWithChildren, Error } from '../../../../default/States/States';
import { useUpdatePaymentDetails } from '../../../../../hooks/payments/useUpdatePaymentDetails/useUpdatePaymentDetails';
import styles from '../../../../../css/default.module.scss';

const CardNearingExpiry = () => {
    const { loading, redirecting, error, redirectUser } = useUpdatePaymentDetails();

    if (loading || redirecting) return <Loading centralised={true} marginTop={true} />;
    if (error) return <Error msg={error} marginTop={true} />;

    return (
        <WarningWithChildren marginBottom={true}>
            Your primary payment method is about to expire. Please{' '}
            <button onClick={redirectUser} className={styles.btnAsText}>
                update your payment method
            </button>{' '}
            as soon as possible to avoid going into arrears and getting your account restricted.
        </WarningWithChildren>
    );
};

export default CardNearingExpiry;
