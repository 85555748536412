import React from 'react';
import { NextStageProps } from './types';
import styles from './styles.module.scss';
import defaultStyles from '../../../../../css/default.module.scss';
import { Loading } from '../../../../default/States/States';

export const NextStage = ({ onClick, disabled, type, loading }: NextStageProps): JSX.Element => {
    if (loading) return <Loading />;

    return (
        <button
            disabled={disabled}
            type={type}
            onClick={onClick}
            className={`${defaultStyles.btnPrimary} ${styles.next} ${
                disabled ? defaultStyles.disabled : ''
            }`}
        >
            Next
        </button>
    );
};
