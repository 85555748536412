import React from 'react';
import { SelectField } from '../../../../../default/Form/Form';

const CAMPAIGN_TYPES = [
    'Sign-up',
    'Cart Abandonment',
    'Coupon',
    'Festive',
    'Halloween',
    'Easter',
    'Feedback',
    'Other',
];

const CampaignTypeDropdown = () => {
    return (
        <SelectField title='campaignType' type='select' alias='Campaign Type'>
            <option value=''>-- Select --</option>
            {CAMPAIGN_TYPES.map((type) => (
                <option key={type} value={type}>
                    {type}
                </option>
            ))}
        </SelectField>
    );
};

export default CampaignTypeDropdown;
