import { useState } from 'react';
import {
    GetStripeCustomerIdDocument,
    useProcessCardUpdateSessionMutation,
} from '../../../graphql/generated/schema';
import useLazyQuery from '../../other/useLazyQuery/useLazyQuery';
import { UseUpdatePaymentDetails } from './types';

export const useUpdatePaymentDetails: UseUpdatePaymentDetails = () => {
    const fetchCustomerId = useLazyQuery(GetStripeCustomerIdDocument);
    const [{ fetching: loading }, generateCardUpdateUrl] = useProcessCardUpdateSessionMutation();
    const [redirecting, setRedirecting] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const redirectUser = async () => {
        // 1. get stripe customer id
        const {
            data: {
                me: { stripeCustomerId: customerId },
            },
        } = await fetchCustomerId();

        if (!customerId) {
            setError(
                'Could not get customer id when trying to generate card renewal URL. Please contact customer support'
            );
            return;
        }

        // 2. generate card redirect url
        const res = await generateCardUpdateUrl({
            customerId, // e.g. 'cus_ObkC0bV9naqpCw',
        });
        const error = res.error?.message;

        if (error) {
            setError(error);
            return;
        }

        const redirectUrl = res.data?.processCardUpdateSession;

        if (!redirectUrl) {
            setError('Could not generate card renewal URL. Please contact customer support');
            return;
        }

        // 3. redirect to stripe card update URL
        setRedirecting(true);
        window.location.href = redirectUrl;
    };

    return { loading, redirecting, error, redirectUser };
};
