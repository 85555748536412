import React, { FC } from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Section } from '../../default/Areas/Areas';
import { FooterProps } from './types';
import { LinkType } from '../Header/types';

export const Footer: FC<FooterProps> = ({ cls = '', links }) => {
    const name = 'Popup Hawk';
    const year = 2025; // new Date().getFullYear(); // @todo: consider manually entering year to prevent usage of unnecessary bandwidth
    const copyrightMsg = `\u00A9 ${
        name || window.location.host
    } 2024 - ${year}. All Rights Reserved.`;

    return (
        <Section
            clsOuter={`${styles.footerOuter} ${cls}`}
            clsInner={styles.footerInner}
            tag='footer'
        >
            <span>{copyrightMsg}</span>
            {links && (
                <ul>
                    {links.map(({ title, url }: LinkType) => (
                        <li key={title}>
                            <Link to={url}>{title}</Link>
                        </li>
                    ))}
                </ul>
            )}
        </Section>
    );
};
