import React, { useState } from 'react';
import styles from '../../../../../../css/default.module.scss';
import Modal from 'react-modal';
import { ConfirmationModalProps } from './types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { useCancelSubscriptionMutation } from '../../../../../../graphql/generated/schema';
import { Loading } from '../../../../../../components/default/States/States';
import { CANCEL_PLAN_ERROR_MSG } from './variables';

const ConfirmationModal = ({
    closeModal,
    modalIsOpen,
    setError,
}: ConfirmationModalProps): JSX.Element => {
    const [, cancelSubscription] = useCancelSubscriptionMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const proceed = async () => {
        setLoading(true);

        try {
            const { data, error: processingError } = await cancelSubscription({});

            if (!data?.cancelSubscription?.success || processingError) {
                setError(`${CANCEL_PLAN_ERROR_MSG} ${processingError?.message || ''}`);
                return;
            }

            navigate('/user/plan/cancellation-success');
        } catch (err: any) {
            setError(`${CANCEL_PLAN_ERROR_MSG} ${err.message || ''}`);
        } finally {
            setLoading(false);
        }

        // @todo: create a <Button/> component (if it doesnt already exist?) with a loading prop, onClick, etc - so if loading, it displays spinner
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={styles.modal}
            contentLabel='Confirm Subscription Cancellation'
            portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
        >
            <button onClick={closeModal} className={styles.close} aria-label='close'>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div>
                <h2>Confirm Cancellation</h2>
                <p>
                    Are you sure you want to downgrade to the free plan? Your plan benefits will be
                    lost.
                </p>
                <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                    <Link to='/user/plan' className={styles.btnPrimary}>
                        Explore Other Options
                    </Link>
                    {loading ? (
                        <Loading />
                    ) : (
                        <button onClick={proceed} className={styles.btnRed}>
                            Continue Downgrade
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
