import React from 'react';
import styles from '../../../../../../css/default.module.scss';
import { Back } from '../../../../../../components/default/Navigation/Navigation';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { userAvatarDemographicsMeta } from '../../../../../misc/MetaTags';
import EditDemographicsForm from '../../../../../../components/account/profile/EditDemographicsForm/EditDemographicsForm';

const Demographics = () => (
    <div>
        <h1 className={styles.centralisedText}>About You</h1>
        <EditDemographicsForm />
        <Back />
    </div>
);

export default withPage(Demographics)({ meta: userAvatarDemographicsMeta, innerSpacing: true });
