import React from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { pageNotFoundMeta } from '../../../misc/MetaTags';
import { HeroArea } from '../../../../components/default/Areas/Areas';

const NotFound = () => (
    <HeroArea
        title='Page Not Found'
        description="We're sorry, but the page you're looking for does not exist. It could have been deleted, you may have entered a wrong URL, or a broken link could have taken you here."
        buttonText='Homepage'
        buttonLink='/'
        buttonTwoText='Account'
        buttonTwoLink='/user'
        withBackButton={true}
    />
);

export default withPage(NotFound)({ meta: pageNotFoundMeta });
